import { Client } from '@stomp/stompjs'

import config from '../api/config'

const stomp = () => {

    const stomp = new Client({
        brokerURL: config.STOPM_BROKER,
        connectHeaders: {
            login: 'yuso',
            passcode: 'battery'
        },
        // debug: str => {
        //     console.log(str)
        // },
        reconnectDelay: 5000,
        heartbeatIncoming: 10000,
        heartbeatOutgoing: 10000
    })
    
    stomp.onConnect = frame => {
        console.log('Connected to the STOMP broker')
        
        // Do something, all subscribes must be done is this callback
        // This is needed because this will be executed after a (re)connect
        
        // setup STOMP global variable
        window.stomp = stomp
    }

    stomp.onDisconnect = frame => {
        console.log('Disconnected from the STOMP broker')
        // console.log(frame)
    }
    
    stomp.onStompError = frame => {
        console.log('STOMP error')
        // Will be invoked in case of error encountered at Broker
        // Bad login/passcode typically will cause an error
        // Complaint brokers will set `message` header with a brief message. Body may contain details.
        // Compliant brokers will terminate the connection after any error
        console.log(frame)
    }

    stomp.onWebSocketClose = evt => {
        console.log('WebSocket closed')
        // console.log(evt)
    }

    stomp.onWebSocketError = evt => {
        console.log('WebSocket error')
        console.log(evt)
    }
    
    stomp.activate()
}

export default stomp