import React, { Component } from 'react'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'

import Loader from '../Loader'

class StateOfCharge extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">State of Charge (%)
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_SOC" onChange={e => this.props.toggleDynamicYAxis(e, 'SOC')} />
                                <label className="form-check-label" htmlFor="yaxis_SOC">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingBatteryData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={this.props.batteryData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} minTickGap={50} />
                                    <YAxis type="number" domain={[this.props.dynamicYAxis.SOC ? 'auto' : 0, this.props.dynamicYAxis.SOC ? 'auto' : 100]} />
                                    <Tooltip />
                                    <Legend verticalAlign="bottom" height={36} />
                                    {
                                        data.soc === false ? null : <Line type="monotone" dataKey="SOC" name="State of charge" unit="%" stroke="#3f51b5" dot={dot => this.props.setDot && this.props.setDot(dot, 'SOC', '%', 3, 0, 100, 'chart-battery', this.props.batteryData)} isAnimationActive={false} />
                                    }
                                </LineChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default StateOfCharge