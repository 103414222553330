import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import Loader from './components/Loader'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Monitoring from './pages/Monitoring'
import Reports from './pages/Reports'
import Dispatch from './pages/Dispatch'
import Management from './pages/Management'
import NotFound from './pages/NotFound'

import stomp from './services/stomp'

import { getSites } from './api/sites'
import { getUserInfo } from './api/user'

import metadata from './metadata.json'

import config from './api/config'


class App extends Component {
    constructor(props) {
        super(props)

        /**
         * FIRST CHECK THE JSON OBJECTS
         * If we have broken JSON object in localStorage
         * then clear the localStorage to prevent app issues
         */
        try {
            // Check only JSON objects
            window.localStorage.site && JSON.parse(window.localStorage.site)
        } catch (err) {
            console.warn('Error while parsing "localStorage" objects, "localStorage" out...')

            // if the JSON parsing fails, clear the localStorage
            window.localStorage.clear()
        }

        /**
         * SECOND CHECK THE APP VERSION
         * If we have new app version meaning we have deployed new code on the server
         * then clear the localStorage to prevent app issues due to breaking changes
         */
        if (metadata.version !== window.localStorage.version) {
            console.warn(`New app version "${metadata.version}" found, clearing "localStorage"...`)

            window.localStorage.clear()

            // Save the new app version in localStorage
            window.localStorage.version = metadata.version
        }

        this.historyUnlisten = null

        this.state = {
            sites: null,
            activePage: null,
            unit: window.localStorage.unit || 'kW',
            site: window.localStorage.site ? JSON.parse(window.localStorage.site) : null,
            user: window.localStorage.user ? JSON.parse(window.localStorage.user) : null
        }

        this.init = this.init.bind(this)
        this.setActivePage = this.setActivePage.bind(this)
        this.selectSite = this.selectSite.bind(this)
        this.updateSites = this.updateSites.bind(this)
        this.setCrispData = this.setCrispData.bind(this)
        this.selectUnit = this.selectUnit.bind(this)
        this.onLogin = this.onLogin.bind(this)
        this.onLogout = this.onLogout.bind(this)
    }

    componentDidMount() {
        // only set this for the production version
        if (!config.DEV) {
            this.props.piwik.push(['setCookieSameSite', 'None']);
            this.props.piwik.push(['setSecureCookie', true]);
        }

        this.init()
    }

    componentWillUnmount() {
        if (this.historyUnlisten) {
            this.historyUnlisten()
        }
    }

    async init() {

        if (!this.state.user) return

        // listen for page navigation
        this.historyUnlisten = this.props.history.listen((location, action) => {
            this.setActivePage(location)
        })

        // track the username
        this.props.piwik.setUserId(this.state.user.username)

        // init the STOMP client
        stomp()

        this.setActivePage(window.location)

        let sites = await getSites(),
            user = { ...this.state.user, info: await getUserInfo() }

        this.setState({
            sites,
            user
        }, this.setCrispData)
    }

    async updateSites() {
        let sites = await getSites()

        this.setState({
            sites
        }, () => this.selectSite(this.state.site && this.state.site.siteId))
    }

    setActivePage(location) {
        // get active page from the URL
        const activePage = location.pathname.substring(location.pathname.indexOf('/', 1) + 1)

        this.setState({
            activePage
        })
    }

    selectSite(siteId) {

        const site = this.state.sites && this.state.sites.find(site => site.siteId === siteId)

        if (site)
            this.setState({
                site
            }, this.setCrispData)
        else
            this.setState({ redirectToHome: true }, () => this.setState({ redirectToHome: false }))

        window.localStorage.site = JSON.stringify(site)
    }

    setCrispData() {

        if (this.state.user)
            window.$crisp.push(['set', 'user:email', this.state.user.username])

        if (this.state.site && this.state.site.company) {
            window.$crisp.push(['set', 'user:nickname', this.state.site.company.firstName])
            window.$crisp.push(['set', 'user:company', this.state.site.company.companyName])
        }
    }

    selectUnit(unit) {

        this.setState({
            unit
        })

        window.localStorage.unit = unit
    }

    onLogin(user) {

        this.setState({
            user
        }, () => {
            this.init()
            this.props.piwik.push(['trackPageView', 'Yuso Flex - Login'])
        })

        window.localStorage.user = JSON.stringify(user)
    }

    onLogout() {
        // on logout delete all state and localy stored data
        let remove = {}
        for (let key in this.state)
            if (key !== 'unit')
                remove[key] = undefined

        this.props.piwik.push(['setCustomUrl', '/'])
        this.props.piwik.push(['deleteCustomVariables', 'page'])
        this.props.piwik.push(['trackPageView', 'Yuso Flex - Logout'])
        this.props.piwik.push(['resetUserId'])

        for (let key in window.localStorage)
            if (key !== 'unit' && key !== 'version')
                delete window.localStorage[key]

        this.setState({ ...remove, redirectToHome: true }, () => this.setState({ redirectToHome: false }))
    }

    render() {

        if (this.state.redirectToHome)
            return <Redirect to="/" />

        if (!this.state.user) {
            return (
                <div className="mt-5">
                    <main role="main" className="container">
                        <div className="row">
                            <div className="col-sm-6 offset-sm-3">
                                <Switch>
                                    <Route
                                        exact path="/forgot-password"
                                        component={ForgotPassword}
                                    />
                                    <Route
                                        path="/"
                                        component={() => <Login
                                            onLogin={this.onLogin}
                                        />}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </main>
                </div>
            )
        }

        if (!this.state.sites)
            return <div className="vh-100 vw-100 d-flex justify-content-center align-items-center display-4 text-info">
                <Loader className="container align-items-center" noText={true} />
            </div>

        return (
            [
                <Header
                    sites={this.state.sites}
                    activePage={this.state.activePage}
                    selectSite={this.selectSite}
                    site={this.state.site}
                    selectUnit={this.selectUnit}
                    unit={this.state.unit}
                    user={this.state.user}
                    onLogout={this.onLogout}
                    key={0}
                />,

                <main role="main" className="container-fluid" key={1}>
                    <Switch>
                        <Route
                            exact path="/"
                            component={() => <Home
                                sites={this.state.sites}
                                selectSite={this.selectSite}
                                site={this.state.site}
                                unit={this.state.unit}
                                user={this.state.user}
                            />}
                        />
                        <Route
                            exact path="/:site/monitoring"
                            component={({ match }) => <Monitoring
                                siteId={match.params.site}
                                selectSite={this.selectSite}
                                site={this.state.site}
                                unit={this.state.unit}
                                user={this.state.user}
                            />}
                        />
                        <Route
                            exact path="/:site/reports"
                            component={({ match }) => <Reports
                                siteId={match.params.site}
                                selectSite={this.selectSite}
                                site={this.state.site}
                                unit={this.state.unit}
                                user={this.state.user}
                            />}
                        />
                        <Route
                            exact path="/:site/dispatch"
                            component={({ match }) =>
                                this.state.user.info.role === 'admin' || this.state.site.siteId === 'aes' || (this.state.site.battery && this.state.site.battery.flexPortalConfig && this.state.site.battery.flexPortalConfig.steering && (this.state.site.battery.flexPortalConfig.steering.enabled && this.state.site.battery.flexPortalConfig.steering.user_access.includes(this.state.user.info.email)))
                                    ? <Dispatch
                                        siteId={match.params.site}
                                        selectSite={this.selectSite}
                                        site={this.state.site}
                                        unit={this.state.unit}
                                        user={this.state.user}
                                        updateSites={this.updateSites}
                                    />
                                    : <Route component={NotFound} />
                            }
                        />
                        < Route
                            exact path="/:site/management"
                            component={({ match }) =>
                                this.state.user.info.role === 'admin'
                                    ? <Management
                                        siteId={match.params.site}
                                        selectSite={this.selectSite}
                                        site={this.state.site}
                                        unit={this.state.unit}
                                        user={this.state.user}
                                        updateSites={this.updateSites}
                                    />
                                    : <Route component={NotFound} />
                            }
                        />
                        {/* Catch all route displays 404 not-found page */}
                        <Route component={NotFound} />
                    </Switch>
                </main>,

                <Footer key={2} />
            ]
        )
    }
}

export default App
