import React, { Component } from 'react'
import { ResponsiveContainer, AreaChart, Area, CartesianGrid, XAxis, YAxis, ReferenceLine, Tooltip, Legend } from 'recharts'

import Loader from '../Loader'

class EnergyPrices extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">Energy Prices (€/MWh)
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_energyPrices" onChange={e => this.props.toggleDynamicYAxis(e, 'energyPrices')} />
                                <label className="form-check-label" htmlFor="yaxis_energyPrices">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingEnergyPricesData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <AreaChart data={this.props.energyPrices} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} LineCategoryGap={'1%'} barGap={1} stackOffset="sign">
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} minTickGap={30} />
                                    <YAxis type="number" domain={this.props.dynamicYAxis.energyPrices ? ['auto', 'auto'] : [dataMin => (Math.floor(dataMin / 100) * 100), dataMax => (Math.ceil(dataMax / 100) * 100)]} tickFormatter={this.tickFormatterY} minTickGap={50} />
                                    <Tooltip />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    <ReferenceLine y={0} stroke="#66c766" ifOverflow="extendDomain" />
                                    {
                                        this.props.site.siteId === 'aes' ?
                                            [
                                                data.apx_price === false ? null : <Area type="stepAfter" dataKey={`apx_price${this.props.hidden.apx_price ? ' ' : ''}`} name="Day-ahead price" unit="€/MWh" stroke="#3f51b5" fill="#3f51b5" fillOpacity="0.1" isAnimationActive={false} key={0} />,
                                                data.forecast_priceUp === false ? null : <Area type="stepAfter" dataKey={`forecast_priceUp${this.props.hidden.forecast_priceUp ? ' ' : ''}`} name="Forecast price up" unit="€/MWh" stroke="#88bb56" fill="#88bb56" fillOpacity="0.1" isAnimationActive={false} key={1} />,
                                                data.forecast_priceMid === false ? null : <Area type="stepAfter" dataKey={`forecast_priceMid${this.props.hidden.forecast_priceMid ? ' ' : ''}`} name="Forecast price middle" unit="€/MWh" stroke="#ffbc00" fill="#ffbc00" fillOpacity="0.1" isAnimationActive={false} key={2} />,
                                                data.forecast_priceDown === false ? null : <Area type="stepAfter" dataKey={`forecast_priceDown${this.props.hidden.forecast_priceDown ? ' ' : ''}`} name="Forecast price down" unit="€/MWh" stroke="#d04239" fill="#d04239" fillOpacity="0.1" isAnimationActive={false} key={3} />,
                                                data.official_pricePos === false ? null : <Area type="stepAfter" dataKey={`official_pricePos${this.props.hidden.official_pricePos ? ' ' : ''}`} name="Official price positive" unit="€/MWh" stroke="#88bb56" fill="#88bb56" fillOpacity="0.1" isAnimationActive={false} key={4} />,
                                                data.official_priceNeg === false ? null : <Area type="stepAfter" dataKey={`official_priceNeg${this.props.hidden.official_priceNeg ? ' ' : ''}`} name="Official price negative" unit="€/MWh" stroke="#d04239" fill="#d04239" fillOpacity="0.1" isAnimationActive={false} key={5} />
                                            ]
                                            :
                                            [
                                                data.neg === false ? null : <Area type="stepAfter" dataKey={`neg${this.props.hidden.neg ? ' ' : ''}`} name="Imbalance price" unit="€/MWh" stroke="#d04239" fill="#d04239" fillOpacity="0.1" isAnimationActive={false} key={0} />,
                                                data.belpex === false ? null : <Area type="stepAfter" dataKey={`belpex${this.props.hidden.belpex ? ' ' : ''}`} name="Belpex price" unit="€/MWh" stroke="#3f51b5" fill="#3f51b5" fillOpacity="0.1" isAnimationActive={false} key={1} />
                                            ]
                                    }
                                </AreaChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default EnergyPrices