import config from './config'
import { getAccessToken } from './user'

const getSites = async () => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/sites`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const saveSiteParams = async (siteId, data) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/sites/params/${siteId}`, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(data) })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const saveSiteConfig = async (siteId, data) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/sites/config/${siteId}`, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(data) })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const saveSteerConfig = async (deviceId, action, data) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/sites/steerconfig/${deviceId}/${action}`, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(data) })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export {
    getSites,
    saveSiteParams,
    saveSiteConfig,
    saveSteerConfig
}