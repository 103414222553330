import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <footer className="p-4">
        <div className="footer-wrap d-flex align-items-center flex-wrap px-2">
          <div className="links-info d-flex align-items-center justify-content-center flex-wrap">
            <img
              className="footer-logo"
              src={`${process.env.PUBLIC_URL}/yuso-logo-inverted.png`}
              alt="Yuso"
            />

            <a href="https://support.yuso.be/en/">Support</a>
            <a href="https://yuso.be/contact/">Contact</a>
            <a href="https://yuso.be">yuso.be</a>
          </div>

          <div className="legal-info d-flex align-items-center justify-content-center flex-wrap ml-auto">
            <div><span>address</span> Zuiderlaan 11 bus 31, 8790 Waregem, Belgium</div>
            <div><span>phone</span><a href="tel:+3256960124"> +32 56 960 124</a></div>
            <div><span>mail</span> info@yuso.be</div>
            <div><span>vat</span> BE 0848 296 771</div>
            <div><a href="https://yuso.be/privacy/">Privacy statement</a></div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
