import React, { Component } from 'react'
import { ResponsiveContainer, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'

import Loader from '../Loader'

class Power extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title"><span>{this.props.type === 'gridActPwr' ? 'Grid' : 'Battery'} Power ({this.props.unit}) {this.props.type !== 'gridActPwr' ? <small><i className="fa fa-info-circle ml-1 text-info" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="Power + sign: BESS is charging (consumption)<br>Power - sign: BESS is discharging (injection)"></i></small> : null}</span>
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_power" onChange={e => this.props.toggleDynamicYAxis(e, this.props.type)} />
                                <label className="form-check-label" htmlFor="yaxis_power">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingBatteryData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <AreaChart data={this.props.batteryData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} minTickGap={50} />
                                    <YAxis type="number" domain={this.props.dynamicYAxis[`${this.props.type}`] ? ['auto', 'auto'] : [dataMin => dataMin >= -this.props.site.battery.assetSpecs.nominal_power ? (Math.floor(-this.props.site.battery.assetSpecs.nominal_power / 10) * 10) : (Math.floor(dataMin / 1000) * 1000), dataMax => dataMax <= this.props.site.battery.assetSpecs.nominal_power ? (Math.ceil(this.props.site.battery.assetSpecs.nominal_power / 10) * 10) : (Math.ceil(dataMax / 1000) * 1000)]} />
                                    <Tooltip />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    {
                                        this.props.type === 'gridActPwr' ?
                                            data.grid === false ? null : <Area type="monotone" dataKey={`gridActPwr${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.gridActPwr ? ' ' : ''}`} name="Grid power" unit={this.props.unit} stroke="#bb5f00" fill="#bb5f00" fillOpacity="0.1" dot={dot => this.props.setDot && this.props.setDot(dot, `gridActPwr${this.props.unit === 'kW' ? '' : 'MW'}`, this.props.unit, 3, -10, 10, 'chart-battery', this.props.batteryData)} isAnimationActive={false} key={0} />
                                            :
                                            this.props.site.siteId === 'aes' ?
                                                [
                                                    data.fcr === false ? null : <Area type="monotone" dataKey={`fcr${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.fcr ? ' ' : ''}`} name="FCR power requested" unit={this.props.unit} stroke="#ffbc00" fill="#ffbc00" fillOpacity="0.1" isAnimationActive={false} key={0} />,
                                                    data.target === false ? null : <Area type="monotone" dataKey={`yusoTarget${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.yusoTarget ? ' ' : ''}`} name="Yuso target" unit={this.props.unit} stroke="#d04239" fill="#d04239" fillOpacity="0.1" isAnimationActive={false} key={1} />,
                                                    data.actual === false ? null : <Area type="monotone" dataKey={`power${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.power ? ' ' : ''}`} name="Actual power" unit={this.props.unit} stroke="#3f51b5" fill="#3f51b5" fillOpacity="0.1" dot={dot => this.props.setDot && this.props.setDot(dot, `power${this.props.unit === 'kW' ? '' : 'MW'}`, this.props.unit, 3, -10, 10, 'chart-battery', this.props.batteryData)} isAnimationActive={false} key={3} />,
                                                    data.grid === false ? null : this.props.report && <Area type="monotone" dataKey={`gridActPwr${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.gridActPwr ? ' ' : ''}`} name="Grid power" unit={this.props.unit} stroke="#bb5f00" fill="#bb5f00" fillOpacity="0.1" isAnimationActive={false} key={4} />
                                                ]
                                                :
                                                [
                                                    // data.fcr === false ? null : <Area type="monotone" dataKey={`fcr${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.fcr ? ' ' : ''}`} name="FCR power requested" unit={this.props.unit} stroke="#ffbc00" fill="#ffbc00" fillOpacity="0.1" isAnimationActive={false} key={0} />,
                                                    data.requested === false ? null : <Area type="monotone" dataKey={`activePwrReq2${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.activePwrReq2 ? ' ' : ''}`} name="Requested power" unit={this.props.unit} stroke="#d04239" fill="#d04239" fillOpacity="0.1" isAnimationActive={false} key={2} />,
                                                    data.actual === false ? null : <Area type="monotone" dataKey={`activePwr2${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.activePwr2 ? ' ' : ''}`} name="Actual power" unit={this.props.unit} stroke="#3f51b5" fill="#3f51b5" fillOpacity="0.1" dot={dot => this.props.setDot && this.props.setDot(dot, `activePwr${this.props.unit === 'kW' ? '' : 'MW'}`, this.props.unit, 3, -10, 10, 'chart-battery', this.props.batteryData)} isAnimationActive={false} key={3} />,
                                                    data.grid === false ? null : this.props.report && <Area type="monotone" dataKey={`gridActPwr${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.gridActPwr ? ' ' : ''}`} name="Grid power" unit={this.props.unit} stroke="#bb5f00" fill="#bb5f00" fillOpacity="0.1" isAnimationActive={false} key={4} />
                                                ]
                                    }
                                </AreaChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default Power