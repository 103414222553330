import React, { Component } from 'react'
import AceEditor from 'react-ace'

import { saveSteerConfig } from '../api/sites'

class Configuration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            deviceId: this.props.site.battery.deviceId,
            steerConfigInput: { ...this.props.site.battery.steerConfig.input },
            steerJson: JSON.stringify({ ...this.props.site.battery.steerConfig.input }, null, 4),
            editJson: false,
            jsonError: false,
            paramsDisabled: ["direct", "dynamic self-supply"].includes(this.props.site.battery.flexPortalConfig.steering.parameters.active_ems_mode_req) ? true : false,
            configChanged: false,
            saving: false,
            refreshing: false,
            updated: false
        }

        this.fieldChange = this.fieldChange.bind(this)
        this.resetConfig = this.resetConfig.bind(this)
        this.editJson = this.editJson.bind(this)
        this.openEditor = this.openEditor.bind(this)
        this.cancelJsonEdit = this.cancelJsonEdit.bind(this)
        this.onConfigChange = this.onConfigChange.bind(this)
        this.onConfigValidate = this.onConfigValidate.bind(this)
        this.updateConfig = this.updateConfig.bind(this)
    }

    componentWillReceiveProps() {
        this.setState({ refreshing: false })
    }

    fieldChange(e) {
        let steerConfigInput = { ...this.state.steerConfigInput }

        if (e.target.name === 'system_mode_req')
            steerConfigInput[e.target.name] = e.target.checked ? 'on' : 'off'
        else
            steerConfigInput[e.target.name] = e.target.value !== '' ? e.target.value : ''

        this.setState({
            steerConfigInput,
            configChanged: JSON.stringify(steerConfigInput) !== JSON.stringify(this.props.site.battery.steerConfig.input),
            paramsDisabled: e.target.name === 'active_ems_mode_req' ? ["direct", "dynamic self-supply"].includes(e.target.value) ? false : true : this.state.paramsDisabled
        })

    }

    resetConfig() {
        this.setState({
            steerConfig: { ...this.props.site.battery.steerConfig.input },
            steerJson: JSON.stringify(this.props.site.battery.steerConfig.input, null, 4),
            paramsDisabled: ["direct", "dynamic self-supply"].includes(this.props.site.battery.steerConfig.input.active_ems_mode_req) ? false : true,
            configChanged: false
        })
    }

    editJson() {
        window.$('#json-warning').modal('show')
    }

    openEditor() {
        this.setState({
            steerConfig: JSON.stringify({ ...this.props.site.battery.steerConfig.input }, null, 4),
            editJson: true
        })
    }

    cancelJsonEdit() {
        this.setState({
            editJson: false,
            steerConfig: { ...this.props.site.steerConfig }
        })
    }

    onConfigChange(steerJson) {
        this.setState({
            steerJson
        })
    }

    onConfigValidate(annotations) {
        this.setState({
            jsonError: !!annotations.find(x => x.type === 'error')
        })
    }

    updateConfig(e) {
        const action = e.target.id
        let json = {}

        this.setState({
            saving: true
        }, async () => {
            try {
                Object.getOwnPropertyNames(json).forEach(prop => {
                    if (json[`${prop}`] === '')
                        throw new Error(`Field ${prop} is required`)
                })

                let res = await saveSteerConfig(
                    this.state.deviceId,
                    action,
                    {
                        json: this.state.editJson ? this.state.steerJson : JSON.stringify(this.state.steerConfigInput)
                    }
                )

                if (res.error)
                    throw res.error

                this.setState({
                    saving: false,
                    updated: true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            updated: false
                        })
                    }, 5000);
                })

                this.props.updateSites()

            } catch (error) {
                this.setState({
                    saving: false,
                    jsonError: true,
                    error: error
                })
            }
        })
    }

    render() {
        const params = this.props.site.battery.flexPortalConfig.steering.parameters

        return (
            <div>
                <h5 className="text-primary font-weight-light mb-4">
                    You can steer {this.props.site.siteName} Battery using the configuration form bellow.
                </h5>
                <div className="row">
                    {
                        !this.state.editJson || this.props.user.info.role !== 'admin' ?
                            <form className="col-sm-5" noValidate>

                                <div className="form-group row required">
                                    <label className="col-sm-6 col-form-label pt-2">System Mode Requested</label>
                                    <div className="col-sm">
                                        <div className="custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="system_mode_req" name="system_mode_req" checked={this.state.steerConfigInput.system_mode_req === 'on' ? true : false} onClick={this.fieldChange} />
                                            <label className="custom-control-label" htmlFor="system_mode_req">{this.state.steerConfigInput.system_mode_req.toUpperCase()}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row required">
                                    <label className="col-sm-6 col-form-label pt-2">EMS Mode Requested</label>
                                    <div className="col-sm">
                                        <select className="form-control" id="active_ems_mode_req" name="active_ems_mode_req" value={this.state.steerConfigInput.active_ems_mode_req} onChange={this.fieldChange} required>
                                            {
                                                params.active_ems_mode_req.map(x => <option value={x.mode}>{x.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className={"form-group row required" + (!params.active_power_req ? " d-none" : "")}>
                                    <div className="col-sm-6 col-form-label">
                                        <label className="pt-2">Real Power Requested (kW)</label>&nbsp;
                                        <big><i className="fa fa-info-circle pt-1 text-info" data-toggle="tooltip" title="Requested real power with [0.1kW] resolution. Power flow in direction of battery is positive. If the requested power in this register is permitted and non-zero, the inverter will adjust this real power. In case this value is set to zero, the asset will be steered based on the imbalance price forecast, set power thresholds and maxChargePower/maxDischargePower."></i></big>
                                    </div>
                                    <div className="col-sm">
                                        <input className="form-control" type="number" value={!this.state.paramsDisabled ? this.state.steerConfigInput.active_power_req : ''} name="active_power_req" onChange={this.fieldChange} disabled={this.state.paramsDisabled} required />
                                    </div>
                                </div>

                                <div className={"form-group row required" + (!params.lower_threshold ? " d-none" : "")}>
                                    <div className="col-sm-6 col-form-label">
                                        <label className="pt-2">Lower Threshold (€/MWh)</label>&nbsp;
                                    </div>
                                    <div className="col-sm">
                                        <input className="form-control" type="number" value={!this.state.paramsDisabled ? this.state.steerConfigInput.lower_threshold : ''} name="lower_threshold" onChange={this.fieldChange} disabled={this.state.paramsDisabled} required />
                                    </div>
                                </div>

                                <div className={"form-group row required" + (!params.upper_threshold ? " d-none" : "")}>
                                    <div className="col-sm-6 col-form-label">
                                        <label className="pt-2">Upper Threshold (€/MWh)</label>&nbsp;
                                    </div>
                                    <div className="col-sm">
                                        <input className="form-control" type="number" value={!this.state.paramsDisabled ? this.state.steerConfigInput.upper_threshold : ''} name="upper_threshold" onChange={this.fieldChange} disabled={this.state.paramsDisabled} required />
                                    </div>
                                </div>

                                <div className={"form-group row required" + (!params.max_charge_power_yuso ? " d-none" : "")}>
                                    <div className="col-sm-6 col-form-label">
                                        <label className="pt-2">Maximum Charge Power (kW)</label>&nbsp;
                                        <big><i className="fa fa-info-circle pt-1 text-info" data-toggle="tooltip" title="Actual maximum charge power [0.1kW] applied by Yuo"></i></big>
                                    </div>
                                    <div className="col-sm">
                                        <input className="form-control" type="number" value={!this.state.paramsDisabled ? this.state.steerConfigInput.max_charge_power_yuso : ''} name="max_charge_power_yuso" onChange={this.fieldChange} disabled={this.state.paramsDisabled} required />
                                    </div>
                                </div>


                                <div className={"form-group row required" + (!params.max_discharge_power_yuso ? " d-none" : "")}>
                                    <div className="col-sm-6 col-form-label">
                                        <label className="pt-2">Maximum Discharge Power (kW)</label>&nbsp;
                                        <big><i className="fa fa-info-circle pt-1 text-info" data-toggle="tooltip" title="Actual maximum discharge power [0.1kW] applied by Yuso"></i></big>
                                    </div>
                                    <div className="col-sm">
                                        <input className="form-control" type="number" value={!this.state.paramsDisabled ? this.state.steerConfigInput.max_discharge_power_yuso : ''} name="max_discharge_power_yuso" onChange={this.fieldChange} disabled={this.state.paramsDisabled} required />
                                    </div>
                                </div>
                                {
                                    this.props.user.info.role === 'admin' ?
                                        <div className="text-right mb-3">
                                            <span className="text-primary ml-auto cursor pointer" onClick={this.editJson}>Edit raw JSON configuration</span>
                                        </div>
                                        : null
                                }
                                <div className="text-right">
                                    <button type="button" className="btn btn-primary ml-auto mr-3" onClick={e => { e.preventDefault(); this.setState({ refreshing: true }, () => this.props.updateSites()) }} disabled={this.state.refreshing}>{!this.state.refreshing ? 'Refresh' : <span>Refreshing... <i className="fas fa-spinner fa-spin mr-2"></i></span>}</button>
                                    <button type="button" className="btn btn-secondary ml-auto mr-3" disabled={!this.state.configChanged || this.state.saving} onClick={this.resetConfig}>Reset form <small><i className="fas fa-undo"></i></small></button>
                                    <button type="button" className="btn btn-secondary ml-auto mr-3" disabled={!this.state.configChanged || this.state.saving} id="save">{!this.state.saving ? <span id="save" onClick={this.updateConfig}>Save <i className="fas fa-info-circle text-light cursor help" title="New configuration will be executed on next attempt to steer the battery (done every minute)."></i></span> : <span>Saving... <i className="fas fa-spinner fa-spin mr-2"></i></span>}</button>
                                    <button type="button" className="btn btn-primary ml-auto" disabled={!this.state.configChanged || this.state.saving} id="execute" onClick={this.updateConfig}>{!this.state.saving ? 'Save & Execute' : <span>Saving... <i className="fas fa-spinner fa-spin mr-2"></i></span>}</button>
                                </div>
                                {
                                    this.state.error ? <p className="alert alert-danger mt-2"><i className="fas fa-exclamation-triangle"></i> {this.state.error}</p> : null
                                }

                                <p className={'alert alert-info my-3 ' + (this.state.updated ? '' : 'd-none')}>
                                    <i className="fas fa-info-circle mr-2"></i> The JSON configuration was succesfully updated.
                                </p>
                            </form>
                            :
                            this.props.user.info.role === 'admin' ?
                                <div className="row">
                                    <div className="col-sm-9">
                                        <p className="alert alert-warning text-body">
                                            <i className="fas fa-exclamation-triangle"></i> Changing the JSON direclty could result in broken configuration which could affect the functionality of the portal. Always make sure to save a valid JSON configuration.
                                        </p>

                                        {
                                            this.state.jsonError ?
                                                <p className="alert alert-danger">
                                                    <i className="fas fa-exclamation-triangle"></i>The JSON configuration has errors, please fix them before saving the JSON.
                                                </p>
                                                : null
                                        }

                                        <AceEditor
                                            className="border border-light w-100"
                                            mode="json"
                                            theme="tomorrow"
                                            tabSize={4}
                                            wrapEnabled={true}
                                            onChange={this.onConfigChange}
                                            onValidate={this.onConfigValidate}
                                            value={this.state.steerJson}
                                        />

                                        <div className="text-right mt-2">
                                            <button type="button" className="btn btn-primary ml-auto mr-3" onClick={e => { e.preventDefault(); this.setState({ refreshing: true }, () => this.props.updateSites()) }} disabled={this.state.refreshing}>{!this.state.refreshing ? 'Refresh' : <span>Refreshing... <i className="fas fa-spinner fa-spin mr-2"></i></span>}</button>
                                            <button className="btn btn-secondary ml-auto mr-3" onClick={this.cancelJsonEdit}>Cancel</button>
                                            <button type="button" className="btn btn-secondary ml-auto mr-3" onClick={this.resetConfig}>Reset JSON <small><i className="fas fa-undo"></i></small></button>
                                            <button type="button" className="btn btn-secondary ml-auto mr-3" disabled={this.state.jsonError || this.state.saving} id="save" onClick={this.updateConfig}>{!this.state.saving ? <span id="save" onClick={this.updateConfig}>Save <i classvalue="save" Name="fas fa-info-circle text-light cursor help" title="New configuration will be executed on next attempt to steer the battery (done every minute)."></i></span> : <span>Saving... <i className="fas fa-spinner fa-spin mr-2"></i></span>}</button>
                                            <button type="button" className="btn btn-primary ml-auto" disabled={this.state.jsonError || this.state.saving} id="execute" onClick={this.updateConfig}>{!this.state.saving ? 'Save & Execute' : <span>Saving... <i className="fas fa-spinner fa-spin mr-2"></i></span>}</button>
                                        </div>

                                        <p className={'alert alert-info my-3 ' + (this.state.updated ? '' : 'd-none')}>
                                            <i className="fas fa-info-circle mr-2"></i> The JSON configuration was succesfully updated.
                                        </p>
                                    </div>
                                </div>
                                : null
                    }
                </div >

                <div className="modal fade" id="json-warning" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="fas fa-exclamation-triangle text-warning mr-2"></i> Warning</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><strong>This is advanced feature intended for administrators!</strong></p>
                                <p>Note that raw JSON editing could break the configuration which could affect the steering of the battery. Only proceed if you know what you're doing.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.openEditor}>Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Configuration