import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader';

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sites: undefined
        }

        this.formatCapacity = this.formatCapacity.bind(this)
        this.formatPower = this.formatPower.bind(this)
    }

    componentDidMount() {

        this.setState({
            sites: this.props.sites
        })
    }

    formatCapacity(nominal_energy) {
        let formatted = 'N/A'

        if (nominal_energy) {
            formatted = nominal_energy + 'kWh'

            if (nominal_energy > 1000) {
                formatted = (nominal_energy / 1000) + 'MWh'
            }
            else if (nominal_energy > 1000000) {
                formatted = (nominal_energy / 1000000) + 'GWh'
            }
        }

        return formatted
    }

    formatPower(nominal_power) {
        let formatted = 'N/A'

        if (nominal_power) {
            formatted = nominal_power + 'kW'

            if (nominal_power > 1000) {
                formatted = (nominal_power / 1000) + 'MW'
            }
            else if (nominal_power > 1000000) {
                formatted = (nominal_power / 1000000) + 'GW'
            }
        }

        return formatted
    }

    render() {

        if (!this.state.sites)
            return <Loader />

        if (this.state.sites.length) {

            return <div className="row">
                {
                    this.state.sites.map(site => {

                        return <div className="col-sm-6 col-lg-3 col mb-4" key={site.siteId}>
                            <div className={`card border-0 h-100${site.visible ? '' : ' bg-light'}`}>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="card-title mb-2">
                                            {site.siteName}: <span className="text-muted">{site.battery && site.battery.assetSpecs ? site.battery && site.battery.assetSpecs.producer : 'N/A'}</span>
                                        </h5>
                                        {
                                            this.props.user.info.role === 'admin' || site.siteId === 'aes' // allow AES team to view Management page (just Allocation schedule)
                                                || (site.battery && site.battery.flexPortalConfig && site.battery.flexPortalConfig.steering && (site.battery.flexPortalConfig.steering.enabled && site.battery.flexPortalConfig.steering.user_access.includes(this.props.user.info.email))) ?
                                                <Link to={`/${site.siteId}/management`} onClick={() => this.props.selectSite(site.siteId)} title="Management">
                                                    <i className="fas fa-cog text-primary"></i>
                                                </Link>
                                                : null
                                        }
                                    </div>
                                    <div className="mb-3">
                                        <h6>Power: {site.battery && site.battery.assetSpecs ? this.formatPower(site.battery && site.battery.assetSpecs.nominal_power) : 'N/A'}, Capacity: {site.battery && site.battery.assetSpecs ? this.formatCapacity(site.battery && site.battery.assetSpecs.nominal_energy) : 'N/A'}</h6>
                                        <small>Owner: {site.companyName} ({site.companyNumber})</small>
                                    </div>
                                    <div className="d-flex flex-column flex-xl-row">
                                        <Link to={`/${site.siteId}/monitoring`} onClick={() => this.props.selectSite(site.siteId)} className="btn btn-info mb-2 mr-xl-4">
                                            <i className="fas fa-bolt"></i> Monitoring
                                        </Link>
                                        <Link to={`/${site.siteId}/reports`} onClick={() => this.props.selectSite(site.siteId)} className="btn btn-info mb-2">
                                            <i className="fas fa-chart-bar"></i> Reports
                                        </Link>
                                    </div>
                                    <ul className="list-group list-group-flush mt-4">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <strong>Devices on site</strong>
                                            <span className="badge badge-warning badge-pill font-weight-bold cursor default" title="Number of devices on site">{site.devices.length}</span>
                                        </li>
                                        {
                                            site.devices.map(device => {
                                                return <li className="list-group-item" title={`Device ID: ${device.deviceId}`} key={device.deviceId}>
                                                    {device.deviceType}: {device.deviceName}
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        }
        else {

            return <div className="row d-flex justify-content-center">
                <div className="col-sm-6 text-center py-5">
                    <span className="display-1">
                        <i className="fas fa-exclamation-triangle text-danger"></i>
                    </span>
                    <h1>You have not yet connected battery to Yuso</h1>
                    <p className="h5 pb-5 mb-5">
                        A battery system, linked to local production, can avoid distribution and transport costs of your energy. Yuso helps you with dimensioning, financing and market-driven control of your battery system. Interested? Contact us at <a href="mailto:info@yuso.be">info@yuso.be</a>.
                    </p>
                </div>
            </div>
        }
    }
}

export default Home