import React, { Component } from 'react'
import moment from 'moment-timezone'
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'

import RecursiveProperty from './RecursiveProperty'

import 'ace-builds'
import 'ace-builds/webpack-resolver'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-tomorrow'

import { saveSiteParams, saveSiteConfig } from '../api/sites'

import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'

moment.tz.setDefault('Europe/Brussels')


class Configuration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            config: { ...this.props.site.config },
            json: JSON.stringify({ ...this.props.site.config }, null, 4),
            editJson: false,
            jsonError: false,
            siteName: this.props.site.siteName,
            companyNumber: this.props.site.companyNumber
        }

        this.editJson = this.editJson.bind(this)
        this.openEditor = this.openEditor.bind(this)
        this.saveJson = this.saveJson.bind(this)
        this.cancelJsonEdit = this.cancelJsonEdit.bind(this)
        this.onConfigChange = this.onConfigChange.bind(this)
        this.onConfigValidate = this.onConfigValidate.bind(this)
        this.onSiteParamsSave = this.onSiteParamsSave.bind(this)
        this.fieldChange = this.fieldChange.bind(this)
        this.onCompanyChanged = this.onCompanyChanged.bind(this)
        this.editProperty = this.editProperty.bind(this)
    }

    editJson() {
        window.$('#json-warning').modal('show')
    }

    openEditor() {
        this.setState({
            json: JSON.stringify({ ...this.props.site.config }, null, 4),
            editJson: true
        })
    }

    async saveJson() {
        this.setState({
            savingJson: true
        })

        // test if the JSON configuration is valid before saving
        try {
            JSON.parse(this.state.json)
        }
        catch(err) {
            this.setState({
                savingJson: false,
                jsonError: !!err
            })
            return
        }

        // save raw JSON configuration
        try {
            await saveSiteConfig(this.props.site.siteId, { json: this.state.json })
            // since JSON configuration is changed we need to update the sites
            this.props.updateSites()
        }
        catch (err) {
            console.error(err)
        }

        this.setState({
            savingJson: false,
            editJson: false
        })  
    }

    cancelJsonEdit() {
        this.setState({
            editJson: false
        })
    }

    onConfigChange(json) {
        this.setState({
            json
        })
    }

    onConfigValidate(annotations) {
        this.setState({
            jsonError: !!annotations.find(x => x.type === 'error')
        })
    }

    async onSiteParamsSave(e) {
        e.preventDefault()

        this.setState({ error: undefined })

        let error

        const form = [ ...e.target.elements ]

        // validate only the site name
        const name = form.find(x => x.name === 'siteName')

        if (name.required && !name.value.trim().length) {
            error= `Site name is required`
        }
    
        if (error) {
            this.setState({ error })
            return
        }

        this.setState({
            savingParams: true
        })

        // save the site params
        try {
            await saveSiteParams(this.props.site.siteId, this.state)
            // since site params are changed we need to update the sites
            this.props.updateSites()
        }
        catch (err) {
            console.error(err)
        }

        this.setState({
            savingParams: false
        })  
    }

    fieldChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onCompanyChanged(selected) {
        let value = selected.length ? selected[0].companyNumber : ''
        this.fieldChange({ target: { name: 'companyNumber', value } })
    }

    getObjectKey(obj, path) {
        return path.reduce((prev, curr) => {
            return prev ? prev[curr] : null
        }, obj)
    }

    setObjectKey(obj, path, value) {
        if (path.length === 1) {
            obj[path] = value
            return
        }
        return this.setObjectKey(obj[path[0]], path.slice(1), value)
    }

    async editProperty(key, value) {
        let config = { ...this.state.config }
        let keys = key.split('.')

        this.setObjectKey(config, keys, value)

        // save raw JSON configuration
        try {
            await saveSiteConfig(this.props.site.siteId, { json: JSON.stringify({ ...config }, null, 4) })

            this.setState({
                config
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    render() {

        return (
            <div>
                {
                    this.props.user.info.role === 'admin' ?
                        <div className="row">
                            <div className="col-sm">
                                <form className="form-inline" onSubmit={this.onSiteParamsSave} noValidate>
                                    <label className="mb-2 mr-sm-2" htmlFor="siteName">Site</label>
                                    <input type="text" className="form-control mb-2 mr-sm-2" id="site_name" name="siteName" placeholder="Site name" value={this.state.siteName} onChange={this.fieldChange} required />

                                    <label className="mb-2 mr-sm-2" htmlFor="companyNumber">Company</label>

                                    <Typeahead
                                        clearButton
                                        useCache={false}
                                        id="companyNumber"
                                        name="companyNumber"
                                        labelKey={option => `${option.companyNumber} - ${option.companyName}`}
                                        renderMenu={(results, menuProps) => (
                                            <Menu {...menuProps}>
                                                {results.map((result, index) => (
                                                    <MenuItem option={result} position={index} key={index}>
                                                        {result.companyName}
                                                        <br />
                                                        <small className="text-muted">{result.companyNumber}</small>
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        )}
                                        inputProps={{ style: { width: '600px' } }}
                                        className="mb-2 mr-sm-2"
                                        defaultSelected={this.props.user.info.companies.filter(x => x.companyNumber === this.state.companyNumber) || []}
                                        placeholder="Select company"
                                        options={this.props.user.info.companies}
                                        onChange={this.onCompanyChanged}
                                    />
                                    
                                    <button type="submit" className="btn btn-primary mb-2 mr-sm-2 btn-form" disabled={this.state.savingParams}>
                                        {
                                            this.state.savingParams ? <i className="fas fa-spinner fa-spin mr-2"></i> : null
                                        }
                                        {
                                            this.state.savingParams ? 'Saving...' : 'Save'
                                        }
                                    </button>

                                    {
                                        this.state.error ?
                                            <div className="alert alert-warning border border-warning mb-2">
                                                <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
                                            </div>
                                            : null
                                    }
                                </form>
                            </div>
                        </div>
                        : null
                }
                <div className="row">
                    <div className="col-sm">
                        {
                            !this.state.editJson ?
                                    <div>
                                        {
                                            this.props.user.info.role === 'admin' ?
                                                <div className="d-flex">
                                                    <span className="text-primary ml-auto cursor pointer" onClick={this.editJson}>Edit raw JSON configuration</span>
                                                </div>
                                                : null
                                        }
                                        <RecursiveProperty
                                            property={this.state.config}
                                            propertyName="Site configuration"
                                            rootProperty={true}
                                            excludeBottomBorder={true}
                                            editProperty={this.editProperty}
                                            user={this.props.user}
                                        />
                                    </div>
                                :
                                this.props.user.info.role === 'admin' &&
                                    <div>
                                        <div className="d-flex mb-3">
                                            <button className="btn btn-secondary ml-auto mr-3" onClick={this.cancelJsonEdit}>Cancel</button>
                                            <button className="btn btn-primary" onClick={this.saveJson} disabled={this.state.savingJson || this.state.jsonError}>
                                                {
                                                    this.state.savingJson ? <i className="fas fa-spinner fa-spin mr-2"></i> : null
                                                }
                                                {
                                                    this.state.savingJson ? 'Saving...' : 'Save'
                                                }
                                            </button>
                                        </div>
                                        <p className="alert alert-warning text-body">
                                            <i className="fas fa-exclamation-triangle"></i> Changing the JSON direclty could result in broken configuration which could affect the functionality of the portal. Always make sure to save a valid JSON configuration.
                                        </p>
                                        {
                                            this.state.jsonError ?
                                                <p className="alert alert-danger">
                                                    <i className="fas fa-exclamation-triangle"></i> The JSON configuration has errors, please fix them before saving the JSON.
                                                </p>
                                                : null
                                        }
                                        <AceEditor
                                            className="border border-light w-100"
                                            mode="json"
                                            theme="tomorrow"
                                            tabSize={4}
                                            wrapEnabled={true}
                                            onChange={this.onConfigChange}
                                            onValidate={this.onConfigValidate}
                                            value={this.state.json}
                                        />
                                    </div>
                        }
                        
                    </div>

                    <div className="modal fade" id="json-warning" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><i className="fas fa-exclamation-triangle text-warning mr-2"></i> Warning</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        <strong>This is advanced feature intended for administrators!</strong>
                                    </p>
                                    <p>
                                        Note that raw JSON editing could break the configuration which could affect the functionality of the portal. Only proceed if you know what you're doing.
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.openEditor}>Proceed</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Configuration