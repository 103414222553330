import React, { Component } from 'react'
import { ResponsiveContainer, Line, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent'

import Loader from '../Loader'

const CustomTooltip = props => {

    if (props.active && props.payload && props.payload[0])
        props.payload.push({
            color: "#000000",
            dataKey: "FCRCapacityReq",
            name: "Required FCR Capacity",
            unit: props.unit,
            value: props.payload[0].payload.FCRCapacityReq || 'N/A ',
            payload: props.payload[0].payload
        }, {
            color: "#000000",
            dataKey: "FCRCapacityExec",
            name: "Target FCR Capacity by Controller",
            unit: props.unit,
            value: props.payload[0].payload.FCRCapacityExec || 'N/A ',
            payload: props.payload[0].payload
        })

    return <DefaultTooltipContent {...props} />
}

class FrequencyResponse extends Component {    
    render() {    
        const FCR_max = this.props.unit === 'kW' ? this.props.site.battery.servicesConfig.FCR.p_fcr_prequal_max * 1000 : this.props.site.battery.servicesConfig.FCR.p_fcr_prequal_max
       
        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">Frequency Response
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_adenergy" onChange={e => this.props.toggleDynamicYAxis(e, 'FrequencyResponse')} />
                                <label className="form-check-label" htmlFor="yaxis_adenergy">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingFrequencyData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={this.props.frequencyData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} minTickGap={50} />
                                    <YAxis type="number" tickFormatter={this.props.tickFormatterY} domain={[this.props.dynamicYAxis.FrequencyResponse ? 'auto' : -FCR_max || 'dataMin', this.props.dynamicYAxis.FrequencyResponse ? 'auto' : FCR_max || 'dataMax']} />
                                    <Tooltip content={<CustomTooltip unit={this.props.unit} />} />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    <Line type="linear" dataKey={`FCRpowerCalculated${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.FCRpowerCalculated ? ' ' : ''}`} name="Required FCR Droop Curve" stroke="#FF2D00" unit={this.props.unit} dot={dot => this.props.setDot && this.props.setDot(dot, `FCRpowerCalculated${this.props.unit === 'kW' ? '' : 'MW'}`, this.props.unit, 3, -10, 10, 'chart-frequency', this.props.batteryData)} />
                                    <Line type="linear" dataKey={`FCRpowerExec${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.FCRpowerExec ? ' ' : ''}`} name="Target FCR setpoint by Controller" stroke="#0049FF" unit={this.props.unit} dot={false} />
                                </LineChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default FrequencyResponse