import React, { Component } from 'react'
import { ResponsiveContainer, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from 'recharts'

import Loader from '../Loader'

class Imbalance extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">System Imbalance and Regulating Power ({this.props.unit})
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_imbalance" onChange={e => this.props.toggleDynamicYAxis(e, 'imbalance')} />
                                <label className="form-check-label" htmlFor="yaxis_imbalance">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingImbalanceData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <AreaChart data={this.props.imbalanceData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} minTickGap={50} />
                                    <YAxis type="number" domain={[this.props.dynamicYAxis.imbalance ? 'auto' : 'dataMin - 10', this.props.dynamicYAxis.imbalance ? 'auto' : 'dataMax + 10']} tickFormatter={this.props.tickFormatterY} />
                                    <Tooltip />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    <ReferenceLine y={0} stroke="#ffbc00" ifOverflow="extendDomain" />
                                    {
                                        this.props.site.siteId === 'aes' ?
                                            [
                                                data.aFRR === false ? null : <Area type="monotone" dataKey={`aFRR${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.aFRR ? ' ' : ''}`} name="aFRR" unit={this.props.unit} stroke="#d04239" fill="#d04239" fillOpacity="0.1" dot={dot => this.props.setDot && this.props.setDot(dot, `aFRR${this.props.unit === 'kW' ? '' : 'MW'}`, this.props.unit, 2, -500, 500, 'chart-imbalance', this.props.imbalanceData)} isAnimationActive={false} key={0} />,
                                                data.mFRR === false ? null : <Area type="monotone" dataKey={`mFRR${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.mFRR ? ' ' : ''}`} name="mFRR" unit={this.props.unit} stroke="#88bb56" fill="#88bb56" fillOpacity="0.1" isAnimationActive={false} key={1} />
                                            ]
                                            :
                                            [
                                                data.aFRR === false ? null : <Area type="monotone" stackId="1" dataKey={`aFRR${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.aFRR ? ' ' : ''}`} name="aFRR" unit={this.props.unit} stroke="#d04239" fill="#d04239" fillOpacity="0.1" dot={dot => this.props.setDot && this.props.setDot(dot, `aFRR${this.props.unit === 'kW' ? '' : 'MW'}`, this.props.unit, 2, -500, 500, 'chart-imbalance', this.props.imbalanceData)} isAnimationActive={false} key={0} />,
                                                data.mFRR === false ? null : <Area type="monotone" stackId="1" dataKey={`mFRR${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.mFRR ? ' ' : ''}`} name="mFRR" unit={this.props.unit} stroke="#88bb56" fill="#88bb56" fillOpacity="0.1" isAnimationActive={false} key={1} />
                                            ]
                                    }
                                </AreaChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default Imbalance