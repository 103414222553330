import * as React from 'react'
import ExpandableProperty from './ExpandableProperty'


class RecursiveProperty extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            edit: false,
            key: this.props.rootProperty ? '' : `${this.props.prevProperty}.${this.props.propertyName}`,
            value: this.props.property ? this.props.property.toString() : 'n/a'
        }

        this.camelCaseToNormal = this.camelCaseToNormal.bind(this)
        this.openEdit = this.openEdit.bind(this)
        this.closeEdit = this.closeEdit.bind(this)
        this.cancelEdit = this.cancelEdit.bind(this)
        this.saveEdit = this.saveEdit.bind(this)
        this.fieldChange = this.fieldChange.bind(this)
    }

    camelCaseToNormal(str) {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, x => x.toUpperCase())
    }

    openEdit() {
        this.setState({
            edit: true
        })
    }

    closeEdit() {
        this.setState({
            edit: false
        })
    }

    cancelEdit() {
        this.setState({
            value: this.props.property ? this.props.property.toString() : 'n/a'
        })
        this.closeEdit()
    }

    saveEdit() {
        this.props.editProperty(this.state.key, this.state.value)
        this.closeEdit()
    }

    fieldChange(e) {
        const value = e.target.value
        this.setState({
            value
        })
    }

    render() {

        return (
            <div className={'config-property ' + (this.props.excludeBottomBorder ? '' : 'config-border')}>
                {
                    typeof this.props.property === 'number' ||
                    typeof this.props.property === 'string' ||
                    typeof this.props.property === 'boolean' ||
                    typeof this.props.property === 'undefined' ||
                    this.props.property === null ?
                        (
                            <React.Fragment>
                                <div className="property-wrap">
                                    <span className="config-key">{this.camelCaseToNormal(this.props.propertyName)}: </span>
                                    &nbsp;
                                    <span>
                                        {
                                            !this.state.edit || this.props.user.info.role !== 'admin' ?
                                                <span>{this.state.value}</span>
                                                :
                                                <input className="edit-input" value={this.state.value} name={this.state.key} onChange={this.fieldChange} />
                                        }
                                        &nbsp;
                                        {
                                            this.props.user.info.role === 'admin' ?
                                                <small>
                                                    {
                                                        !this.state.edit ?
                                                            <i className="fas fa-pencil-alt text-info cursor pointer edit-property" title="Edit" onClick={this.openEdit}></i>
                                                            :
                                                            <span>
                                                                <i className="fas fa-times text-danger mx-1 cursor pointer" title="Cancel" onClick={this.cancelEdit}></i>
                                                                <i className="fas fa-check text-success ml-1 cursor pointer" title="Save" onClick={this.saveEdit}></i>
                                                            </span>
                                                    }
                                                </small>
                                                : null
                                        }
                                    </span>
                                </div>
                            </React.Fragment>
                        )
                        :
                        (
                            <ExpandableProperty title={this.camelCaseToNormal(this.props.propertyName)} expanded={!!this.props.rootProperty}>
                                {Object.values(this.props.property).map((property, index, { length }) => (
                                    <RecursiveProperty
                                        key={index}
                                        property={property}
                                        propertyName={Object.getOwnPropertyNames(this.props.property)[index]}
                                        excludeBottomBorder={index === length - 1}
                                        editProperty={this.props.editProperty}
                                        user={this.props.user}
                                        prevProperty={this.props.rootProperty ? '' : (!this.props.prevProperty ? this.props.propertyName : `${this.props.prevProperty}.${this.props.propertyName}`)}
                                    />
                                ))}
                            </ExpandableProperty>
                        )
                }
            </div>
        )
    }
}

export default RecursiveProperty
