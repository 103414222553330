import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import './index.scss'

import App from './App'
import PiwikTracker from './components/PiwikTracker'

import * as serviceWorker from './serviceWorker'

import config from './api/config'

const piwik = PiwikTracker({
    url: config.TRACKING.URL,
    clientTrackerName: config.TRACKING.SCRIPT,
    siteId: config.TRACKING.SITE_ID
})

const history = createBrowserHistory()


ReactDOM.render((
    <Router history={piwik.connectToHistory(history)}>
        <App piwik={piwik} history={history} />
    </Router>
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
