import React, { Component } from 'react'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'

import Loader from '../Loader'

class BalancingPrice extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">Balancing Price  {this.props.site.siteId === 'aes' ? '(NL)' : '(BE)'}</h4>
                    {
                        this.props.report === true ?
                            <div className="col-sm col-md-8 offset-md-2">
                                <div className="card mb-8">
                                    <div className="card-body  p-2">
                                        <h5 className="card-title text-center" >Imbalance forecast accuracy</h5>
                                        <div className="card-text">
                                            {
                                                this.props.loadingImbForecastAccuracyData ?
                                                    <Loader>Loading...</Loader>
                                                    :
                                                    <div>
                                                        <h6 className="text-center font-weight-bold">
                                                            PT1 (1 min before quarter start): {this.props.imbForecastAccuracyData.PT1 ? `${this.props.imbForecastAccuracyData.PT1.toFixed(1)}%` : `N/A`}<br />
                                                            PT0 (5 minutes in the quarter): {this.props.imbForecastAccuracyData.PT0_5 ? `${this.props.imbForecastAccuracyData.PT0_5.toFixed(1)}%` : `N/A`}<br />
                                                            PT0 (end of quarter): {this.props.imbForecastAccuracyData.PT0_14 ? `${this.props.imbForecastAccuracyData.PT0_14.toFixed(1)}%` : `N/A`}
                                                        </h6>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                            : null
                    }
                    {
                        this.props.loadingBalancingPriceData ? <Loader className="height-400" /> : this.props.balancingPriceData.length < 1 ? <div className="height-400 d-flex justify-content-center align-items-center"><h1>N/A</h1></div> :
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={this.props.balancingPriceData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="pte_start" tickFormatter={this.props.tickFormatterX} />
                                    <YAxis type="number" domain={['auto', 'auto']} />
                                    <Tooltip formatter={value => value.toFixed(2)} />
                                    <Legend verticalAlign="bottom" onClick={this.props.toggleChart} />
                                    {
                                        this.props.site.siteId === 'aes' ?
                                            [
                                                data.realized_pos === false ? null : <Line type="stepAfter" dataKey={`realized_pos${this.props.hidden.realized_pos ? ' ' : ''}`} name="Realized Imbalance Price up" stroke="#ff0000" unit={'€/MWh'} dot={false} isAnimationActive={false} key={0} />,
                                                data.realized_neg === false ? null : <Line type="stepAfter" dataKey={`realized_neg${this.props.hidden.realized_neg ? ' ' : ''}`} name="Realized Imbalance Price down" stroke="#990000" unit={'€/MWh'} dot={false} isAnimationActive={false} key={1} />,
                                                data.pt1_priceup === false ? null : <Line type="stepAfter" dataKey={`prediction_priceup_PT1${this.props.hidden.prediction_priceup_PT1 ? ' ' : ''}`} name="PT1 price up (1 min before quarter)" stroke="#ffaeb4" unit={'€/MWh'} dot={false} isAnimationActive={false} key={2} />,
                                                data.pt1_pricedown === false ? null : <Line type="stepAfter" dataKey={`prediction_pricedown_PT1${this.props.hidden.prediction_pricedown_PT1 ? ' ' : ''}`} name="PT1 price down (1 min before quarter)" stroke="#936E7F" unit={'€/MWh'} dot={false} isAnimationActive={false} key={3} />,
                                                data.pt0_5_priceup === false ? null : <Line type="stepAfter" dataKey={`prediction_priceup_PT0_5${this.props.hidden.prediction_priceup_PT0_5 ? ' ' : ''}`} name="PT0 price up (5 mins in the quarter)" stroke="#ffce3c" unit={'€/MWh'} dot={false} isAnimationActive={false} key={4} />,
                                                data.pt0_5_pricedown === false ? null : <Line type="stepAfter" dataKey={`prediction_pricedown_PT0_5${this.props.hidden.prediction_pricedown_PT0_5 ? ' ' : ''}`} name="PT0 price down (5 mins in the quarter)" stroke="#ff6d3c" unit={'€/MWh'} dot={false} isAnimationActive={false} key={5} />,
                                                data.pt0_14_priceup === false ? null : <Line type="stepAfter" dataKey={`prediction_priceup_PT0_14${this.props.hidden.prediction_priceup_PT0_14 ? ' ' : ''}`} name="PT0 price up (end of quarter)" stroke="#A2E53F" unit={'€/MWh'} dot={false} isAnimationActive={false} key={6} />,
                                                data.pt0_14_pricedown === false ? null : <Line type="stepAfter" dataKey={`prediction_pricedown_PT0_14${this.props.hidden.prediction_pricedown_PT0_14 ? ' ' : ''}`} name="PT0 price down (end of quarter)" stroke="#5A7F23" unit={'€/MWh'} dot={false} isAnimationActive={false} key={7} />,
                                                this.props.report === true || data.current_neg === false ? null : <Line type="stepAfter" dataKey={`current_neg${this.props.hidden.current_neg ? ' ' : ''}`} name="Current forecast down" stroke="#00546F" legendType="circle" unit={'€/MWh'} dot={{ r: 5, stroke: "#00384A", strokeWidth: 3 }} isAnimationActive={false} key={8} />,
                                                this.props.report === true || data.current_pos === false ? null : <Line type="stepAfter" dataKey={`current_pos${this.props.hidden.current_pos ? ' ' : ''}`} name="Current forecast up" stroke="#008cba" legendType="circle" unit={'€/MWh'} dot={dot => this.props.setDot && this.props.setDot(dot, 'current_pos', '€/MWh', 2, -600, 600, 'chart-balancingPrice', this.props.balancingPriceData)} isAnimationActive={false} key={9} />
                                            ]
                                            :
                                            [
                                                data.realized_pos === false ? null : <Line type="stepAfter" dataKey={`realized_pos${this.props.hidden.realized_pos ? ' ' : ''}`} name="Realized Imbalance Price up" stroke="#ff0000" unit={'€/MWh'} dot={false} isAnimationActive={false} key={0} />,
                                                data.realized_neg === false ? null : <Line type="stepAfter" dataKey={`realized_neg${this.props.hidden.realized_neg ? ' ' : ''}`} name="Realized Imbalance Price down" stroke="#990000" unit={'€/MWh'} dot={false} isAnimationActive={false} key={1} />,
                                                data.pt1_price === false ? null : <Line type="stepAfter" dataKey={`prediction_priceup_PT1${this.props.hidden.prediction_priceup_PT1 ? ' ' : ''}`} name="PT1 price prob (1 min before quarter)" stroke="#ffaeb4" unit={'€/MWh'} dot={false} isAnimationActive={false} key={2} />,
                                                data.pt0_5_price === false ? null : <Line type="stepAfter" dataKey={`prediction_priceup_PT0_5${this.props.hidden.prediction_priceup_PT0_5 ? ' ' : ''}`} name="PT0 price prob (5 mins in the quarter)" stroke="#ffce3c" unit={'€/MWh'} dot={false} isAnimationActive={false} key={3} />,
                                                data.pt0_14_priceup === false ? null : <Line type="stepAfter" dataKey={`prediction_priceup_PT0_14${this.props.hidden.prediction_priceup_PT0_14 ? ' ' : ''}`} name="PT0 price prob (end of quarter)" stroke="#A2E53F" unit={'€/MWh'} dot={false} isAnimationActive={false} key={4} />,
                                                this.props.report === true || data.current_pos === false ? null : <Line type="stepAfter" dataKey={`current_pos${this.props.hidden.current_pos ? ' ' : ''}`} name="Current forecast" stroke="#008cba" legendType="circle" unit={'€/MWh'} dot={dot => this.props.setDot && this.props.setDot(dot, 'current_pos', '€/MWh', 2, -600, 600, 'chart-balancingPrice', this.props.balancingPriceData)} isAnimationActive={false} key={5} />
                                            ]
                                    }
                                </LineChart>
                            </ResponsiveContainer>
                    }
                </div >
            </div >
        )
    }
}

export default BalancingPrice