import moment from 'moment-timezone'
import React, { Component } from 'react'
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent'

import Loader from '../Loader'

moment.tz.setDefault('UTC')

const CustomTooltip = props => {
    // payload[0] doesn't exist when tooltip isn't visible
    if (props.active && props.payload && props.payload[0]) {

        // mutating props directly is against react's conventions
        // so we create a new payload with the name and value fields set to what we want
        const label = 'UTC: ' + moment.tz(props.payload[0].payload.start_time_utc, 'YYYY-MM-DD HH:mm:ss', 'UTC').format('YYYY-MM-DD HH:mm') + ' – ' + moment.tz(props.payload[0].payload.end_time_utc, 'YYYY-MM-DD HH:mm:ss', 'UTC').format('YYYY-MM-DD HH:mm')

        // we render the default, but with our overridden payload
        return <DefaultTooltipContent {...props} label={label} />
    }

    // we just render the default
    return <DefaultTooltipContent {...props} />
}

class CapacityFCR extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title"><span>FCR Capacity ({this.props.unit})</span>
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_FCRCapacityExec" onChange={e => this.props.toggleDynamicYAxis(e, 'FCRCapacityExec')} />
                                <label className="form-check-label" htmlFor="yaxis_FCRCapacityExec">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingFCRData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={this.props.fcrData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={tick => this.props.tickFormatterX(tick, 'YYYY-MM-DD')} />
                                    <YAxis type="number" domain={[this.props.dynamicYAxis.FCRCapacityExec ? 'auto' : 'dataMin - 1', this.props.dynamicYAxis.FCRCapacityExec ? 'auto' : 'dataMax + 1']} tickFormatter={this.props.tickFormatterY} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    {
                                        [
                                            data.offered_capacity === false ? null : <Bar dataKey={`offered_capacity${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.offered_capacity ? ' ' : ''}`} name="Offered capacity" unit={this.props.unit} fill="#3f51b5" isAnimationActive={false} key={0} />,
                                            data.allocated_capacity === false ? null : <Bar dataKey={`allocated_capacity${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.allocated_capacity ? ' ' : ''}`} name="Allocated capacity" unit={this.props.unit} fill="#88bb56" isAnimationActive={false} key={1} />
                                        ]
                                    }
                                </BarChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default CapacityFCR