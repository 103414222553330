import React, { Component } from 'react'
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, ReferenceLine, Tooltip, Legend, BarChart, Bar } from 'recharts'
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent'

import Loader from '../Loader'

const CustomTooltip = props => {

    if (props.active && props.payload && props.payload[0])
        props.payload.push({
            color: props.payload[0].payload.validated >= 3 ? "#00FF00" : "#FF0000",
            dataKey: "validated",
            name: "Validated",
            value: props.payload[0].payload.validated >= 3 ? "Y" : "N",
            payload: props.payload[0].payload
        })

    return <DefaultTooltipContent {...props} />
}

class ImbalanceRevenue extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">Battery P&L on Elia Imbalance Mechanism (€) {!this.props.loadingRevenueData && this.props.revenueData.length ? <small><i className="fa fa-info-circle ml-1 text-info" data-toggle="tooltip" data-boundary="viewport" data-html="true" title={`Latest Battery P&L on Imbalance Arbitrage calculation available for: ${this.props.revenueData[this.props.revenueData.length - 1].time}`}></i></small> : null}
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_imbalanceRevenue" onChange={e => this.props.toggleDynamicYAxis(e, 'imbalanceRevenue')} />
                                <label className="form-check-label" htmlFor="yaxis_imbalanceRevenue">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingRevenueData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={this.props.revenueData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} barCategoryGap={'1%'} barGap={1} stackOffset="sign">
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} minTickGap={50} />
                                    <YAxis type="number" domain={this.props.dynamicYAxis.imbalanceRevenue ? ['auto', 'auto'] : [dataMin => (Math.floor(dataMin / 5) * 5), dataMax => (Math.ceil(dataMax / 5) * 5)]} tickFormatter={this.props.tickFormatterY} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    <ReferenceLine y={0} stroke="#66c766" ifOverflow="extendDomain" />
                                    {
                                        this.props.site.siteId === 'aes' ?
                                            [
                                                data.yusoImbalance === false ? null : <Bar dataKey={`yusoImbalance${this.props.hidden.yusoImbalance ? ' ' : ''}`} name="Yuso imbalance revenue" unit="€" stackId="revenue" fill="#3f51b5" legendType="circle" isAnimationActive={false} dot={false} key={0} />,
                                                data.fcrImbalance === false ? null : <Bar dataKey={`fcrImbalance${this.props.hidden.fcrImbalance ? ' ' : ''}`} name="FCR imbalance revenue" unit="€" stackId="revenue" fill="#ffbc00" legendType="circle" isAnimationActive={false} dot={false} key={1} />,
                                                data.residuImbalance === false ? null : <Bar dataKey={`residuImbalance${this.props.hidden.residuImbalance ? ' ' : ''}`} name="Residu imbalance revenue" unit="€" stackId="revenue" fill="#d04239" legendType="circle" isAnimationActive={false} dot={false} key={2} />
                                            ]
                                            :
                                            [
                                                data.imbalanceRevenue === false ? null : <Bar dataKey={`revenueImbalance${this.props.hidden.imbalanceRevenue ? ' ' : ''}`} name="Battery P&L on Elia Imbalance Mechanism" unit="€" fill="#5f5cc9" legendType="circle" isAnimationActive={false} dot={false} key={0} />
                                            ]
                                    }
                                </BarChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default ImbalanceRevenue