import config from './config'
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'

const userPool = new CognitoUserPool({
    UserPoolId: config.AWS_COGNITO_POOL_ID,
    ClientId: config.AWS_COGNITO_CLIENT_ID
})

const login = (user, pass, cb) => {
    const authenticationDetails = new AuthenticationDetails({
        Username: user,
        Password: pass
    })

    const cognitoUser = new CognitoUser({
        Username: user,
        Pool: userPool
    })

    cognitoUser.authenticateUser(
        authenticationDetails,
        {
            onSuccess: res => cb(null, cognitoUser),
            onFailure: err => cb(err),
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                cb({ newPassRequired: true })
            }
        }
    )
}

const getTokens = cb => {

    const currentUser = userPool.getCurrentUser()

    if(!currentUser) {
        // if no user found, clear the localStorage
        window.localStorage.clear()
        cb('User not logged in.')
        return window.location = '/'
    }

    currentUser.getSession(cb)
}

const getAccessToken = () => {

    return new Promise((resolve, reject) => {

        getTokens((err, res) => {
            if(err)
                return reject(err)
    
            resolve(res.accessToken.jwtToken)
        })
    })
}

const newPassword = (user, oldPass, newPass, cb) => {
    const authenticationDetails = new AuthenticationDetails({
        Username: user,
        Password: oldPass
    })

    const cognitoUser = new CognitoUser({
        Username: user,
        Pool: userPool
    })

    cognitoUser.authenticateUser(
        authenticationDetails,
        {
            onSuccess: res => {
                // return the logged in user object
                cb(null, cognitoUser)
            },
            onFailure: err => cb(err),
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                delete userAttributes.email_verified
                delete userAttributes.phone_number_verified
                cognitoUser.completeNewPasswordChallenge(newPass, userAttributes, this)
            }
        }
    )
}

const forgotPasswordInitiate = (email, cb) => {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool
    })

    cognitoUser.forgotPassword({
        onSuccess: data => cb(null, data),
        onFailure: err => cb(err)
    })
}

const forgotPasswordComplete = (user, verificationCode, newPassword, cb) => {
    const cognitoUser = new CognitoUser({
        Username: user,
        Pool: userPool
    })

    cognitoUser.confirmPassword(
        verificationCode,
        newPassword,
        {
            onSuccess: () => cb(),
            onFailure: err => cb(err)
        }
    )
}

const getUserInfo = async () => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/users`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export {
    login,
    newPassword,
    getAccessToken,
    forgotPasswordInitiate,
    forgotPasswordComplete,
    getUserInfo
}
