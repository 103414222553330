import config from './config'
import { getAccessToken } from './user'

const getBatteryData = async (siteId, timeframe) => {
    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}?timeframe=${timeframe}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getBatteryDataReport = async (siteId, startDate, endDate) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/report?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getFrequencyData = async (siteId, timeframe) => {
    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/frequency?timeframe=${timeframe}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getFrequencyDataReport = async (siteId, startDate, endDate) => {
    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/frequency/report?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getEnergyData = async (siteId, timeframe) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/energy?timeframe=${timeframe}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getEnergyDataReport = async (siteId, startDate, endDate) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/energy/report?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getEnergyPricesReport = async (siteId, startDate, endDate) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/energy/prices/report?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getImbalance = async (siteId, timeframe) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/imbalance?timeframe=${timeframe}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getImbalancePrices = async (siteId, timeframe) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/imbalance/prices?timeframe=${timeframe}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getImbalanceReport = async (siteId, startDate, endDate) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/imbalance/report?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getImbalancePricesReport = async (siteId, startDate, endDate) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/imbalance/prices/report?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getRevenue = async (siteId, timeframe) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/revenue?timeframe=${timeframe}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getRevenueReport = async (siteId, startDate, endDate) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/revenue/report?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getSmappee = async (siteId, timeframe) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/smappee?timeframe=${timeframe}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getFCR = async (siteId, date) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/fcr?date=${date}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getFCRReport = async (siteId, startDate, endDate) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/fcr/report?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getThroughputReport = async (siteId, startDate, endDate) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/throughput/report?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getStatus = async siteId => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/status`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getAllocations = async siteId => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/allocations`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getAllocationMessages = async (siteId, document_id) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/allocation/messages?document_id=${document_id}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getDexterData = async (siteId, data, timeframe) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/dexter/${data}?timeframe=${timeframe}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getDexterDataReport = async (siteId, data, startDate, endDate) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/dexter/report/${data}?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const sendUpdatedSchedule = async (siteId, data) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/allocations`, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(data) })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const sendEmergencyEmail = async (siteId, data) => {

    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/allocations/emergency`, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(data) })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getAssetSchedule = async (siteId, date) => {
    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/assetSchedule?date=${date}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const getAssetScheduleUK = async (siteId, date) => {
    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/assetScheduleUK?date=${date}`, { headers: { 'Authorization': 'Bearer ' + token } })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const updateAssetScheduleUK = async (siteId, data) => {
    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/updateAssetScheduleUK`, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(data) })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

const updateAssetSchedule = async (siteId, data) => {
    try {
        const token = await getAccessToken()
        const response = await fetch(`${config.API_URL}/api/battery/${siteId}/updateAssetSchedule`, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(data) })
        const json = await response.json()
        return Promise.resolve(json)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export {
    getBatteryData,
    getBatteryDataReport,
    getFrequencyData,
    getFrequencyDataReport,
    getEnergyData,
    getEnergyDataReport,
    getEnergyPricesReport,
    getImbalance,
    getImbalancePrices,
    getImbalanceReport,
    getImbalancePricesReport,
    getRevenue,
    getRevenueReport,
    getSmappee,
    getFCR,
    getFCRReport,
    getThroughputReport,
    getStatus,
    getAllocations,
    getAllocationMessages,
    getDexterData,
    getDexterDataReport,
    sendUpdatedSchedule,
    sendEmergencyEmail,
    getAssetSchedule,
    updateAssetSchedule,
    getAssetScheduleUK,
    updateAssetScheduleUK
}