import * as React from 'react'


export default class ExpandableProperty extends React.Component {
    state = {
        isOpen: !!this.props.expanded
    }

    render() {
        return (
            <React.Fragment>
                <div onClick={() => this.setState({ isOpen: !this.state.isOpen })} className="config-expandable">
                    {this.state.isOpen ? <i className="fas fa-angle-down"></i> : <i className="fas fa-angle-right"></i>} {this.props.title}
                </div>
                {this.state.isOpen ? this.props.children : null}
                {React.Children.count(this.props.children) === 0 && this.state.isOpen ? 'Empty list' : null}
            </React.Fragment>
        )
    }
}
