import checkStomp from './checkStomp'

const stompUnsubscribe = sub => {
    if (sub)
        sub.unsubscribe()
}

const stompSubscribe = (topic, cb) => {

    if(checkStomp(stompSubscribe, topic, cb)) {
        let sub = window.stomp.subscribe(`/topic/${topic}`, message => {
            if (message.body)
                message.json = JSON.parse(message.body)
            
            cb(message.json, sub)
        })
    }
}

export {
    stompUnsubscribe,
    stompSubscribe
}