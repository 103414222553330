import React, { Component } from 'react'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'

import Loader from '../Loader'

class Throughput extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title"><span>Energy Discharged ({this.props.unit}h)</span>
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_throughput" onChange={e => this.props.toggleDynamicYAxis(e, 'throughput')} />
                                <label className="form-check-label" htmlFor="yaxis_throughput">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    <div className="row">
                        <div className="col-sm col-md-4 offset-md-2">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-title text-center">Energy Discharged ({this.props.unit}h)</h5>
                                    <div className="card-text">
                                        {
                                            this.props.loadingThroughputData ?
                                                <Loader>Loading...</Loader>
                                                :
                                                <h4 className="text-center font-weight-bold">{this.props.throughputUpperBound ? `${this.props.throughputUpperBound} ${this.props.unit}h` : `N/A`}</h4>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm col-md-4">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-title text-center">Discharge Cycles</h5>
                                    <div className="card-text">
                                        {
                                            this.props.loadingThroughputData ?
                                                <Loader>Loading...</Loader>
                                                :
                                                <h4 className="text-center font-weight-bold">{this.props.dischargeCycles ? this.props.dischargeCycles : `N/A`}</h4>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.loadingThroughputData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={this.props.throughputData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={tick => this.props.tickFormatterX(tick, 'YYYY-MM-DD')} />
                                    <YAxis type="number" domain={this.props.dynamicYAxis.throughput ? ['auto', 'auto'] : [dataMin => (Math.floor(dataMin / 100) * 100), dataMax => (Math.ceil(dataMax / 100) * 100)]} tickFormatter={this.props.tickFormatterY} />
                                    <Tooltip />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    {
                                        data.throughput === false ? null : <Line type="stepAfter" dataKey={`throughput${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.throughput ? ' ' : ''}`} name="Energy Discharged" stroke="#3f51b5" unit={this.props.unit + 'h'} dot={false} isAnimationActive={false} key={0} />
                                    }
                                </LineChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default Throughput