import React, { Component } from 'react'
import { ResponsiveContainer, Line, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent'

import Loader from '../Loader'

const CustomTooltip = props => {


    if (props.active && props.payload && props.payload[0])
        props.payload.push({
            color: "#000000",
            dataKey: "SOC",
            name: "State of Charge",
            unit: '%',
            value: props.payload[0].payload.SOC || 'N/A ',
            payload: props.payload[0].payload
        })

    return <DefaultTooltipContent {...props} />
}

class AvailableDischargeEnergy extends Component {
    render() {
        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">Available Discharge Energy of the Battery Plant
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_adenergy" onChange={e => this.props.toggleDynamicYAxis(e, 'AvailableDischargeEnergy')} />
                                <label className="form-check-label" htmlFor="yaxis_adenergy">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingBatteryData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={this.props.batteryData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} minTickGap={50} />
                                    <YAxis type="number" tickFormatter={this.props.tickFormatterY} domain={[this.props.dynamicYAxis.AvailableDischargeEnergy ? 'auto' : 0, this.props.dynamicYAxis.AvailableDischargeEnergy ? 'auto' : this.props.unit === 'kW' ? this.props.assetSpecs.nominal_energy : this.props.assetSpecs.nominal_energy / 1000]} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    <Line type="linear" strokeDasharray="5 5" dataKey={`energyNew${this.props.unit === 'kW' ? '' : 'MWh'}${this.props.hidden.energyNew ? ' ' : ''}`} name="Initial Useable Energy Capacity" stroke="#000000" unit={this.props.unit + 'h'} dot={dot => this.props.setDot && this.props.setDot(dot, `energyNew${this.props.unit === 'kW' ? '' : 'MWh'}`, this.props.unit + 'h', 3, -10, 10, 'chart-battery', this.props.batteryData)} />
                                    <Line type="linear" dataKey={`totalEnergyAvailableDischarge${this.props.unit === 'kW' ? '' : 'MWh'}${this.props.hidden.totalEnergyAvailableDischarge ? ' ' : ''}`} name="Actual Useable Energy Capacity" stroke="#000000" unit={this.props.unit + 'h'} dot={false} />
                                    <Line type="linear" dataKey={`actualEnergyAvailableDischarge${this.props.unit === 'kW' ? '' : 'MWh'}${this.props.hidden.actualEnergyAvailableDischarge ? ' ' : ''}`} name="Actual Dischargeable Energy" stroke="#FF2D00" unit={this.props.unit + 'h'} dot={false} />
                                    <Line type="linear" dataKey={`actualEnergyAvailableDischargeNominalPower${this.props.unit === 'kW' ? '' : 'MWh'}${this.props.hidden.actualEnergyAvailableDischargeNominalPower ? ' ' : ''}`} name="Actual Dischargeable Energy at Nominal Power" stroke="#0049FF" unit={this.props.unit + 'h'} dot={false} />
                                </LineChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default AvailableDischargeEnergy