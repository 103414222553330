import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'

import Loader from './Loader'
import AllocationMessages from './AllocationMessages'

import 'react-datepicker/dist/react-datepicker.css'

import { getAllocations, sendUpdatedSchedule, sendEmergencyEmail } from '../api/battery'

const moment = extendMoment(Moment)
const BUFFER_PERIOD = 2 * 15 // in minutes - Needs to be minumum 2 quarther-hours due to Tennet FCR nomination rules!

moment.tz.setDefault('Europe/Amsterdam')


class Allocation extends Component {
    constructor(props) {
        super(props)

        let date = moment().format('YYYY-MM-DD')

        this.state = {
            allocations: undefined,
            date,
            capacity: this.props.site.battery.assetSpecs.nominal_energy / 1000, // convert to MW
            time: moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss'),
            errors: [],
            emailSubject: 'Yuso FCR allocation message for AES Zeeland',
            emailMessage: `Dear all,

Attached the XML message that we have sent you.

Kind regards,`
        }

        this.generatePeriods = this.generatePeriods.bind(this)
        this.getAllocations = this.getAllocations.bind(this)
        this.sendUpdatedSchedule = this.sendUpdatedSchedule.bind(this)
        this.checkTimeframes = this.checkTimeframes.bind(this)
        this.updateAllocation = this.updateAllocation.bind(this)
        this.addTimeframe = this.addTimeframe.bind(this)
        this.removeTimeframe = this.removeTimeframe.bind(this)
        this.disablePeriod = this.disablePeriod.bind(this)
        this.warningPeriod = this.warningPeriod.bind(this)
        this.dateChanged = this.dateChanged.bind(this)
        this.disabledDates = this.disabledDates.bind(this)
        this.renderDayContents = this.renderDayContents.bind(this)
        this.openInfo = this.openInfo.bind(this)
        this.pastAllocationDate = this.pastAllocationDate.bind(this)
        this.sendDisabled = this.sendDisabled.bind(this)
        this.fieldChange = this.fieldChange.bind(this)
        this.disableField = this.disableField.bind(this)
        this.sendEmergencyEmail = this.sendEmergencyEmail.bind(this)

        // create web worker for correct countdown (executes in a separate thread)
        const blob = new window.Blob([`setInterval(() => postMessage(''), 1000)`])
        this.objectURL = window.URL.createObjectURL(blob)
        this.worker = new window.Worker(this.objectURL)
    }

    componentDidMount() {
        this.generatePeriods()
        this.getAllocations()

        this.worker.onmessage = () => {
            this.setState({
                time: moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss'),
                nextTimeframe: this.state.periods && this.state.periods.find(x => x !== this.state.periods[0] && moment.tz(x, 'YYYY-MM-DD HH:mm', true, 'UTC') > moment().add(BUFFER_PERIOD, 'minutes').tz('UTC'))
            })
        }
    }

    componentDidUpdate() {
        // make the date picker inpt field readonly
        let datePickers = document.querySelectorAll('.react-datepicker-wrapper input')
        if (datePickers.length)
            datePickers.forEach(x => x.setAttribute('readonly', true))
    }

    componentWillUnmount() {
        this.worker.terminate()
        window.URL.revokeObjectURL(this.objectURL)
    }

    generatePeriods() {
        let a = moment(this.state.date, 'YYYY-MM-DD').startOf('day')
        let b = moment(this.state.date, 'YYYY-MM-DD').add(1, 'day').startOf('day')
        let h = b.diff(a, 'hours')

        let periods = [] // store the number of quarter-hours for today

        Array(h).fill(undefined).forEach((val, idx) => {
            periods.push(moment(this.state.date + ' ' + idx, 'YYYY-MM-DD H').tz('UTC').format('YYYY-MM-DD HH:mm'))
            periods.push(moment(this.state.date + ' ' + idx, 'YYYY-MM-DD H').add(15, 'minutes').tz('UTC').format('YYYY-MM-DD HH:mm'))
            periods.push(moment(this.state.date + ' ' + idx, 'YYYY-MM-DD H').add(30, 'minutes').tz('UTC').format('YYYY-MM-DD HH:mm'))
            periods.push(moment(this.state.date + ' ' + idx, 'YYYY-MM-DD H').add(45, 'minutes').tz('UTC').format('YYYY-MM-DD HH:mm'))
        })

        periods.push(moment(this.state.date, 'YYYY-MM-DD').add(1, 'day').startOf('day').tz('UTC').format('YYYY-MM-DD HH:mm'))

        this.setState({
            periods,
            dayStart: periods[0],
            dayEnd: periods[periods.length - 1],
            nextTimeframe: periods.find(x => x !== periods[0] && moment.tz(x, 'YYYY-MM-DD HH:mm', true, 'UTC') > moment().add(BUFFER_PERIOD, 'minutes').tz('UTC'))
        })

        // get the allocations for selected day
        this.getAllocations()
    }

    async getAllocations() {

        try {
            let allocations = await getAllocations(this.props.site.siteId)

            // group same date ranges
            let values = []

            allocations.filter(x => x.date === this.state.date).forEach(allocation => {

                let row = values.find(x => x.start_time_utc === allocation.start_time_utc && x.end_time_utc === allocation.end_time_utc)

                if (row) {
                    if (allocation.type === 'AVAILABLE') {
                        row.capacity = allocation.capacity
                    }
                    else if (allocation.type === 'UNAVAILABLE') {
                        row.unavailable_capacity = allocation.capacity
                        row.substitute_resource_provider = allocation.substitute_resource_provider
                    }
                }
                else {

                    values.push({
                        document_id: allocation.document_id,
                        timerseries_id: allocation.timerseries_id,
                        version: allocation.version,
                        date: allocation.date,
                        start_time_utc: allocation.start_time_utc,
                        end_time_utc: allocation.end_time_utc,
                        capacity: allocation.type === 'AVAILABLE' ? allocation.capacity : '',
                        unavailable_capacity: allocation.type === 'UNAVAILABLE' ? allocation.capacity : '',
                        type: allocation.type,
                        substitute_resource_provider: allocation.substitute_resource_provider
                    })
                }
            })

            // update allocations with adjusted format
            allocations = [...allocations.filter(x => x.date !== this.state.date), ...values]

            this.checkTimeframes(allocations)

            this.setState({
                allocations
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async sendUpdatedSchedule() {

        let errors = this.checkTimeframes(this.state.allocations)

        if (errors.length) return

        let allocation = this.state.allocations.filter(x => x.date === this.state.date)

        this.setState({
            sending: true,
            schedule: undefined
        })

        try {
            let schedule = await sendUpdatedSchedule(this.props.site.siteId, allocation)

            if (schedule.error) {
                if (schedule.error === 'EMPTY_ALLOCATION') {
                    this.setState({
                        errors: ['The allocation is empty please specify the capacity']
                    })
                }
                else {
                    this.setState({
                        errors: ['Error occured while sending the new schedule. Please try again or contact support@yuso.be']
                    })
                }
            }
            else {
                let allocations = [...this.state.allocations]

                if (schedule.bid_id) {
                    allocations.filter(x => x.date === this.state.date).forEach(x => {
                        x.document_id = schedule.bid_id
                        delete x.future
                    })
                }

                this.setState({
                    schedule,
                    allocations
                })
            }
        }
        catch (err) {
            console.error(err)
        }

        this.setState({
            sending: undefined
        })
    }

    checkTimeframes(allocations) {

        let day = allocations.filter(x => x.date === this.state.date)

        // make sure day start and end time are correct
        day[0].start_time_utc = this.state.dayStart
        day[day.length - 1].end_time_utc = this.state.dayEnd

        let errors = []

        // make ranges
        for (let i = 0; i < day.length; i++) {
            let start = moment.tz(day[i].start_time_utc, 'YYYY-MM-DD HH:mm', 'UTC')
            let end = moment.tz(day[i].end_time_utc, 'YYYY-MM-DD HH:mm', 'UTC')

            day[i].start_moment = start
            day[i].end_moment = end
            day[i].range = moment.range(start, end)

            // reset errors
            day[i].error = undefined
        }

        // check for minimum time range and if end time before start time
        for (let i = 0; i < day.length; i++) {
            let qh = (Array.from(day[i].range.by('minutes')).length - 1) / 15

            if (qh === 0) {
                day[i].error = true
                errors.push(`Timeframe ${day[i].start_time_utc} – ${day[i].end_time_utc} must be minumum 15 minutes (quarter-hour).`)
            }

            if (day[i].start_moment > day[i].end_moment) {
                day[i].error = true
                errors.push(`Start time ${day[i].start_time_utc} is greater than end time ${day[i].end_time_utc}.`)
            }
        }

        // check for continuous date ranges
        for (let i = 0; i < day.length - 1; i++) {
            if (!day[i].range.adjacent(day[i + 1].range)) {
                day[i].error = true
                day[i + 1].error = true
                errors.push(`Timeframes ${day[i].start_time_utc} – ${day[i].end_time_utc} and ${day[i + 1].start_time_utc} – ${day[i + 1].end_time_utc} are not continuous.`)
            }
        }

        // NOT USED: timeframes in the past are disabled
        // check if timeframe is in the past
        // const past = moment.range(moment().startOf('day').tz('UTC'), moment().add(BUFFER_PERIOD, 'minutes').tz('UTC'))
        // for (let i = 0; i < day.length; i++) {
        //     if (past.contains(day[i].range)) {
        //         day[i].error = true
        //         errors.push(`Timeframe ${day[i].start_time_utc} – ${day[i].end_time_utc} is in the past.`)
        //     }
        // }

        this.setState({
            errors
        })

        return errors
    }

    updateAllocation(id, e) {
        if (e.target.name === 'capacity' || e.target.name === 'unavailable_capacity') {
            if (parseFloat(e.target.value) < parseFloat(e.target.min)) {
                e.target.value = e.target.min
            }

            if (parseFloat(e.target.value) > parseFloat(e.target.max)) {
                e.target.value = e.target.max
            }

            let val = parseFloat(e.target.value)

            e.target.value = !isNaN(val) ? val : ''
        }

        let allocations = [...this.state.allocations]

        let day = allocations.filter(x => x.date === this.state.date)
        let row = day.find(x => x.timerseries_id === id)

        let index = day.indexOf(row)

        let prevRow = day[index - 1]
        let nextRow = day[index + 1]

        if (row) {

            row[e.target.name] = e.target.value

            // if (e.target.name === 'capacity') {
            //     row.capacity = parseFloat(e.target.value)
            //     row.unavailable_capacity = this.state.capacity - (row.capacity || 0)
            // }
            // else if (e.target.name === 'unavailable_capacity') {
            //     row.unavailable_capacity = parseFloat(e.target.value)
            //     row.capacity = this.state.capacity - (row.unavailable_capacity || 0)
            // }
        }

        if (prevRow && e.target.name === 'start_time_utc') {
            prevRow.end_time_utc = e.target.value
        }

        if (nextRow && e.target.name === 'end_time_utc') {
            nextRow.start_time_utc = e.target.value
        }

        this.checkTimeframes(allocations)

        this.setState({
            allocations,
            schedule: undefined
        })
    }

    addTimeframe() {
        let allocations = [...this.state.allocations]

        let day = allocations.filter(x => x.date === this.state.date)

        let last = day[day.length - 1]

        last.end_time_utc = this.state.periods.find(x => moment.tz(x, 'YYYY-MM-DD HH:mm', 'UTC') > moment().add(BUFFER_PERIOD, 'minutes').tz('UTC') && moment.tz(x, 'YYYY-MM-DD HH:mm', 'UTC') > moment.tz(last.start_time_utc, 'YYYY-MM-DD HH:mm', 'UTC')) || last.end_time_utc

        if (last.end_time_utc === this.state.dayEnd) {

            this.setState({
                errors: ['Cannot add new timeframe. Please adjust/fix your current timeframes before adding new one.']
            })

            return
        }

        allocations.push({
            document_id: last.document_id,
            timerseries_id: Math.random(),
            version: last.version,
            date: this.state.date,
            start_time_utc: last.end_time_utc,
            end_time_utc: this.state.dayEnd,
            capacity: 10,
            type: 'AVAILABLE',
            substitute_resource_provider: null,
            added: true
        })

        this.checkTimeframes(allocations)

        this.setState({
            allocations,
            schedule: undefined
        })
    }

    removeTimeframe(id) {

        let allocations = [...this.state.allocations]

        let row = allocations.find(x => x.timerseries_id === id)
        let index = allocations.indexOf(row)

        allocations.splice(index, 1)

        this.checkTimeframes(allocations)

        this.setState({
            allocations,
            schedule: undefined
        })
    }

    disablePeriod(period) {

        let p = moment.tz(period, 'YYYY-MM-DD HH:mm', 'UTC')
        let startOrEndDay = (period === this.state.dayStart || period === this.state.dayEnd)

        return !(p > moment().add(BUFFER_PERIOD, 'minutes').tz('UTC')) || startOrEndDay
    }

    warningPeriod(allocation, period, field) {

        let p = moment.tz(period, 'YYYY-MM-DD HH:mm', 'UTC')
        if (field === 'start') {
            return p > moment().tz('UTC') && p >= moment.tz(allocation.end_time_utc, 'YYYY-MM-DD HH:mm', 'UTC') && allocation.end_time_utc !== this.state.dayEnd ? 'text-danger' : ''
        }

        if (field === 'end') {
            return p > moment().tz('UTC') && p <= moment.tz(allocation.start_time_utc, 'YYYY-MM-DD HH:mm', 'UTC') && allocation.start_time_utc !== this.state.dayStart ? 'text-danger' : ''
        }
    }

    dateChanged(date) {
        this.setState({
            allocations: this.state.allocations.filter(x => !x.added),
            date: moment(date).format('YYYY-MM-DD'),
            errors: []
        }, this.generatePeriods) // update the time periods for the newly selected date
    }

    disabledDates(date) {
        // get last allocation date
        let allocations = [...this.state.allocations]

        allocations = allocations.map(x => parseInt(x.date.replace(/-/g, ''))).sort((a, b) => {
            return a - b
        })

        let last = allocations[allocations.length - 1]

        return moment(date) >= moment().startOf('day').subtract(90, 'days') && moment(date) <= moment(last, 'YYYYMMDD')
    }

    renderDayContents(day, date) {
        // get last allocation date
        let allocations = [...this.state.allocations]

        // get just the real allocations, not the future one
        allocations = allocations.filter(x => !x.future).map(x => parseInt(x.date.replace(/-/g, ''))).sort((a, b) => {
            return a - b
        })

        let last = allocations[allocations.length - 1]
        let size = allocations.filter(x => moment(x, 'YYYYMMDD').startOf('day') > moment().startOf('day')).length

        const tooltipText = `No auction results received for ${moment(date).format('YYYY-MM-DD')}, but you can manually allocate the capacity.`;

        return moment(date) >= moment().startOf('day').subtract(90, 'days') && moment(date) > moment(last, 'YYYYMMDD') && moment(date) <= moment(last, 'YYYYMMDD').add(7 - size, 'days') ?
            <div className="bg-light text-danger" style={{ borderRadius: '0.3rem' }} title={tooltipText}>{moment(date).format('D')}</div>
            :
            <div>{moment(date).format('D')}</div>
    }

    openInfo(open) {
        if (open && moment(this.state.date, 'YYYY-MM-DD') >= moment().startOf('day'))
            window.$('#allocation-info').modal('show')
    }

    pastAllocationDate() {
        return moment(this.state.date, 'YYYY-MM-DD') < moment().startOf('day')
    }

    sendDisabled() {
        return this.pastAllocationDate() || this.state.errors.length || this.state.sending
    }

    fieldChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    disableField(period, type) {
        // return index === 0 && moment(this.state.date, 'YYYY-MM-DD') <= moment().startOf('day')
        let p = moment.tz(period, 'YYYY-MM-DD HH:mm', 'UTC')
        return (type === 'time' && period === this.state.dayStart) || (type === 'time' && period === this.state.dayEnd) || (p <= moment())
    }

    async sendEmergencyEmail() {
        this.setState({ sendingEmergency: true })

        try {
            await sendEmergencyEmail(this.props.site.siteId, {
                date: this.state.date,
                emailCC: this.state.emailCC,
                emailSubject: this.state.emailSubject,
                emailMessage: this.state.emailMessage
            })
        }
        catch (err) {
            console.error(err)
        }

        this.setState({
            sendingEmergency: undefined
        })
    }

    render() {

        let day

        if (this.state.allocations) {
            day = this.state.allocations.filter(x => x.date === this.state.date)
        }

        return (
            <div className="row">
                <div className="col-sm">
                    {
                        !this.state.allocations ?
                            <Loader text="Loading allocations data..." />
                            :
                            <div>
                                <div className="row">
                                    <div className="col-sm">
                                        <h3 className="card-title text-center mb-4">
                                            Schedule for &nbsp;
                                            <div className="d-inline-block position-relative">
                                                <DatePicker
                                                    className="font-weight-bold border-0 p-0 m-0 h3 text-body cursor pointer schedule-date-picker"
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={moment(this.state.date, 'YYYY-MM-DD').toDate()}
                                                    onChange={this.dateChanged}
                                                    renderDayContents={this.renderDayContents}
                                                    filterDate={this.disabledDates}
                                                />
                                                <sup className="position-absolute" style={{ top: '-7px', right: '-7px' }}>
                                                    <small><i className="fas fa-info-circle text-info cursor help" title="Click on the date to change it"></i></small>
                                                </sup>
                                            </div>
                                        </h3>
                                        {
                                            day.length ?
                                                <div>
                                                    <table className="table-allocation-time">
                                                        <tbody>
                                                            <tr>
                                                                <td>Current <strong>UTC</strong> time:*</td>
                                                                <td><strong className="font-weight-bold">{this.state.time}</strong></td>
                                                                <td> &ndash; Next available timeframe in <strong>UTC</strong> time:</td>
                                                                <td>
                                                                    {
                                                                        !this.pastAllocationDate() ?
                                                                            <strong className="font-weight-bold">{this.state.nextTimeframe}</strong>
                                                                            : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Current <strong>Amsterdam</strong> time:</td>
                                                                <td><strong className="font-weight-bold">{moment.tz(this.state.time, 'YYYY-MM-DD HH:mm:ss', true, 'UTC').tz('Europe/Amsterdam').format('YYYY-MM-DD HH:mm:ss')}</strong></td>
                                                                <td> &ndash; Next available timeframe in <strong>Amsterdam</strong> time:</td>
                                                                <td>
                                                                    {
                                                                        !this.pastAllocationDate() ?
                                                                            <strong className="font-weight-bold">{moment.tz(this.state.nextTimeframe, 'YYYY-MM-DD HH:mm', true, 'UTC').tz('Europe/Amsterdam').format('YYYY-MM-DD HH:mm')}</strong>
                                                                            : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br /><br />
                                                    <table className="table table-bordered table-sm table-allocation">
                                                        <thead className="bg-light">
                                                            <tr>
                                                                <th>Start time (UTC)</th>
                                                                <th>End time (UTC)</th>
                                                                <th className="text-right">Available capacity (MW)</th>
                                                                <th className="text-right">Unavailable capacity (MW)</th>
                                                                <th className="text-right pr-5">Substitute provider</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                day.map((x, index, array) => {
                                                                    return (
                                                                        <tr key={x.timerseries_id} className={x.error ? 'allocation-error' : ''}>
                                                                            <td>
                                                                                {
                                                                                    this.disableField(x.start_time_utc, 'time') ?
                                                                                        <input type="text" className="form-control cursor default" name="start_time_utc" value={x.start_time_utc || ''} readOnly={true} onClick={() => this.openInfo(true)} />
                                                                                        :
                                                                                        <select className="form-control" name="start_time_utc" value={x.start_time_utc || ''} onChange={e => this.updateAllocation(x.timerseries_id, e)}>
                                                                                            {
                                                                                                this.state.periods.map(period => <option value={period} key={period} disabled={this.disablePeriod(period)} className={this.warningPeriod(x, period, 'start')}>{period}</option>)
                                                                                            }
                                                                                        </select>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    this.disableField(x.end_time_utc, 'time') ?
                                                                                        <input type="text" className="form-control cursor default" name="end_time_utc" value={x.end_time_utc || ''} readOnly={true} onClick={() => this.openInfo(true)} />
                                                                                        :
                                                                                        <select className="form-control" name="end_time_utc" value={x.end_time_utc || ''} onChange={e => this.updateAllocation(x.timerseries_id, e)}>
                                                                                            {
                                                                                                this.state.periods.map(period => <option value={period} key={period} disabled={this.disablePeriod(period)} className={this.warningPeriod(x, period, 'end')}>{period}</option>)
                                                                                            }
                                                                                        </select>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <input type="number" className={'form-control text-right ' + (this.disableField(x.end_time_utc) === 0 ? 'cursor default' : '')} name="capacity" value={!isNaN(x.capacity) ? x.capacity : ''} readOnly={this.disableField(x.end_time_utc, 'capacity')} onChange={e => this.updateAllocation(x.timerseries_id, e)} min={0} max={this.state.capacity} onClick={() => this.openInfo(this.disableField(x.end_time_utc, 'capacity'))} />
                                                                            </td>
                                                                            <td>
                                                                                <input type="number" className={'form-control text-right ' + (this.disableField(x.end_time_utc) ? 'cursor default' : '')} name="unavailable_capacity" value={!isNaN(x.unavailable_capacity) ? x.unavailable_capacity : ''} readOnly={this.disableField(x.end_time_utc)} onChange={e => this.updateAllocation(x.timerseries_id, e)} min={0} max={this.state.capacity} onClick={() => this.openInfo(this.disableField(x.end_time_utc, 'capacity'))} />
                                                                            </td>
                                                                            <td>
                                                                                <div className="position-relative">
                                                                                    <input type="text" className={'form-control text-right pr-5 ' + (this.disableField(x.end_time_utc) ? 'cursor default' : '')} name="substitute_resource_provider" value={x.substitute_resource_provider || ''} maxLength={50} readOnly={this.disableField(x.end_time_utc, 'capacity')} onChange={e => this.updateAllocation(x.timerseries_id, e)} onClick={() => this.openInfo(this.disableField(x.end_time_utc, 'capacity'))} />
                                                                                    {
                                                                                        !this.disableField(x.start_time_utc, 'time') && !this.disableField(x.end_time_utc, 'time') ?
                                                                                            <div className="d-flex align-items-center position-absolute h-100 pr-2" style={{ fontSize: '1.5rem', top: 0, right: 0 }}>
                                                                                                <i className="fas fa-times text-danger cursor pointer" title="Remove timeframe" onClick={() => this.removeTimeframe(x.timerseries_id)}></i>
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <small className="form-text">
                                                        <em>* You can only specify UTC timeframes with minmum 2 quarter-hours in future</em>
                                                    </small>
                                                    <br />
                                                    {
                                                        this.state.errors.length ?
                                                            <div className="alert alert-danger">
                                                                <h5 className="mb-2">
                                                                    <i className="fas fa-exclamation-triangle mr-2"></i> <strong>Errors occured:</strong>
                                                                </h5>
                                                                <ul className="mb-0">
                                                                    {
                                                                        this.state.errors.map(error => <li key={Math.random()}>{error}</li>)
                                                                    }
                                                                </ul>
                                                                { }
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.pastAllocationDate() ?
                                                            <div className="alert alert-info">
                                                                <i className="fas fa-info-circle mr-2"></i> You can't send new allocation schedule in the past.
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.allocations.find(x => x.date === this.state.date).future ?
                                                            <div className="alert alert-info">
                                                                <i className="fas fa-info-circle mr-2"></i> You selected a date for which we haven't received auction from AES. If you allocate capacity new auction and allocation will be saved in the database.
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.schedule && this.state.schedule.status === 'OK' ?
                                                            <div className="alert alert-success d-flex align-items-center">
                                                                <i className="fas fa-check mr-2"></i> The new allocation schedule was successfully queued for sending to Tennet. Usually it takes less than 5 minutes to get response back.
                                                            </div>
                                                            : null
                                                    }
                                                    <button className="btn btn-warning mr-3" type="button" onClick={this.addTimeframe} disabled={this.pastAllocationDate()}>
                                                        <i className="fas fa-plus mr-2"></i> Add new timeframe
                                                    </button>
                                                    <button className="btn btn-primary" type="button" onClick={this.sendUpdatedSchedule} disabled={this.sendDisabled()}>
                                                        <i className={'fas mr-2 ' + (this.state.sending ? 'fa-spinner fa-spin' : 'fa-paper-plane')}></i> Send updated schedule
                                                    </button>

                                                    <AllocationMessages
                                                        siteId={this.props.siteId}
                                                        site={this.props.site}
                                                        unit={this.props.unit}
                                                        date={this.state.date}
                                                        documentId={day[0].document_id}
                                                    />

                                                    {
                                                        !this.pastAllocationDate() ?
                                                            <div className="border border-danger bg-danger-light mt-5 p-3">
                                                                <h5 className="text-center text-danger mb-4">
                                                                    <i className="fas fa-exclamation-triangle mr-2"></i> Emergency procedure
                                                                </h5>
                                                                <p>
                                                                    If for some reason allocations can't be sent with the action above, or if we didn't received response in the expected time, or some other issues arise when sending the allocation, the first thing to do is, call the Tennet day ahead planning desk at <a className="text-body font-weight-bold" href="tel:+31263731730" target="_blank" rel="noopener noreferrer">+31263731730</a> or operational preparation at <a className="text-body font-weight-bold" href="tel:+31263731241" target="_blank" rel="noopener noreferrer">+31263731241</a> and coordinate with them.
                                                                </p>
                                                                <div>
                                                                    <h5>Send manualy email with the XML nomination to Tennet:</h5>
                                                                    <table className="table table-sm table-borderless m-0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th style={{ width: '100px' }}>To:</th>
                                                                                <td>
                                                                                    <input className="form-control" value="ProcesspecialistenSON-SYBalanshandhaving@tennet.eu, it@yuso.be" readOnly />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>CC:</th>
                                                                                <td>
                                                                                    <input className="form-control" name="emailCC" value={this.state.emailCC || ''} onChange={this.fieldChange} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Subject:</th>
                                                                                <td><input className="form-control" name="emailSubject" value={this.state.emailSubject} onChange={this.fieldChange} /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Message:</th>
                                                                                <td>
                                                                                    <textarea className="form-control mb-2" style={{ height: '135px' }} name="emailMessage" value={this.state.emailMessage} onChange={this.fieldChange} />
                                                                                    <i className="fas fa-paperclip mr-2"></i> The XML allocation will be attached to the message.
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="2" className="clearfix">
                                                                                    <button type="button" className="btn btn-danger float-right" onClick={this.sendEmergencyEmail} disabled={this.state.sendingEmergency}>{this.state.sendingEmergency ? 'Sending...' : 'Send email'}</button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                :
                                                <h3 className="text-center">
                                                    {
                                                        moment(this.state.date, 'YYYY-MM-DD') < moment() ?
                                                            <span>Allocations schedule for {this.state.date} was handled with the legacy method</span>
                                                            :
                                                            <span>No allocation schedule for {this.state.date}</span>
                                                    }
                                                </h3>
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>

                <div className="modal fade" id="allocation-info" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="fas fa-exclamation-triangle text-warning mr-2"></i> Alert</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    This field <strong>cannot be changed</strong>. In order to make adjustments please <strong>add additional timeframe</strong> with the button below.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Allocation