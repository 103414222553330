import React, { Component } from 'react'
import moment from 'moment-timezone'

import Loader from '../components/Loader'
import Allocation from '../components/Allocation'
import AssetSchedule from '../components/AssetSchedule'
import AssetScheduleUk from '../components/AssetScheduleUk'
import SteerConfiguration from '../components/SteerConfiguration'

moment.tz.setDefault('Europe/Brussels')


class Dispatch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            steering: false
        }

    }

    componentDidMount() {
        console.log(this.props)

        console.log(this.props.site.battery.country)

        if (!this.props.site || (this.props.site && this.props.site.siteId !== this.props.siteId))
            this.props.selectSite(this.props.siteId)
        else this.setState({
            steering: this.props.user.info.steering === 'enabled' && (this.props.site.battery.flexPortalConfig.steering && this.props.site.battery.flexPortalConfig.steering.enabled === true)
        })
    }

    render() {

        if (!this.props.site)
            return <Loader className="container align-items-center" noText={true} />

        return (
            < div className="row" >
                <div className="col-sm">
                    {
                        this.props.site.serviceType === 'utility'
                            ? this.props.siteId === 'aes'
                                ? <Allocation
                                    siteId={this.props.siteId}
                                    site={this.props.site}
                                    unit={this.props.unit}
                                    user={this.props.user}
                                />
                                : this.props.site.battery.country === 'GB' ?
                                    <AssetScheduleUk
                                        siteId={this.props.siteId}
                                        site={this.props.site}
                                        user={this.props.user}
                                    />
                                    : <AssetSchedule
                                        siteId={this.props.siteId}
                                        site={this.props.site}
                                        user={this.props.user}
                                    />
                            : this.state.steering
                                ? <SteerConfiguration
                                    site={this.props.site}
                                    user={this.props.user}
                                    updateSites={this.props.updateSites}
                                />
                                : <p>Nothing to see here :)</p>
                    }
                    <div className="p-5"></div>
                </div>
            </div >
        )
    }
}

export default Dispatch