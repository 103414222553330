import React, { Component } from 'react'
import moment from 'moment-timezone'

import 'ace-builds'
import 'ace-builds/webpack-resolver'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-xml'
import 'ace-builds/src-noconflict/theme-tomorrow'

import Loader from './Loader'

import { getAllocationMessages } from '../api/battery'

moment.tz.setDefault('Europe/Brussels')


class AllocationMessages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            messages: undefined,
            xml: '',
            title: '',
            spin: '',
            date: this.props.date
        }

        this.getAllocationMessages = this.getAllocationMessages.bind(this)
        this.previewXML = this.previewXML.bind(this)

        // create web worker for correct countdown (executes in a separate thread)
        const blob = new window.Blob([`setInterval(() => postMessage(''), 3 * 60 * 1000);`]) // 3 minutes
        this.objectURL = window.URL.createObjectURL(blob)
        this.worker = new window.Worker(this.objectURL)
    }

    componentDidMount() {
        this.getAllocationMessages()

        // use the web worker message event to update countdown time
        this.worker.onmessage = () => {
            this.getAllocationMessages()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.date !== this.props.date) {
            this.getAllocationMessages()
        }
    }

    componentWillUnmount() {
        this.worker.terminate()
        window.URL.revokeObjectURL(this.objectURL)
    }

    async getAllocationMessages() {

        this.setState({
            spin: 'fa-spin'
        })

        try {
            let messages = await getAllocationMessages(this.props.site.siteId, this.props.documentId)

            this.setState({
                messages,
                spin: ''
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    previewXML(xml, title) {

        this.setState({
            xml,
            title
        }, () => window.$('#xml-preview').modal('show'))
        
    }

    render() {

        return (
            <div className="row mt-4">
                <div className="col-sm">
                    <div>
                        <div className="row">
                            <div className="col-sm">
                                <h5 className="text-center mb-4">
                                    Allocation messages sent and received
                                    &nbsp;
                                    <small className="ml-2"><i className={'fas fa-sync-alt text-info cursor pointer ' + this.state.spin} title="Refresh messages" onClick={this.getAllocationMessages}></i></small>
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                {
                                    !this.state.messages ?
                                        <Loader text="Loading allocation messages..." />
                                        :
                                            !this.state.messages.length ?
                                                <div className="text-center">
                                                    <p>
                                                        {
                                                            moment(this.props.date, 'YYYY-MM-DD') < moment() ?
                                                                <span>Allocations messages not logged in database for {this.props.date}</span>
                                                                :
                                                                <span><i className="fas fa-exclamation-triangle mr-2 text-warning"></i> No allocations messages sent</span>
                                                        }
                                                    </p>
                                                </div>
                                                :
                                                <div>
                                                    <table className="table table-bordered table-sm">
                                                        <thead className="bg-light">
                                                            <tr>
                                                                <th>Document ID</th>
                                                                <th className="text-right">Version</th>
                                                                <th className="text-right">Sent at (Europe/Brussels)</th>
                                                                <th className="text-right">Sent XML</th>
                                                                <th className="text-right">Received at (Europe/Brussels)</th>
                                                                <th className="text-right">Received code</th>
                                                                <th className="text-right">Received message</th>
                                                                <th className="text-right">Received XML</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.messages.map(message => {
                                                                    return (
                                                                        <tr key={message.sent_dtlt}>
                                                                            <td>{message.document_id}</td>
                                                                            <td className="text-right">{message.version}</td>
                                                                            <td className="text-right">{message.sent_dtlt}</td>
                                                                            <td className="text-right">
                                                                                <span className="text-primary cursor pointer" onClick={() => this.previewXML(message.sent_xml, 'Sent')}>
                                                                                    Preview sent XML
                                                                                </span>
                                                                            </td>
                                                                            <td className="text-right">{message.received_dtlt}</td>
                                                                            <td className="text-right">{message.received_code}</td>
                                                                            <td className="text-right">{message.received_message}</td>
                                                                            <td className="text-right">
                                                                                {
                                                                                    message.received_xml ?
                                                                                        <span className="text-primary cursor pointer" onClick={() => this.previewXML(message.received_xml, 'Received')}>
                                                                                            Preview received XML
                                                                                        </span>
                                                                                        : null
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="xml-preview" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.title} XML Preview</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <AceEditor
                                    className="w-100"
                                    mode="xml"
                                    theme="tomorrow"
                                    wrapEnabled={true}
                                    readOnly={true}
                                    value={this.state.xml}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AllocationMessages