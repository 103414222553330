import Loader from './Loader'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import 'react-datepicker/dist/react-datepicker.css'

import { getAssetSchedule, updateAssetScheduleUK } from '../api/battery'

moment.tz.setDefault('Europe/Brussels')


class AssetScheduleUk extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedDate: moment().tz('Europe/London').format("YYYY-MM-DD"),
            assetSchedule: undefined,
            updatedSchedule: false,
            updating: false
        }

        this.getAssetSchedule = this.getAssetSchedule.bind(this)
        this.generatePeriods = this.generatePeriods.bind(this)
        this.disablePeriod = this.disablePeriod.bind(this)
        this.onDateChange = this.onDateChange.bind(this)
        this.onChange = this.onChange.bind(this)
        this.addTimeframe = this.addTimeframe.bind(this)
        this.onTimeframeChange = this.onTimeframeChange.bind(this)
        this.changeDisabled = this.changeDisabled.bind(this)
        this.scheduleStateUpdate = this.scheduleStateUpdate.bind(this)
        this.updateAssetScheduleUK = this.updateAssetScheduleUK.bind(this)
        this.deleteionDisabled = this.deleteionDisabled.bind(this)
    }

    componentDidMount() {
        this.getAssetSchedule()
        this.generatePeriods()
    }

    async getAssetSchedule() {
        this.setState({ assetSchedule: undefined })

        try {
            let assetSchedule = await getAssetSchedule(this.props.site.siteId, this.state.selectedDate)

            this.setState({ assetSchedule })
        }
        catch (err) {
            console.error(err)
        }
    }

    generatePeriods() {

        let a = moment(this.state.selectedDate, 'YYYY-MM-DD').startOf('day'),
            b = moment(this.state.selectedDate, 'YYYY-MM-DD').add(1, 'day').startOf('day'),
            h = b.diff(a, 'hours'),
            periods = [] // store the number of quarter-hours for today

        Array(h).fill(undefined).forEach((val, idx) => {
            periods.push(moment(this.state.selectedDate + ' ' + idx, "YYYY-MM-DD H").format("YYYY-MM-DD HH:mm:ss"))
            periods.push(moment(this.state.selectedDate + ' ' + idx, "YYYY-MM-DD H").add(30, 'minutes').format("YYYY-MM-DD HH:mm:ss"))
        })

        periods = periods.map(x => moment(x, 'YYYY-MM-DD HH:mm:ss').tz('Europe/London').format('YYYY-MM-DD HH:mm:ss'))

        periods.push(moment(periods[periods.length - 1], 'YYYY-MM-DD HH:mm:ss').add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'))

        periods = periods.filter(x => !this.disablePeriod(x))

        this.setState({
            periods
        })

    }

    disablePeriod(period) {
        return moment(period).isBefore(moment().tz('Europe/London').minutes(30 * Math.floor(moment().minutes() / 30)).seconds(0).milliseconds(0).format('YYYY-MM-DD HH:mm:ss'))
    }

    onDateChange(date) {
        this.setState({
            selectedDate: moment(date).format('YYYY-MM-DD'),
            assetSchedule: undefined,
            updatedSchedule: false
        },
            () => {
                this.getAssetSchedule()
                this.generatePeriods()
            }
        )
    }

    onChange(e) {

        let assetSchedule = this.state.assetSchedule,
            index = assetSchedule.findIndex(x => x.id === e.target.id),
            name = e.target.name || e.target.attributes.getNamedItem("data-name").value

        if (!assetSchedule[index].edited)
            assetSchedule[index].edited = true

        assetSchedule[index][e.target.name] = e.target.value

        switch (name) {

            case "delete":

                if (assetSchedule[index].newTimeframe)
                    assetSchedule.splice(index, 1)
                else {
                    assetSchedule[index].delete = true
                    assetSchedule[index].pBase = ''
                    assetSchedule[index].overruleFlag = ''
                    assetSchedule[index].pMaxCharge = ''
                    assetSchedule[index].pMaxDischarge = ''
                    assetSchedule[index].dch = ''
                    assetSchedule[index].dcl = ''
                    assetSchedule[index].dmh = ''
                    assetSchedule[index].dml = ''
                    assetSchedule[index].drh = ''
                    assetSchedule[index].drl = ''
                    assetSchedule[index].freePowerUP = ''
                    assetSchedule[index].freePowerDOWN = ''
                    assetSchedule[index].bmup = ''
                    assetSchedule[index].bmdown = ''
                    assetSchedule[index].brup = ''
                    assetSchedule[index].brdown = ''
                }

                break

            default:

                break
        }

        this.setState({
            assetSchedule
        }, this.scheduleStateUpdate)
    }

    addTimeframe() {

        let assetSchedule = this.state.assetSchedule,
            periodIndex = this.state.periods.findIndex(x => moment(x).isSameOrAfter(moment().tz('Europe/London').minutes(30 * Math.floor(moment().minutes() / 30)).seconds(0).milliseconds(0).format('YYYY-MM-DD HH:mm:ss'))),

            newTimeframe = {
                newTimeframe: true,
                id: this.props.site.battery.deviceId + '_' + moment().format('YYYYMMDDHHmmssSSS'),
                device_id: this.props.site.battery.deviceId,
                startTimeGB: moment(this.state.periods[periodIndex], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                endTimeGB: moment(this.state.periods[periodIndex + 1], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                delivery_date: this.state.selectedDate,
                pBase: '',
                overruleFlag: '',
                pMaxCharge: '',
                pMaxDischarge: '',
                dch: '',
                dcl: '',
                dmh: '',
                dml: '',
                drh: '',
                drl: '',
                freePowerUP: '',
                freePowerDOWN: '',
                bmup: '',
                bmdown: '',
                brup: '',
                brdown: ''
            }
        assetSchedule.push(newTimeframe)

        this.setState({
            assetSchedule: assetSchedule.sort((a, b) => moment(a.startTimeGB).isAfter(moment(b.startTimeGB)) ? 1 : -1)
        }, this.scheduleStateUpdate)
    }

    onTimeframeChange(e) {
        let assetSchedule = this.state.assetSchedule,
            index = assetSchedule.findIndex(x => x.id === e.target.id)

        assetSchedule[index][e.target.name] = e.target.value

        if (e.target.name === "startTimeGB" && moment(e.target.value, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment(assetSchedule[index].endTimeGB, "YYYY-MM-DD HH:mm:ss")))
            assetSchedule[index].endTimeGB = moment(e.target.value, "YYYY-MM-DD HH:mm:ss").add(30, 'minutes').format("YYYY-MM-DD HH:mm:ss")
        else if (e.target.name === "endTimeGB" && moment(e.target.value, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(moment(assetSchedule[index].startTimeGB, "YYYY-MM-DD HH:mm:ss")))
            assetSchedule[index].startTimeGB = moment(e.target.value, "YYYY-MM-DD HH:mm:ss").subtract(30, 'minutes').format("YYYY-MM-DD HH:mm:ss")

        this.setState({
            assetSchedule
        }, () => {
            setTimeout(() => {
                this.sortSchedule()
            }, 1000)
        })
    }

    sortSchedule() {
        this.setState({
            assetSchedule: this.state.assetSchedule.sort((a, b) => moment(a.startTimeGB).isAfter(moment(b.startTimeGB)) ? 1 : -1)
        })
    }

    scheduleStateUpdate() {
        if (this.state.assetSchedule && this.state.assetSchedule.filter(x => x.edited || x.newTimeframe || x.delete).length > 0)
            this.setState({
                updatedSchedule: true
            })
        else this.setState({
            updatedSchedule: false
        })
    }

    changeDisabled(endTimeGB) {
        return endTimeGB ? moment(endTimeGB, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(moment().tz('Europe/London').format('YYYY-MM-DD HH:mm:ss')) : moment(this.state.selectedDate).isBefore(moment().tz('Europe/London').startOf('day').format('YYYY-MM-DD'))
    }

    deleteionDisabled(x) {
        return !moment(x.endTimeGB, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment().tz('Europe/London').format('YYYY-MM-DD HH:mm:ss'))
    }

    async updateAssetScheduleUK() {

        this.setState({ updating: true, errors: undefined })

        let assetSchedule = this.state.assetSchedule.filter(x => x.edited || x.newTimeframe || x.delete),
            errors = []

        assetSchedule.forEach(x => {

            const pBase = parseFloat(x.pBase.toString().trim()) || null
            const overruleFlag = parseFloat(x.overruleFlag.toString().trim()) || null
            const pMaxDischarge = parseFloat(x.pMaxDischarge.toString().trim()) || null
            const pMaxCharge = parseFloat(x.pMaxCharge.toString().trim()) || null
            const dch = parseFloat(x.dch.toString().trim()) || null
            const dcl = parseFloat(x.dcl.toString().trim()) || null
            const dmh = parseFloat(x.dmh.toString().trim()) || null
            const dml = parseFloat(x.dml.toString().trim()) || null
            const drh = parseFloat(x.drh.toString().trim()) || null
            const drl = parseFloat(x.drl.toString().trim()) || null
            const freePowerUP = parseFloat(x.freePowerUP.toString().trim()) || null
            const freePowerDOWN = parseFloat(x.freePowerDOWN.toString().trim()) || null
            const bmup = parseFloat(x.bmup.toString().trim()) || null
            const bmdown = parseFloat(x.bmdown.toString().trim()) || null
            const brup = parseFloat(x.brup.toString().trim()) || null
            const brdown = parseFloat(x.brdown.toString().trim()) || null

            if (moment(x.endTimeGB, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(moment().tz('Europe/London').format('YYYY-MM-DD HH:mm:ss')))
                errors.push(`End of ${x.startTimeGB} - ${x.endTimeGB} timeframe is in the past.`)
        })

        let duplicates = assetSchedule.filter((e, i, arr) => {
            let i2 = arr.findIndex(e2 => e2.startTimeGB === e.startTimeGB && e2.endTimeGB === e.endTimeGB)
            return i2 !== -1 && i2 !== i
        })

        if (duplicates.length)
            errors.push(`Duplicate timeframes found.`)

        if (errors.length)
            this.setState({
                updating: false,
                errors
            })

        else {

            let res = await updateAssetScheduleUK(this.props.site.siteId, assetSchedule)

            if (res.errors)
                this.setState({
                    updating: false,
                    errors: res.errors
                }, () => {
                    setTimeout(() => {
                        this.setState({ errors: [] })
                    }, 5000)
                })

            else this.setState({
                updating: 'ok'
            }, () => {
                setTimeout(() => {
                    this.setState({ updating: false, assetSchedule: undefined, errors: undefined, edited: false })
                    this.getAssetSchedule()
                }, 5000)
            })
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm">
                    {
                        <div className="row">
                            <div className="col-sm">
                                <h3 className="card-title text-center mb-4">
                                    Asset Schedule for UK &nbsp;
                                    <div className="d-inline-block position-relative">
                                        <DatePicker
                                            className="font-weight-bold border-0 p-0 m-0 h3 text-body cursor pointer schedule-date-picker"
                                            dateFormat="yyyy-MM-dd"
                                            selected={moment(this.state.selectedDate, 'YYYY-MM-DD').toDate()}
                                            onChange={this.onDateChange}
                                            filterDate={(date) => moment(date) < moment().add(2, 'days')}
                                        />
                                        <sup className="position-absolute" style={{ top: '-7px', right: '-7px' }}>
                                            <small><i className="fas fa-info-circle text-info cursor help" title="Click on the date to change it"></i></small>
                                        </sup>
                                    </div>
                                </h3>
                                {
                                    !(process.env.NODE_ENV === 'development' || window.location.href.indexOf("dev") >= 0) ? null :
                                        <div>
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-lg-4 col-12 alert alert-warning text-center">
                                                    <span><i className="fa fa-exclamation-triangle alert-warning mr-2"></i> The asset schedule on a development environment works with the test tables.</span>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                }
                                {
                                    this.state.updating === 'ok' ?
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-lg-4 col-12 alert alert-info text-center">
                                                <h5><i className="fas fa-info-circle mr-2"></i> Asset schedule for {this.state.date} was succesfully updated.</h5>
                                                <h5><i className="fas fa-spinner fa-spin mr-2"></i> Please wait...</h5>
                                            </div>
                                        </div>
                                        : this.state.assetSchedule === undefined ? <Loader text="Loading asset schedule..." />
                                            : !this.state.assetSchedule.length ? <h5 className="text-center">Please check Grafana for the asset schedule</h5> :
                                                <table className="table table-bordered table-sm table-asset-schedule-uk pr-6">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th className="text-left">Start time (GB)</th>
                                                            <th className="text-left">End time (GB)</th>
                                                            <th className="text-right">P base (MW)</th>
                                                            <th className="text-right">Overrule Flag</th>
                                                            <th className="text-right">P Max Charge (MW)</th>
                                                            <th className="text-right">P Max Discharge (MW)</th>
                                                            <th className="text-right">dch (MW)</th>
                                                            <th className="text-right">dcl (MW)</th>
                                                            <th className="text-right">dmh (MW)</th>
                                                            <th className="text-right">dml (MW)</th>
                                                            <th className="text-right">drh (MW)</th>
                                                            <th className="text-right">drl (MW)</th>
                                                            <th className="text-right">Free Power UP (MW)</th>
                                                            <th className="text-right">Free Power DOWN (MW)</th>
                                                            <th className="text-right">BM UP (MW)</th>
                                                            <th className="text-right">BM DOWN (MW)</th>
                                                            <th className="text-right">BR UP (MW)</th>
                                                            <th className="text-right">BR DOWN (MW)</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.assetSchedule.map(x => {
                                                                return (
                                                                    <tr key={x.id} className="asset-schedule-uk">
                                                                        <td>
                                                                            {
                                                                                !x.newTimeframe
                                                                                    ? <input type="text" className={`form-control text-left${x.delete ? ' bg-danger text-white' : ''}`} id={x.id} name={'startTimeGB'} readOnly={true} value={x.startTimeGB} />
                                                                                    : <select className="form-control" id={x.id} name={'startTimeGB'} value={x.startTimeGB} onChange={e => this.onTimeframeChange(e)}>
                                                                                        {
                                                                                            this.state.periods.map(period => <option value={period} key={period} disabled={this.disablePeriod(period)}>{period}</option>)
                                                                                        }
                                                                                    </select>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                !x.newTimeframe
                                                                                    ? <input type="text" className={`form-control text-left${x.delete ? ' bg-danger text-white' : ''}`} id={x.id} name={'endTimeGB'} readOnly={true} value={x.endTimeGB} />
                                                                                    : <select className="form-control" id={x.id} name="endTimeGB" value={x.endTimeGB} onChange={e => this.onTimeframeChange(e)}>
                                                                                        {
                                                                                            this.state.periods.map(period => <option value={period} key={period} disabled={this.disablePeriod(period)}>{period}</option>)
                                                                                        }
                                                                                    </select>
                                                                            }
                                                                        </td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="pBase" value={x.pBase} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="overruleFlag" value={x.overruleFlag} /></td>
                                                                        <td><input type="number" className="form-control text-right" id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="pMaxCharge" value={x.PMaxCharge} /></td>
                                                                        <td><input type="number" className="form-control text-right" id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="pMaxDischarge" value={x.PMaxDischarge} /></td>
                                                                        <td><input type="number" className="form-control text-right" id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="dch" value={x.dch} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="dcl" value={x.dcl} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="dmh" value={x.dmh} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="dml" value={x.dml} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="drh" value={x.drh} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="drl" value={x.drl} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="freePowerUP" value={x.freePowerUP} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="freePowerDOWN" value={x.freePowerDOWN} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="bmup" value={x.bmup} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="bmdown" value={x.bmdown} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="brup" value={x.brup} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name="brdown" value={x.brdown} /></td>
                                                                        <td>
                                                                            <div className="position-relative">
                                                                                <input type="number" className={`form-control text-right${this.deleteionDisabled(x) ? '' : ' pr-5'}`} id={x.id} readOnly={this.changeDisabled(x.endTimeGB) || x.delete} onChange={this.onChange} name={'pRealTimeMax'} value={x.pRealTimeMax} />
                                                                                {
                                                                                    this.deleteionDisabled(x) || x.delete ? null :
                                                                                        <div className="d-flex align-items-center position-absolute h-100 pr-2" style={{ fontSize: '1.5rem', top: 0, right: 0 }}>
                                                                                            <i className="fas fa-times fa-lg text-danger cursor pointer" title="Remove timeframe" data-name="delete" id={x.id} onClick={e => this.onChange(e)}></i>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                }
                                {
                                    !(this.state.errors && this.state.errors.length) ? null :
                                        <div>
                                            <div className="alert alert-warning">
                                                <i className="fas fa-exclamation-triangle mr-2"></i> The following {this.state.errors.length > 1 ? 'errors' : 'error'} occured:
                                                <ul>
                                                    {
                                                        this.state.errors.map(error => (<li key={Math.random()}>{error}</li>))
                                                    }
                                                </ul>
                                            </div>
                                            <br />
                                        </div>
                                }
                                {
                                    this.state.assetSchedule === undefined || this.changeDisabled() || this.state.updating === 'ok' ? null :
                                        <div>
                                            <button className="btn btn-warning mr-3" type="button" onClick={this.addTimeframe}>
                                                <i className="fas fa-plus mr-2"></i> Add new timeframe
                                            </button>
                                            <button className="btn btn-primary" type="button" onClick={this.updateAssetScheduleUK} disabled={!this.state.updatedSchedule || this.state.updating}>
                                                <i className={'fas mr-2 ' + (this.state.updating ? 'fa-spinner fa-spin' : 'fa fa-save')}></i> Update new asset schedule
                                            </button>
                                        </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div >
        )
    }
}

export default AssetScheduleUk