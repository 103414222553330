import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { login } from '../api/user'


class Login extends Component {
    constructor (props) {
        super(props)

        this.state = {
            email: '',
            password: '',
            loginStage: 'initial'
        }

        this.userLogin = this.userLogin.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
        this.renderLoginForm = this.renderLoginForm.bind(this)
        this.renderConfirmAccount = this.renderConfirmAccount.bind(this)
    }

    userLogin() {
        login(
            this.state.email,
            this.state.password,
            (err, res) => {
                if (err && err.newPassRequired) {
                    this.setState({ loginStage: 'new-password-required' })
                }
                else if (err) {
                    this.setState({ loginStage: 'retry' })
                }
                else {
                    this.setState({ loginStage: 'logged-in' })
                    
                    this.props.onLogin(res)
                }
            }
        )
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value })
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value })
    }

    handleLoginSubmit(e) {
        e.preventDefault()

        this.setState({ loginStage: 'logging-in' })

        this.userLogin()
    }

    renderLoginForm() {
        return (
            <form onSubmit={this.handleLoginSubmit} noValidate>
                <h1 className="form-title text-center mb-5">
                    <Link to="/">
                        <img src="/yuso-flex-logo.png" alt="Yuso" className="mb-4 position-relative form-logo" />
                    </Link>
                    <br />
                    <strong>Welcome,</strong>
                    <br />
                    to Yuso Flex portal
                </h1>
                <div className="form-group">
                    <input
                        className="form-control p-3"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        placeholder="E-mail"
                        autoComplete="off"
                        type="email"
                    />
                </div>
                <div className="form-group">
                    <input
                        className="form-control p-3"
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        placeholder="Password"
                        autoComplete="off"
                        type="password"
                    />
                </div>
                <div className="alert alert-warning">
                    <i className="fas fa-info-circle mr-2"></i> Use your My Yuso username and password to log-in to the Yuso Flex portal
                </div>
                {
                    this.state.loginStage === 'retry' && <div className="alert alert-danger">
                        Incorrect email address or password. Please try again
                    </div>
                }
                <div className="form-group d-flex flex-column flex-lg-row justify-content-between">
                    <small className="text-center text-lg-left mb-2"><Link to="/forgot-password" className="text-muted">Forgot your password?</Link></small>
                    <button
                        type="submit"
                        className="btn btn-primary px-5 py-3"
                        disabled={this.state.loginStage === 'logging-in' ? 'disabled' : undefined}
                    >
                        Login <i className="fas fa-arrow-right"></i>
                    </button>
                </div>
            </form>
        )
    }

    renderConfirmAccount() {
        return <span>Please first confirm your account at <a href="https://my.yuso.io">My Yuso</a></span>
    }

    render() {
        if (this.state.loginStage === 'new-password-required') {
            return this.renderConfirmAccount()
        }

        return this.renderLoginForm()
    }
}

export default Login
