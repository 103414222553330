const checkStomp = (stompSubscribe, topic, cb) => {
    if (!window.stomp) {

        setTimeout(() => {
            stompSubscribe(topic, cb)
        }, 1000)

        return false
    }

    return true
}

export default checkStomp