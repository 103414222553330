import React, { Component } from 'react'

class Loader extends Component {
    constructor(props) {
        super(props)

        this.spinner = this.spinner.bind(this)
    }

    spinner() {
        return <span>
            <span className="fas fa-spinner fa-spin mr-2"></span> {this.props.noText ? null : this.props.text || 'Loading...'}
        </span>
    }

    render() {

        if (this.props.inline)
            return this.spinner()

        return <div className={'d-flex justify-content-center align-items-center p-2 ' + (this.props.className || '')}>
            {this.spinner()}
        </div>
    }
}

export default Loader