import React, { Component } from 'react'
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, ReferenceLine, Tooltip, Legend } from 'recharts'
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent'

import Loader from '../Loader'

const CustomTooltip = props => {

    if (props.active && props.payload && props.payload[0])
        props.payload.push({
            color: props.payload[0].payload.validated >= 3 ? "#00FF00" : "#FF0000",
            dataKey: "validated",
            name: "Validated",
            value: props.payload[0].payload.validated >= 3 ? "Y" : "N",
            payload: props.payload[0].payload
        })

    return <DefaultTooltipContent {...props} />
}

class Energy extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title"><span>Energy ({this.props.unit}h) <small><i className="fa fa-info-circle ml-1 text-info" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="Energy + sign: Energy volume stored in BESS (consumption)<br>Energy - sign: Energy volume discharged from BESS (injection)"></i></small></span>
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_energy" onChange={e => this.props.toggleDynamicYAxis(e, 'energy')} />
                                <label className="form-check-label" htmlFor="yaxis_energy">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingEnergyData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={this.props.energyData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} />
                                    <YAxis type="number" domain={[this.props.dynamicYAxis.energy ? 'auto' : 'dataMin - 1', this.props.dynamicYAxis.energy ? 'auto' : 'dataMax + 1']} tickFormatter={this.props.tickFormatterY} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    <ReferenceLine y={0} stroke="#66c766" ifOverflow="extendDomain" />
                                    {
                                        [
                                            data.energySS === false ? null : <Bar dataKey={`energyInSs${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.energyOnIm ? ' ' : ''}`} name="BESS Energy in self-supply" fill="#32CD32" legendType="circle" unit={this.props.unit + 'h'} isAnimationActive={false} key={0} />,
                                            data.energyIM === false ? null : <Bar dataKey={`energyOnIm${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.energyInSs ? ' ' : ''}`} name="BESS energy on imbalance" fill="#FF0000" legendType="circle" unit={this.props.unit + 'h'} isAnimationActive={false} key={1} />,
                                            data.grid === false ? null : this.props.report && <Bar dataKey={`energyGrid${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.energyGrid ? ' ' : ''}`} name="Grid energy" fill="#bb5f00" legendType="circle" unit={this.props.unit + 'h'} isAnimationActive={false} key={2} />
                                        ]
                                    }
                                </BarChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default Energy