import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Header extends Component {
  render() {
    return <nav className="navbar navbar-expand-md navbar-dark bg-primary fixed-top">

      <Link to="/" className="navbar-brand">
        <img src="/yuso-flex-logo-inverted.png" alt="Yuso" className="menu-logo" />
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbars" aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbars">
        {
          this.props.sites && this.props.sites.length ?
            <ul className="navbar-nav bg-primary">
              <li className="nav-item dropdown">
                <span className="nav-link dropdown-toggle cursor default font-weight-bold" title="Selected site">{this.props.site ? this.props.site.siteName : 'Sites'}</span>
                <div className="dropdown-menu" aria-labelledby="dropdown">
                  {
                    this.props.sites.map(site => {
                      return (
                        <Link className="dropdown-item" to={`/${site.siteId}/${this.props.activePage !== '/' ? this.props.activePage : 'monitoring'}`} onClick={() => this.props.selectSite(site.siteId)} key={site.siteId}>{site.siteName}</Link>
                      )
                    })
                  }
                </div>
              </li>
              {
                this.props.site ?
                  [
                    <li className={'nav-item ' + (this.props.activePage === 'monitoring' ? 'active' : '')} key={0}>
                      <Link className="nav-link" to={`/${this.props.site.siteId}/monitoring`}>Monitoring</Link>
                    </li>,
                    <li className={'nav-item ' + (this.props.activePage === 'reports' ? 'active' : '')} key={1}>
                      <Link className="nav-link" to={`/${this.props.site.siteId}/reports`}>Reports</Link>
                    </li>,
                    this.props.user.info.role === 'admin' || this.props.site.siteId === 'aes' || (this.props.site.battery && this.props.site.battery.flexPortalConfig && this.props.site.battery.flexPortalConfig.steering && (this.props.site.battery.flexPortalConfig.steering.enabled && this.props.site.battery.flexPortalConfig.steering.user_access.includes(this.props.user.info.email))) ?
                      <li className={'nav-item ' + (this.props.activePage === 'dispatch' ? 'active' : '')} key={2}>
                        <Link className="nav-link" to={`/${this.props.site.siteId}/dispatch`}>Dispatch</Link>
                      </li> : null,
                    this.props.user.info.role === 'admin' ? <li className={'nav-item ' + (this.props.activePage === 'management' ? 'active' : '')} key={3}>
                      <Link className="nav-link" to={`/${this.props.site.siteId}/management`}>Management</Link>
                    </li>
                      : null
                  ]
                  : null
              }
            </ul>
            : null
        }

        <ul className="navbar-nav bg-primary ml-auto">
          {
            this.props.sites && this.props.sites.length && (this.props.activePage === 'monitoring' || this.props.activePage === 'reports') ?
              <li className="nav-item dropdown">
                <span className="nav-link dropdown-toggle cursor default font-weight-bold" title="Selected unit">
                  Unit&nbsp;{this.props.unit}
                </span>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown">
                  <span className="dropdown-item cursor pointer" onClick={() => this.props.selectUnit('kW')}>Kilowatts (<strong>kW</strong>)</span>
                  <span className="dropdown-item cursor pointer" onClick={() => this.props.selectUnit('MW')}>Megawatts (<strong>MW</strong>)</span>
                </div>
              </li>
              : null
          }
          <li className="nav-item">
            <span className="nav-link text-white cursor default" title="Logged in user">
              {this.props.user.username}
            </span>
          </li>
          <li className="nav-item">
            <span className="nav-link cursor pointer" onClick={this.props.onLogout}>
              Logout
            </span>
          </li>
        </ul>
      </div>
    </nav>
  }
}

export default Header