import React, { Component } from 'react'
import { ResponsiveContainer, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'

import Loader from './Loader'

class Status extends Component {

    render() {

        return (
            this.props.site.siteId === 'aes'
                ? [
                    <div className="col-sm col-md-6 col-lg-3" key={0}>
                        <div className="card border-0 h-100">
                            <div className="card-body">
                                <h5 className="card-title">Last Received Update</h5>
                                <h4><strong>{this.props.latestData ? this.props.latestData.dtlt : 'No update'}</strong></h4>
                                <div className="card-text">
                                    {this.props.getLastUpdate(true)}
                                </div>
                            </div>
                        </div>
                    </div>,
                    <div className="col-sm col-md-6 col-lg-3" key={1}>
                        <div className="card border-0 h-100">
                            <div className="card-body">
                                <h5 className="card-title">Gateway server <span className="float-right chart-gateway-status" title="Updates in" style={{ fontSize: '0.85rem' }}></span></h5>
                                {
                                    !this.props.status ? <Loader /> :
                                        <div>
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td><h6 className="m-0">State:</h6></td>
                                                        <td className={`alert m-0 flex-fill ${this.props.status.status.InstanceState.Name === 'running' ? 'alert-success' : 'alert-warning'}`}><h6 className="m-0"><strong>{this.props.status.status.InstanceState.Name.toUpperCase()}</strong></h6></td>
                                                    </tr>
                                                    <tr>
                                                        <td><h6 className="m-0">Instance status:</h6></td>
                                                        <td className={`alert m-0 flex-fill ${this.props.status.status.InstanceStatus.Status === 'ok' ? 'alert-success' : 'alert-warning'}`}><h6 className="m-0"><strong>{this.props.status.status.InstanceStatus.Status.toUpperCase()}</strong></h6></td>
                                                    </tr>
                                                    <tr>
                                                        <td><h6 className="m-0">System status:</h6></td>
                                                        <td className={`alert m-0 flex-fill ${this.props.status.status.SystemStatus.Status === 'ok' ? 'alert-success' : 'alert-warning'}`}><h6 className="m-0"><strong>{this.props.status.status.SystemStatus.Status.toUpperCase()}</strong></h6></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>,
                    <div className="col-sm col-md-6 col-lg-3" key={2}>
                        <div className="card border-0 h-100">
                            <div className="card-body">
                                <h5 className="card-title">Gateway CPU % <span className="float-right chart-gateway-status" title="Updates in" style={{ fontSize: '0.85rem' }}></span></h5>
                                {
                                    !this.props.status ? <Loader /> :
                                        <ResponsiveContainer width="100%" height={150}>
                                            <AreaChart data={this.props.status.metrics.find(x => x.Label === 'AWS/EC2 i-04aec8c58c90bb724 CPUUtilization').ChartData} margin={{ top: 5, right: 0, bottom: 0, left: 0 }}>
                                                <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                                <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} />
                                                <YAxis type="number" domain={['auto', 'auto']} />
                                                <Tooltip />
                                                <Legend verticalAlign="bottom" height={36} onClick={this.toggleChart} />
                                                <Area type="monotone" dataKey="value" name="CPU utilization" unit="%" stroke="#3f51b5" fill="#3f51b5" fillOpacity="0.1" isAnimationActive={false} />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                }
                            </div>
                        </div>
                    </div>,
                    <div className="col-sm col-md-6 col-lg-3" key={3}>
                        <div className="card border-0 h-100">
                            <div className="card-body">
                                <h5 className="card-title">Gateway Network (KB) <span className="float-right chart-gateway-status" title="Updates in" style={{ fontSize: '0.85rem' }}></span></h5>
                                {
                                    !this.props.status ? <Loader /> :
                                        <ResponsiveContainer width="100%" height={150}>
                                            <AreaChart data={this.props.status.metrics.find(x => x.Label === 'AWS/EC2 i-04aec8c58c90bb724 NetworkIn').ChartData.map((x, i) => { return { time: x.time, valueIn: (x.value / 1024).toFixed(2), valueOut: (this.props.status.metrics.find(x => x.Label === 'AWS/EC2 i-04aec8c58c90bb724 NetworkOut').ChartData[i].value / 1024).toFixed(2) } })} margin={{ top: 5, right: 0, bottom: 0, left: 0 }}>
                                                <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                                <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} />
                                                <YAxis type="number" domain={['auto', 'auto']} />
                                                <Tooltip />
                                                <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                                <Area type="monotone" dataKey="valueIn" name="Network in" unit="KB" stroke="#3f51b5" fill="#3f51b5" fillOpacity="0.1" isAnimationActive={false} />
                                                <Area type="monotone" dataKey="valueOut" name="Network out" unit="KB" stroke="#d04239" fill="#d04239" fillOpacity="0.1" isAnimationActive={false} />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                }
                            </div>
                        </div>
                    </div>
                ]
                : <div className="col-sm col-md-6" key={0}>
                    <div className="card border-0 h-100">
                        <div className="card-body">
                            <h5 className="card-title">Last Received Update</h5>
                            <h4><strong>{this.props.latestData ? this.props.latestData.dtlt : 'No update'}</strong></h4>
                            <div className="card-text">
                                {this.props.getLastUpdate(true)}
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Status