import React, { Component } from 'react'

class NotFound extends Component {
    render() {
        return <div className="p-5">
                <div className="p-5 text-center">
                    <h3>404 Page not found</h3>
                </div>
            </div>
    }
}

export default NotFound