import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'

import Loader from '../components/Loader'
import ActivePower from '../components/charts/ActivePower'
import AvailableDischargeEnergy from '../components/charts/AvailableDischargeEnergy'
import Power from '../components/charts/Power'
import Energy from '../components/charts/Energy'
import Frequency from '../components/charts/Frequency'
import FrequencyResponse from '../components/charts/FrequencyResponse'
import StateOfCharge from '../components/charts/StateOfCharge'
import Imbalance from '../components/charts/Imbalance'
import EnergyPrices from '../components/charts/EnergyPrices'
import CapacityFCR from '../components/charts/CapacityFCR'
import PriceFCR from '../components/charts/PriceFCR'
import Revenue from '../components/charts/Revenue'
import ImbalanceRevenue from '../components/charts/ImbalanceRevenue'
import Throughput from '../components/charts/Throughput'
import BalancingPrice from '../components/charts/BalancingPrice'
import BaselinePowerSOC from '../components/charts/BaselinePowerSOC'

import 'react-datepicker/dist/react-datepicker.css'

import { getBatteryDataReport, getFrequencyDataReport, getEnergyDataReport, getEnergyPricesReport, getImbalanceReport, getImbalancePricesReport, getFCRReport, getRevenueReport, getThroughputReport, getDexterDataReport } from '../api/battery'

moment.tz.setDefault('Europe/Brussels')

const periods = [
    { id: 'today', name: 'Today', startDate: moment().startOf('day').toDate(), endDate: moment().endOf('day').toDate() },
    { id: 'yesterday', name: 'Yesterday', startDate: moment().subtract(1, 'day').startOf('day').toDate(), endDate: moment().subtract(1, 'day').endOf('day').toDate() },
    { id: 'div1', divider: true },
    { id: 'this_week', name: 'This week', startDate: moment().startOf('isoWeek').toDate(), endDate: moment().endOf('isoWeek').toDate() },
    { id: 'this_month', name: 'This month', startDate: moment().startOf('month').toDate(), endDate: moment().endOf('month').toDate() },
    { id: 'this_quarter', name: 'This quarter', startDate: moment().startOf('quarter').toDate(), endDate: moment().endOf('quarter').toDate() },
    { id: 'this_year', name: 'This year', startDate: moment().startOf('year').toDate(), endDate: moment().endOf('year').toDate() },
    { id: 'div2', divider: true },
    { id: 'previous_week', name: 'Previous week', startDate: moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), endDate: moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate() },
    { id: 'previous_month', name: 'Previous month', startDate: moment().subtract(1, 'month').startOf('month').toDate(), endDate: moment().subtract(1, 'month').endOf('month').toDate() },
    { id: 'previous_quarter', name: 'Previous quarter', startDate: moment().subtract(1, 'quarter').startOf('quarter').toDate(), endDate: moment().subtract(1, 'quarter').endOf('quarter').toDate() },
    { id: 'previous_year', name: 'Previous year', startDate: moment().subtract(1, 'year').startOf('year').toDate(), endDate: moment().subtract(1, 'year').endOf('year').toDate() },
    { id: 'div3', divider: true },
    { id: 'all', name: 'All time', startDate: moment(0).startOf('day').toDate(), endDate: moment().endOf('day').toDate() },
    { id: 'custom', name: 'Custom', startDate: moment().startOf('day').toDate(), endDate: moment().endOf('day').toDate() } // default to today
]

class Reports extends Component {
    constructor(props) {
        super(props)

        let period = periods[0]

        try {
            period = JSON.parse(window.localStorage.period)
            period = { ...period, startDate: moment(period.startDate).toDate(), endDate: moment(period.endDate).toDate() }
        }
        catch (err) { }

        this.state = {
            period,
            startDate: period.startDate,
            endDate: period.endDate,
            loadingBatteryData: true,
            loadingFrequencyData: true,
            loadingEnergyData: true,
            loadingEnergyPricesData: true,
            loadingImbalanceData: true,
            loadingImbalancePricesData: true,
            loadingFCRData: true,
            loadingRevenueData: true,
            loadingThroughputData: true,
            loadingBalancingPriceData: true,
            loadingImbForecastAccuracyData: true,
            hidden: {},
            dynamicYAxis: {}
        }

        this.updateBatteryData = this.updateBatteryData.bind(this)
        this.updateFrequencyData = this.updateFrequencyData.bind(this)
        this.updateEnergyData = this.updateEnergyData.bind(this)
        this.updateEnergyPricesData = this.updateEnergyPricesData.bind(this)
        this.updateImbalanceData = this.updateImbalanceData.bind(this)
        this.updateImbalancePricesData = this.updateImbalancePricesData.bind(this)
        this.updateFCRData = this.updateFCRData.bind(this)
        this.updateRevenueData = this.updateRevenueData.bind(this)
        this.updateThroughputData = this.updateThroughputData.bind(this)
        this.updateBalancingPriceData = this.updateBalancingPriceData.bind(this)
        this.updateImbForecastAccuracyData = this.updateImbForecastAccuracyData.bind(this)
        this.tickFormatterX = this.tickFormatterX.bind(this)
        this.tickFormatterY = this.tickFormatterY.bind(this)
        this.onPeriodChange = this.onPeriodChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeStart = this.handleChangeStart.bind(this)
        this.handleChangeEnd = this.handleChangeEnd.bind(this)
        this.futureDays = this.futureDays.bind(this)
        this.toggleChart = this.toggleChart.bind(this)
        this.toggleDynamicYAxis = this.toggleDynamicYAxis.bind(this)
        this.applyFilter = this.applyFilter.bind(this)
    }

    componentDidMount() {

        if (!this.props.site || (this.props.site && this.props.site.siteId !== this.props.siteId))
            this.props.selectSite(this.props.siteId)
        else {
            this.updateBatteryData()
            this.updateFrequencyData()
            this.updateEnergyData()
            this.updateEnergyPricesData()
            this.updateImbalanceData()
            this.updateImbalancePricesData()
            this.updateFCRData()
            this.updateRevenueData()
            this.updateThroughputData()
            this.updateBalancingPriceData()
            this.updateImbForecastAccuracyData()
        }
    }

    componentDidUpdate() {
        window.$('[data-toggle="tooltip"]').tooltip()

        // make the date picker inpt field readonly
        let datePickers = document.querySelectorAll('.react-datepicker-wrapper input')
        if (datePickers.length)
            datePickers.forEach(x => x.setAttribute('readonly', true))
    }

    async updateBatteryData(cb) {

        this.setState({ loadingBatteryData: true })

        try {
            let response = await getBatteryDataReport(this.props.site.siteId, moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'))

            let batteryData = response.data
            let batteryStates = response.states ? response.states : {}

            this.setState({
                batteryData,
                batteryStates
            }, () => {
                this.setState({ loadingBatteryData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async updateFrequencyData(cb) {

        this.setState({ loadingFrequencyData: true })

        try {
            let frequencyData = await getFrequencyDataReport(this.props.site.siteId, moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'))

            this.setState({
                frequencyData
            }, () => {
                this.setState({ loadingFrequencyData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async updateEnergyData(cb) {

        this.setState({ loadingEnergyData: true })

        try {
            let response = await getEnergyDataReport(this.props.site.siteId, moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss')),
                energyData = response.revenues,
                energyCalculations = response.calculations

            this.setState({
                energyData,
                energyCalculations
            }, () => {
                this.setState({ loadingEnergyData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async updateEnergyPricesData(cb) {

        this.setState({ loadingEnergyPricesData: true })

        try {
            let energyPrices = await getEnergyPricesReport(this.props.site.siteId, moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'))

            this.setState({
                energyPrices
            }, () => {
                this.setState({ loadingEnergyPricesData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async updateImbalanceData(cb) {

        this.setState({ loadingImbalanceData: true })

        try {
            let imbalanceData = await getImbalanceReport(this.props.site.siteId, moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'))

            this.setState({
                imbalanceData
            }, () => {
                this.setState({ loadingImbalanceData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async updateImbalancePricesData(cb) {

        this.setState({ loadingImbalancePricesData: true })

        try {
            let imbalancePricesData = await getImbalancePricesReport(this.props.site.siteId, moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'))

            this.setState({
                imbalancePricesData
            }, () => {
                this.setState({ loadingImbalancePricesData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async updateFCRData(cb) {

        this.setState({ loadingFCRData: true })

        try {
            let fcrData = await getFCRReport(this.props.site.siteId, moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'))

            this.setState({
                fcrData
            }, () => {
                this.setState({ loadingFCRData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async updateRevenueData(cb) {

        this.setState({ loadingRevenueData: true })

        try {
            let revenueData = await getRevenueReport(this.props.site.siteId, moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'))

            const imbalanceRevenue = revenueData.length ? revenueData.map(x => x.revenueImbalance).reduce((sum, current) => sum += current, 0).toFixed(2) : null
            const FCRRevenue = revenueData.length ? revenueData.map(x => x.revenueFCR || 0).reduce((sum = 0, current) => sum += current, 0).toFixed(2) : null
            const totalRevenue = revenueData.length ? revenueData.map(x => x.totalRevenue).reduce((sum, current) => sum += current, 0).toFixed(2) : null

            this.setState({
                revenueData,
                imbalanceRevenue,
                FCRRevenue,
                totalRevenue
            }, () => {
                this.setState({ loadingRevenueData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async updateThroughputData(cb) {

        this.setState({ loadingThroughputData: true })

        try {
            let response = await getThroughputReport(this.props.site.siteId, moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'))

            this.setState({
                throughputData: response.throughputData,
                throughputUpperBound: response.throughputUpperBound,
                dischargeCycles: response.dischargeCycles
            }, () => {
                this.setState({ loadingThroughputData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async updateBalancingPriceData(cb) {

        this.setState({ loadingBalancingPriceData: true })

        try {
            let balancingPriceData = await getDexterDataReport(this.props.site.siteId, 'balancingPrice', moment(this.state.startDate).format('YYYY-MM-DD HH:mm'), moment(this.state.endDate).add(1, "second").format('YYYY-MM-DD HH:mm'))

            this.setState({
                balancingPriceData
            }, () => {
                this.setState({ loadingBalancingPriceData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    async updateImbForecastAccuracyData(cb) {

        this.setState({ loadingImbForecastAccuracyData: true })

        try {
            let imbForecastAccuracyData = await getDexterDataReport(this.props.site.siteId, 'imbalanceForecastAccuracy', moment(this.state.startDate).format('YYYY-MM-DD HH:mm'), moment(this.state.endDate).add(1, "second").format('YYYY-MM-DD HH:mm'))

            this.setState({
                imbForecastAccuracyData
            }, () => {
                this.setState({ loadingImbForecastAccuracyData: false })
                !!cb && cb()
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    tickFormatterX(tick, format) {
        const time = moment(tick, 'YYYY-MM-DD HH:mm:ss')

        if (['today', 'yesterday'].includes(this.state.period.id))
            return time.format(format || 'H:mm')

        return time.format(format || 'Y-MM-DD H:mm')
    }

    tickFormatterY(tick) {
        return parseFloat(tick).toFixed()
    }

    onPeriodChange(e) {

        let period = periods.find(x => x.id === e.target.value)
        let startDate = period.startDate
        let endDate = period.endDate

        if (period.id === 'custom') {
            startDate = this.state.startDate
            endDate = this.state.endDate
            period = { ...period, startDate, endDate }

            if (this.startDate)
                this.startDate.input.focus()
        }

        this.setState({
            period,
            startDate,
            endDate
        })

        window.localStorage.period = JSON.stringify(period)
    }

    handleChange({ startDate, endDate }) {

        startDate = startDate || this.state.startDate

        endDate = endDate || this.state.endDate

        if (startDate.valueOf() > endDate.valueOf()) {
            let tmp = moment(endDate).startOf('day').toDate()
            endDate = moment(startDate).endOf('day').toDate()
            startDate = tmp
        }

        let period = periods.find(x => x.id === 'custom')
        period = { ...period, startDate, endDate }

        this.setState({
            period,
            startDate,
            endDate
        })

        window.localStorage.period = JSON.stringify(period)
    }

    handleChangeStart(startDate) {
        this.handleChange({ startDate })
    }

    handleChangeEnd(endDate) {
        this.handleChange({ endDate })
    }

    futureDays(day) {
        return day.valueOf() < new Date().valueOf()
    }

    toggleChart(e, i, el) {
        el.target.classList.toggle('text-muted')

        let unit = e.payload.unit
        if (unit && unit.endsWith('h'))
            unit = unit.substring(0, unit.length - 1)

        let key = e.dataKey.trim()
        let index = key.lastIndexOf(unit)

        if (index > 0)
            key = key.substring(0, index)

        this.setState({
            hidden: { ...this.state.hidden, [key]: !this.state.hidden[key] }
        })
    }

    toggleDynamicYAxis(e, key) {
        this.setState({
            dynamicYAxis: { ...this.state.dynamicYAxis, [key]: e.target.checked }
        })
    }

    applyFilter() {
        this.updateBatteryData()
        this.updateFrequencyData()
        this.updateEnergyData()
        this.updateEnergyPricesData()
        this.updateImbalanceData()
        this.updateImbalancePricesData()
        this.updateFCRData()
        this.updateRevenueData()
        this.updateThroughputData()
        this.updateBalancingPriceData()
        this.updateImbForecastAccuracyData()
    }

    render() {

        if (!this.props.site)
            return <Loader className="container align-items-center" noText={true} />

        if (this.props.site.battery === null)
            return <div className="row d-flex justify-content-center">
                <div className="col-sm-6 text-center py-5">
                    <span className="display-1">
                        <i className="fas fa-exclamation-triangle text-danger"></i>
                    </span>
                    <h1>You have not yet connected battery to {this.props.site.siteName}</h1>
                    <p className="h5 pb-5 mb-5">
                        A battery system, linked to local production, can avoid distribution and transport costs of your energy. Yuso helps you with dimensioning, financing and market-driven control of your battery system. Interested? Contact us at <a href="mailto:info@yuso.be">info@yuso.be</a>.
                    </p>
                </div>
            </div>

        const charts = this.props.site.battery.visualisationConfig.reports || {}

        return <div className="row">
            <div className="col-sm">
                <h1 className="text-center">
                    {this.props.site.siteName} Reports Dashboard
                </h1>
                <h4 className="text-center text-muted">
                    Yuso Inside BESS Application
                </h4>
                <div className="row mb-3">
                    <div className="col-sm">
                        <div className="form-inline d-flex flex-column flex-lg-row justify-content-center">
                            <select className="form-control align-self-md-stretch mb-3 mx-1" value={this.state.period.id} onChange={this.onPeriodChange}>
                                {
                                    periods.map(p => {
                                        if (p.divider)
                                            return <option key={p.id} disabled value="">────────────</option>

                                        return <option key={p.id} value={p.id}>{p.name}</option>
                                    })
                                }
                            </select>
                            <DatePicker
                                className="form-control align-self-md-stretch bg-white mb-3 mx-1"
                                dateFormat="yyyy-MM-dd HH:mm"
                                showTimeSelect
                                timeIntervals={5}
                                timeFormat="HH:mm"
                                injectTimes={[moment().endOf('day').toDate()]}
                                selected={this.state.startDate}
                                selectsStart
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={this.handleChangeStart}
                                filterDate={this.futureDays}
                                ref={el => (this.startDate = el)}
                            />
                            <DatePicker
                                className="form-control align-self-md-stretch bg-white mb-3 mx-1"
                                dateFormat="yyyy-MM-dd HH:mm"
                                showTimeSelect
                                timeIntervals={5}
                                timeFormat="HH:mm"
                                injectTimes={[moment().endOf('day').toDate()]}
                                selected={this.state.endDate}
                                selectsEnd
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={this.handleChangeEnd}
                                filterDate={this.futureDays}
                            />
                            <button type="button" className="btn btn-primary px-5 align-self-md-stretch mb-3 mx-1" onClick={this.applyFilter}>Apply <i className="fas fa-chevron-right ml-1" style={{ fontSize: '0.8rem' }}></i></button>
                        </div>
                    </div>
                </div>

                {/* INFO BOXES */}
                {
                    !charts.includes("BatteryRevenuesInfoBoxes") ? null :
                        <div className='group-card pt-3 px-3 mb-4'>
                            <div className="row">
                                <div className="col-sm col-md-4">
                                    <div className="card border-0 mb-4">
                                        <div className="card-body">
                                            <h5 className="card-title text-center">Imbalance revenue</h5>
                                            <div className="card-text">
                                                {
                                                    this.state.loadingRevenueData ?
                                                        <Loader>Loading...</Loader>
                                                        :
                                                        <h4 className="text-center font-weight-bold">{this.state.imbalanceRevenue || this.state.imbalanceRevenue === 0 ? `€${this.state.imbalanceRevenue}` : 'N/A'}</h4>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.props.site.siteId === 'aes' ?
                                        <div className="col-sm col-md-4">
                                            <div className="card border-0 mb-4">
                                                <div className="card-body">
                                                    <h5 className="card-title text-center">FCR revenue</h5>
                                                    <div className="card-text">
                                                        {
                                                            this.state.loadingRevenueData ?
                                                                <Loader>Loading...</Loader>
                                                                :
                                                                <h4 className="text-center font-weight-bold">{this.state.FCRRevenue || this.state.FCRRevenue === 0 ? `€${this.state.FCRRevenue}` : 'N/A'}</h4>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-sm col-md-4">
                                            <div className="card border-0 mb-4">
                                                <div className="card-body">
                                                    <h5 className="card-title text-center">Net revenue</h5>
                                                    <div className="card-text">
                                                        {
                                                            this.state.loadingRevenueData ?
                                                                <Loader>Loading...</Loader>
                                                                :
                                                                <h4 className="text-center font-weight-bold">{this.state.totalRevenue || this.state.totalRevenue === 0 ? `€${this.state.totalRevenue}` : 'N/A'}</h4>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                                <div className="col-sm col-md-4">
                                    <div className="card border-0 mb-4">
                                        <div className="card-body">
                                            <h5 className="card-title text-center">State of health</h5>
                                            <div className="card-text">
                                                {
                                                    !this.state.batteryStates ?
                                                        <Loader>Loading...</Loader>
                                                        :
                                                        <h4 className="text-center font-weight-bold">{this.state.batteryStates.SOH ? `${this.state.batteryStates.SOH}%` : 'N/A'}</h4>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.loadingEnergyData ?
                                        <div className="col-sm col-md-12">
                                            <div className="card border-0 mb-4">
                                                <div className="card-body">
                                                    <div className="card-text">
                                                        <Loader>Loading...</Loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        this.props.site.siteId === 'aes' ? null :
                                            [
                                                <div className="col-sm col-md-2" key={0}>
                                                    <div className="card border-0 mb-4">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center">Discharged on Imbalance Mechanism</h5>
                                                            <div className="card-text">
                                                                <h4 className="text-center font-weight-bold">{this.state.energyCalculations[0].discharged_on_im || this.state.energyCalculations[0].discharged_on_im === 0 ? `${this.state.energyCalculations[0].discharged_on_im.toFixed(0)} kWh` : 'N/A'}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>,
                                                <div className="col-sm col-md-2" key={1}>
                                                    <div className="card border-0 mb-4">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center">Discharged in Self-Supply</h5>
                                                            <div className="card-text">
                                                                <h4 className="text-center font-weight-bold">{this.state.energyCalculations[0].discharged_in_ss || this.state.energyCalculations[0].discharged_in_ss === 0 ? `${this.state.energyCalculations[0].discharged_in_ss.toFixed(0)} kWh` : 'N/A'}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>,
                                                <div className="col-sm col-md-2" key={2}>
                                                    <div className="card border-0 mb-4">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center">Total discharged</h5>
                                                            <div className="card-text">
                                                                <h4 className="text-center font-weight-bold">{this.state.energyCalculations[0].total_discharged || this.state.energyCalculations[0].total_discharged === 0 ? `${this.state.energyCalculations[0].total_discharged.toFixed(0)} kWh` : 'N/A'}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>,
                                                <div className="col-sm col-md-2" key={3}>
                                                    <div className="card border-0 mb-4">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center">Total charged</h5>
                                                            <div className="card-text">
                                                                <h4 className="text-center font-weight-bold">{this.state.energyCalculations[0].total_charged || this.state.energyCalculations[0].total_charged === 0 ? `${this.state.energyCalculations[0].total_charged.toFixed(0)} kWh` : 'N/A'}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>,
                                                <div className="col-sm col-md-2" key={4}>
                                                    <div className="card border-0 mb-4">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center">Charged in Self-Supply</h5>
                                                            <div className="card-text">
                                                                <h4 className="text-center font-weight-bold">{this.state.energyCalculations[0].charged_in_ss || this.state.energyCalculations[0].charged_in_ss === 0 ? `${this.state.energyCalculations[0].charged_in_ss.toFixed(0)} kWh` : 'N/A'}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>,
                                                <div className="col-sm col-md-2" key={5}>
                                                    <div className="card border-0 mb-4">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center">Charged on Imbalance Mechanism</h5>
                                                            <div className="card-text">
                                                                <h4 className="text-center font-weight-bold">{this.state.energyCalculations[0].charged_on_im || this.state.energyCalculations[0].charged_on_im === 0 ? `${this.state.energyCalculations[0].charged_on_im.toFixed(0)} kWh` : 'N/A'}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ]
                                }
                            </div>
                        </div>
                }

                {/* CHARTS */}
                {
                    this.props.site.siteId === 'aes' ? null :
                        <div className='group-card pt-3 px-3 mb-4'>
                            <h2 className="text-center">General overview</h2><hr />
                            < div className="row" >
                                <div className="col-sm col-lg-12 col-xl-6">
                                    <ActivePower
                                        loadingBatteryData={this.state.loadingBatteryData}
                                        assetSpecs={this.props.site.battery.assetSpecs}
                                        unit={this.props.unit}
                                        batteryData={this.state.batteryData}
                                        hidden={this.state.hidden}
                                        dynamicYAxis={this.state.dynamicYAxis}
                                        toggleDynamicYAxis={this.toggleDynamicYAxis}
                                        tickFormatterX={this.tickFormatterX}
                                        tickFormatterY={this.tickFormatterY}
                                        toggleChart={this.toggleChart}
                                        setDot={this.setDot}
                                    />
                                </div>
                                <div className="col-sm col-lg-12 col-xl-6">
                                    <AvailableDischargeEnergy
                                        loadingBatteryData={this.state.loadingBatteryData}
                                        assetSpecs={this.props.site.battery.assetSpecs}
                                        unit={this.props.unit}
                                        batteryData={this.state.batteryData}
                                        hidden={this.state.hidden}
                                        dynamicYAxis={this.state.dynamicYAxis}
                                        toggleDynamicYAxis={this.toggleDynamicYAxis}
                                        tickFormatterX={this.tickFormatterX}
                                        tickFormatterY={this.tickFormatterY}
                                        toggleChart={this.toggleChart}
                                        setDot={this.setDot}
                                    />
                                </div>
                            </div>
                        </div>
                }
                {
                    !charts.includes("FCR") ? null :
                        <div className='group-card pt-3 px-3 mb-4'>
                            <h2 className="text-center">Frequency Containment Service</h2><hr />
                            <div className="row">
                                <div className="col-sm col-lg-12 col-xl-6">
                                    <FrequencyResponse
                                        site={this.props.site}
                                        loadingFrequencyData={this.state.loadingFrequencyData}
                                        unit={this.props.unit}
                                        frequencyData={this.state.frequencyData}
                                        hidden={this.state.hidden}
                                        dynamicYAxis={this.state.dynamicYAxis}
                                        toggleDynamicYAxis={this.toggleDynamicYAxis}
                                        tickFormatterX={this.tickFormatterX}
                                        tickFormatterY={this.tickFormatterY}
                                        toggleChart={this.toggleChart}
                                        setDot={this.setDot}
                                    />
                                </div>

                                <div className="col-sm col-lg-12 col-xl-6">
                                    <BaselinePowerSOC
                                        site={this.props.site}
                                        loadingFrequencyData={this.state.loadingFrequencyData}
                                        unit={this.props.unit}
                                        frequencyData={this.state.frequencyData}
                                        hidden={this.state.hidden}
                                        dynamicYAxis={this.state.dynamicYAxis}
                                        toggleDynamicYAxis={this.toggleDynamicYAxis}
                                        tickFormatterX={this.tickFormatterX}
                                        tickFormatterY={this.tickFormatterY}
                                        toggleChart={this.toggleChart}
                                        setDot={this.setDot}
                                    />
                                </div>

                            </div>
                        </div>
                }
                {
                    !charts.includes("ImbalanceRevenue") && !charts.includes("Revenue") && !charts.includes("power") && !charts.includes("Energy") && !charts.includes("StateOfCharge") && !charts.includes("EnergyPrices") && !charts.includes("gridPower") && !charts.includes("Frequency") && !charts.includes("Imbalance") && !charts.includes("CapacityFCR") && !charts.includes("PriceFCR") && !charts.includes("Throughput") && !charts.includes("BalancingPrice") ? null :
                        <div className='group-card pt-3 px-3 mb-4'>
                            <div className="row">
                                {
                                    !charts.includes("ImbalanceRevenue") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <ImbalanceRevenue
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingRevenueData={this.state.loadingRevenueData}
                                                revenueData={this.state.revenueData}
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }
                                {
                                    !charts.includes("Revenue") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <Revenue
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingRevenueData={this.state.loadingRevenueData}
                                                revenueData={this.state.revenueData}
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }

                                {
                                    !charts.includes("power") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <Power
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingBatteryData={this.state.loadingBatteryData}
                                                batteryData={this.state.batteryData}
                                                type="power"
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }
                                {
                                    !charts.includes("Energy") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <Energy
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingEnergyData={this.state.loadingEnergyData}
                                                energyData={this.state.energyData}
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }
                                {
                                    !charts.includes("StateOfCharge") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <StateOfCharge
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingBatteryData={this.state.loadingBatteryData}
                                                batteryData={this.state.batteryData}
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }
                                {
                                    !charts.includes("EnergyPrices") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <EnergyPrices
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingEnergyPricesData={this.state.loadingEnergyPricesData}
                                                energyPrices={this.state.energyPrices}
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }
                                {
                                    !charts.includes("gridPower") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <Power
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingBatteryData={this.state.loadingBatteryData}
                                                batteryData={this.state.batteryData}
                                                type="gridPower"
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                setDot={this.setDot}
                                            />
                                        </div>
                                }

                                {
                                    !charts.includes("Frequency") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <Frequency
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingBatteryData={this.state.loadingBatteryData}
                                                batteryData={this.state.batteryData}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                hidden={this.state.hidden}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }


                                {
                                    !charts.includes("Imbalance") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <Imbalance
                                                site={this.props.site}
                                                unit="MW" //unit is always MW
                                                loadingImbalanceData={this.state.loadingImbalanceData}
                                                imbalanceData={this.state.imbalanceData}
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }
                                {
                                    !charts.includes("CapacityFCR") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <CapacityFCR
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingFCRData={this.state.loadingFCRData}
                                                fcrData={this.state.fcrData}
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }
                                {
                                    !charts.includes("PriceFCR") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <PriceFCR
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingFCRData={this.state.loadingFCRData}
                                                fcrData={this.state.fcrData}
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }
                            </div>
                            <div className="row">
                                {
                                    !charts.includes("Throughput") ? null :
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <Throughput
                                                site={this.props.site}
                                                unit={this.props.unit}
                                                loadingThroughputData={this.state.loadingThroughputData}
                                                throughputData={this.state.throughputData}
                                                throughputUpperBound={this.state.throughputUpperBound}
                                                dischargeCycles={this.state.dischargeCycles}
                                                hidden={this.state.hidden}
                                                dynamicYAxis={this.state.dynamicYAxis}
                                                toggleDynamicYAxis={this.toggleDynamicYAxis}
                                                tickFormatterX={this.tickFormatterX}
                                                tickFormatterY={this.tickFormatterY}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }
                                {
                                    !charts.includes("BalancingPrice") || this.props.site.siteId === 'verplancke' ? null : //Verplanke(specia case)
                                        <div className="col-sm col-lg-12 col-xl-6">
                                            <BalancingPrice
                                                site={this.props.site}
                                                loadingBalancingPriceData={this.state.loadingBalancingPriceData}
                                                balancingPriceData={this.state.balancingPriceData}
                                                loadingImbForecastAccuracyData={this.state.loadingImbForecastAccuracyData}
                                                imbForecastAccuracyData={this.state.imbForecastAccuracyData}
                                                hidden={this.state.hidden}
                                                tickFormatterX={this.tickFormatterX}
                                                toggleChart={this.toggleChart}
                                                report={true}
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                }
                {/* <div className="p-5"></div> */}
            </div>
        </div>
    }
}

export default Reports