import Loader from './Loader'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import 'react-datepicker/dist/react-datepicker.css'

import { getAssetSchedule, updateAssetSchedule } from '../api/battery'

moment.tz.setDefault('Europe/Brussels')


class AssetSchedule extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedDate: moment().format("YYYY-MM-DD"),
            assetSchedule: undefined,
            updatedSchedule: false,
            updating: false
        }

        this.getAssetSchedule = this.getAssetSchedule.bind(this)
        this.generatePeriods = this.generatePeriods.bind(this)
        this.disablePeriod = this.disablePeriod.bind(this)
        this.onDateChange = this.onDateChange.bind(this)
        this.onChange = this.onChange.bind(this)
        this.addTimeframe = this.addTimeframe.bind(this)
        this.onTimeframeChange = this.onTimeframeChange.bind(this)
        this.changeDisabled = this.changeDisabled.bind(this)
        this.scheduleStateUpdate = this.scheduleStateUpdate.bind(this)
        this.updateAssetSchedule = this.updateAssetSchedule.bind(this)
        this.deleteionDisabled = this.deleteionDisabled.bind(this)
    }

    componentDidMount() {
        this.getAssetSchedule()
        this.generatePeriods()
    }

    async getAssetSchedule() {
        this.setState({ assetSchedule: undefined })

        try {
            let assetSchedule = await getAssetSchedule(this.props.site.siteId, this.state.selectedDate)

            this.setState({ assetSchedule })
        }
        catch (err) {
            console.error(err)
        }
    }

    generatePeriods() {
        let a = moment(this.state.selectedDate, 'YYYY-MM-DD').startOf('day'),
            b = moment(this.state.selectedDate, 'YYYY-MM-DD').add(1, 'day').startOf('day'),
            h = b.diff(a, 'hours'),
            periods = [] // store the number of quarter-hours for today

        Array(h).fill(undefined).forEach((val, idx) => {
            periods.push(moment(this.state.selectedDate + ' ' + idx, "YYYY-MM-DD H").format("YYYY-MM-DD HH:mm:ss"))
            periods.push(moment(this.state.selectedDate + ' ' + idx, "YYYY-MM-DD H").add(15, 'minutes').format("YYYY-MM-DD HH:mm:ss"))
            periods.push(moment(this.state.selectedDate + ' ' + idx, "YYYY-MM-DD H").add(30, 'minutes').format("YYYY-MM-DD HH:mm:ss"))
            periods.push(moment(this.state.selectedDate + ' ' + idx, "YYYY-MM-DD H").add(45, 'minutes').format("YYYY-MM-DD HH:mm:ss"))
        })

        periods.push(moment(this.state.selectedDate, 'YYYY-MM-DD').add(1, 'day').startOf('day').format("YYYY-MM-DD HH:mm:ss"))

        periods = periods.filter(x => !this.disablePeriod(x))

        this.setState({
            periods
        })
    }

    disablePeriod(period) {
        return moment(period).isBefore(moment().minutes(15 * Math.floor(moment().minutes() / 15)).seconds(0).milliseconds(0))
    }

    onDateChange(date) {
        this.setState({
            selectedDate: moment(date).format('YYYY-MM-DD'),
            assetSchedule: undefined,
            updatedSchedule: false
        },
            () => {
                this.getAssetSchedule()
                this.generatePeriods()
            }
        )
    }

    onChange(e) {

        let assetSchedule = this.state.assetSchedule,
            index = assetSchedule.findIndex(x => x.id === e.target.id),
            name = e.target.name || e.target.attributes.getNamedItem("data-name").value,
            nominalPowerMW = this.props.site.battery.assetSpecs.nominal_power / 1000

        if (!assetSchedule[index].edited)
            assetSchedule[index].edited = true

        switch (name) {

            case 'pMaxCharge':
            case 'pMaxDischarge':

                if (e.target.value >= 0 && e.target.value <= nominalPowerMW)
                    assetSchedule[index][e.target.name] = e.target.value
                else if (e.target.value < 0)
                    assetSchedule[index][e.target.name] = 0
                else if (e.target.value > nominalPowerMW)
                    assetSchedule[index][e.target.name] = nominalPowerMW

                break

            // TODO: to be checked by @Kliment and @Elias
            case 'fcr':
            case 'afrrUp':
            case 'afrrDown':

                if (e.target.value >= 0 && e.target.value <= nominalPowerMW)
                    assetSchedule[index][e.target.name] = e.target.value
                else if (e.target.value < 0)
                    assetSchedule[index][e.target.name] = 0
                else if (e.target.value > nominalPowerMW)
                    assetSchedule[index][e.target.name] = nominalPowerMW

                break

            case 'pBase':

                if (e.target.value >= -nominalPowerMW && e.target.value <= nominalPowerMW)
                    assetSchedule[index][e.target.name] = e.target.value
                else if (e.target.value < -nominalPowerMW)
                    assetSchedule[index][e.target.name] = -nominalPowerMW
                else if (e.target.value > nominalPowerMW)
                    assetSchedule[index][e.target.name] = nominalPowerMW

                break

            case 'pRealTimeMax':

                if (e.target.value >= 0 && e.target.value <= Math.max(nominalPowerMW - (assetSchedule[index].pBase || 0) - (assetSchedule[index].fcr || 0) - Math.max((assetSchedule[index].afrrUp || 0), (assetSchedule[index].afrrDown || 0)), 0))
                    assetSchedule[index][e.target.name] = e.target.value
                else if (e.target.value < 0)
                    assetSchedule[index][e.target.name] = 0
                else if (e.target.value > Math.max(nominalPowerMW - (assetSchedule[index].pBase || 0) - (assetSchedule[index].fcr || 0) - Math.max((assetSchedule[index].afrrUp || 0), (assetSchedule[index].afrrDown || 0)), 0))
                    assetSchedule[index][e.target.name] = Math.max(nominalPowerMW - (assetSchedule[index].pBase || 0) - (assetSchedule[index].fcr || 0) - Math.max((assetSchedule[index].afrrUp || 0), (assetSchedule[index].afrrDown || 0)), 0)

                break

            case 'delete':

                if (assetSchedule[index].newTimeframe)
                    assetSchedule.splice(index, 1)
                else {
                    assetSchedule[index].delete = true
                    assetSchedule[index].pMaxCharge = ''
                    assetSchedule[index].pMaxDischarge = ''
                    assetSchedule[index].fcr = ''
                    assetSchedule[index].afrrUp = ''
                    assetSchedule[index].afrrDown = ''
                    assetSchedule[index].pBase = ''
                    assetSchedule[index].pRealTimeMax = ''
                }

                break

            default:

                break
        }

        this.setState({
            assetSchedule
        }, this.scheduleStateUpdate)
    }

    addTimeframe() {
        let assetSchedule = this.state.assetSchedule,
            periodIndex = this.state.periods.findIndex(x => moment(x).isSameOrAfter(moment().minutes(15 * Math.floor(moment().minutes() / 15)).seconds(0).milliseconds(0))),

            newTimeframe = {
                newTimeframe: true,
                id: this.props.site.battery.deviceId + '_' + moment().format('YYYYMMDDHHmmssSSS'),
                device_id: this.props.site.battery.deviceId,
                startTimeLT: this.state.periods[periodIndex],
                endTimeLT: this.state.periods[periodIndex + 1],
                delivery_date: this.state.selectedDate,
                pMaxCharge: '',
                pMaxDischarge: '',
                fcr: '',
                afrrUp: '',
                afrrDown: '',
                pBase: '',
                pRealTimeMax: ''
            }

        assetSchedule.push(newTimeframe)

        this.setState({
            assetSchedule: assetSchedule.sort((a, b) => moment(a.startTimeLT).isAfter(moment(b.startTimeLT)) ? 1 : -1)
        }, this.scheduleStateUpdate)
    }

    onTimeframeChange(e) {
        let assetSchedule = this.state.assetSchedule,
            index = assetSchedule.findIndex(x => x.id === e.target.id)

        assetSchedule[index][e.target.name] = e.target.value

        if (e.target.name === "startTimeLT" && moment(e.target.value, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment(assetSchedule[index].endTimeLT, "YYYY-MM-DD HH:mm:ss")))
            assetSchedule[index].endTimeLT = moment(e.target.value, "YYYY-MM-DD HH:mm:ss").add(15, 'minutes').format("YYYY-MM-DD HH:mm:ss")
        else if (e.target.name === "endTimeLT" && moment(e.target.value, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(moment(assetSchedule[index].startTimeLT, "YYYY-MM-DD HH:mm:ss")))
            assetSchedule[index].startTimeLT = moment(e.target.value, "YYYY-MM-DD HH:mm:ss").subtract(15, 'minutes').format("YYYY-MM-DD HH:mm:ss")

        this.setState({
            assetSchedule
        }, () => {
            setTimeout(() => {
                this.sortSchedule()
            }, 1000)
        })
    }

    sortSchedule() {
        this.setState({
            assetSchedule: this.state.assetSchedule.sort((a, b) => moment(a.startTimeLT).isAfter(moment(b.startTimeLT)) ? 1 : -1)
        })
    }

    scheduleStateUpdate() {
        if (this.state.assetSchedule && this.state.assetSchedule.filter(x => x.edited || x.newTimeframe || x.delete).length > 0)
            this.setState({
                updatedSchedule: true
            })
        else this.setState({
            updatedSchedule: false
        })
    }

    changeDisabled(endTimeLT) {
        return endTimeLT ? moment(endTimeLT, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(moment()) : moment(this.state.selectedDate).isBefore(moment().startOf('day'))
    }

    deleteionDisabled(x) {
        return !moment(x.endTimeLT, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment())
    }

    async updateAssetSchedule() {

        this.setState({ updating: true, errors: undefined })

        let assetSchedule = this.state.assetSchedule.filter(x => x.edited || x.newTimeframe || x.delete),
            errors = []

        assetSchedule.forEach(x => {

            const nominal_power = this.props.site.battery.assetSpecs.nominal_power / 1000 // MW

            // input values
            const fcr = parseFloat(x.fcr.toString().trim()) || null
            const afrrUp = parseFloat(x.afrrUp.toString().trim()) || null
            const afrrDown = parseFloat(x.afrrDown.toString().trim()) || null
            const pBase = parseFloat(x.pBase.toString().trim()) || null
            const pRealTimeMax = parseFloat(x.pRealTimeMax.toString().trim()) || null
            const pMaxDischarge = parseFloat(x.pMaxDischarge.toString().trim()) || null
            const pMaxCharge = parseFloat(x.pMaxCharge.toString().trim()) || null

            const p_real_time_max_check = (fcr || 0) + Math.min(afrrUp || 0, afrrDown || 0) + (pRealTimeMax || 0)
            const p_real_time_max_check_constrain = Math.max(Math.min(pMaxDischarge || nominal_power, nominal_power), Math.min(pMaxCharge || nominal_power, nominal_power))
            const p_real_time_max_ok = p_real_time_max_check <= p_real_time_max_check_constrain

            const injection_allocated_power = (fcr || 0) + (afrrUp || 0) - Math.min(0, pBase || 0) + (pRealTimeMax || 0)
            const injection_total_power = Math.min(pMaxDischarge || nominal_power, nominal_power)
            const injection_ok = injection_allocated_power <= injection_total_power

            const offtake_allocated_power = (fcr || 0) + (afrrDown || 0) + Math.max(0, pBase || 0) + (pRealTimeMax || 0)
            const offtake_total_power = Math.min(pMaxCharge || nominal_power, nominal_power)
            const offtake_ok = offtake_allocated_power <= offtake_total_power

            console.log('p_real_time_max_ok', p_real_time_max_ok)
            console.log('injection_ok', injection_ok)
            console.log('offtake_ok', offtake_ok)

            // check if the row is not marked for deleting
            if (!x.delete) {

                // P Real Time Max check
                if (!p_real_time_max_ok)
                    errors.push(`Condition: FCR + min(aFRR UP, aFRR DOWN) + P Real Time Max ≤ max(min(P Max Discharge, Nominal Power), min(P Max Charge, Nominal Power)) not statisfied (${p_real_time_max_check} ≤ ${p_real_time_max_check_constrain}) for timeframe: ${x.startTimeLT} - ${x.endTimeLT}.`)

                // injection direction check
                if (!injection_ok)
                    errors.push(`Condition: FCR + aFRR UP - min(0, P base) + P Real Time Max ≤ min(P Max Discharge, Nominal Power) not statisfied (${injection_allocated_power} ≤ ${injection_total_power}) for timeframe: ${x.startTimeLT} - ${x.endTimeLT}.`)

                // offtake direction check
                if (!offtake_ok)
                    errors.push(`Condition: FCR + aFRR DOWN + max(0, P base) + P Real Time Max ≤ min(P Max Charge, Nominal Power) not statisfied (${offtake_allocated_power} ≤ ${offtake_total_power}) for timeframe: ${x.startTimeLT} - ${x.endTimeLT}.`)
            }

            if (moment(x.endTimeLT, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(moment()))
                errors.push(`End of ${x.startTimeLT} - ${x.endTimeLT} timeframe is in the past.`)
        })

        let duplicates = assetSchedule.filter((e, i, arr) => {
            let i2 = arr.findIndex(e2 => e2.startTimeLT === e.startTimeLT && e2.endTimeLT === e.endTimeLT)
            return i2 !== -1 && i2 !== i
        })

        if (duplicates.length)
            errors.push(`Duplicate timeframes found.`)

        if (errors.length)
            this.setState({
                updating: false,
                errors
            })

        else {

            let res = await updateAssetSchedule(this.props.site.siteId, assetSchedule)

            if (res.errors)
                this.setState({
                    updating: false,
                    errors: res.errors
                })

            else this.setState({
                updating: 'ok'
            }, () => {
                setTimeout(() => {
                    this.setState({ updating: false, assetSchedule: undefined, errors: undefined, edited: false })
                    this.getAssetSchedule()
                }, 5000)
            })
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm">
                    {
                        <div className="row">
                            <div className="col-sm">
                                <h3 className="card-title text-center mb-4">
                                    Asset Schedule for &nbsp;
                                    <div className="d-inline-block position-relative">
                                        <DatePicker
                                            className="font-weight-bold border-0 p-0 m-0 h3 text-body cursor pointer schedule-date-picker"
                                            dateFormat="yyyy-MM-dd"
                                            selected={moment(this.state.selectedDate, 'YYYY-MM-DD').toDate()}
                                            onChange={this.onDateChange}
                                            filterDate={(date) => moment(date) < moment().add(2, 'days')}
                                        />
                                        <sup className="position-absolute" style={{ top: '-7px', right: '-7px' }}>
                                            <small><i className="fas fa-info-circle text-info cursor help" title="Click on the date to change it"></i></small>
                                        </sup>
                                    </div>
                                </h3>
                                {
                                    !(process.env.NODE_ENV === 'development' || window.location.href.indexOf("dev") >= 0) ? null :
                                        <div>
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-lg-4 col-12 alert alert-warning text-center">
                                                    <span><i className="fa fa-exclamation-triangle alert-warning mr-2"></i> The asset schedule on a development environment works with the test tables.</span>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                }
                                {
                                    this.state.updating === 'ok' ?
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-lg-4 col-12 alert alert-info text-center">
                                                <h5><i className="fas fa-info-circle mr-2"></i> Asset schedule for {this.state.date} was succesfully updated.</h5>
                                                <h5><i className="fas fa-spinner fa-spin mr-2"></i> Please wait...</h5>
                                            </div>
                                        </div>
                                        : this.state.assetSchedule === undefined ? <Loader text="Loading asset schedule..." />
                                            : !this.state.assetSchedule.length ? <h5 className="text-center">There is no asset schedule data available</h5> :
                                                <table className="table table-bordered table-sm table-asset-schedule pr-6">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th className="text-left">Start time (LT)</th>
                                                            <th className="text-left">End time (LT)</th>
                                                            <th className="text-right">P Max Charge (MW)</th>
                                                            <th className="text-right">P Max Discharge (MW)</th>
                                                            <th className="text-right">FCR (MW)</th>
                                                            <th className="text-right">aFRR Up (MW)</th>
                                                            <th className="text-right">aFRR Down (MW)</th>
                                                            <th className="text-right">P base (MW)</th>
                                                            <th className="text-right">P Real Time Max (MW)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.assetSchedule.map(x => {
                                                                return (
                                                                    <tr key={x.id} className="asset-schedule">
                                                                        <td>
                                                                            {
                                                                                !x.newTimeframe
                                                                                    ? <input type="text" className={`form-control text-left${x.delete ? ' bg-danger text-white' : ''}`} id={x.id} name={'startTimeLT'} readOnly={true} value={x.startTimeLT} />
                                                                                    : <select className="form-control" id={x.id} name={'startTimeLT'} value={x.startTimeLT} onChange={e => this.onTimeframeChange(e)}>
                                                                                        {
                                                                                            this.state.periods.map(period => <option value={period} key={period} disabled={this.disablePeriod(period)}>{period}</option>)
                                                                                        }
                                                                                    </select>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                !x.newTimeframe
                                                                                    ? <input type="text" className={`form-control text-left${x.delete ? ' bg-danger text-white' : ''}`} id={x.id} name={'endTimeLT'} readOnly={true} value={x.endTimeLT} />
                                                                                    : <select className="form-control" id={x.id} name="endTimeLT" value={x.endTimeLT} onChange={e => this.onTimeframeChange(e)}>
                                                                                        {
                                                                                            this.state.periods.map(period => <option value={period} key={period} disabled={this.disablePeriod(period)}>{period}</option>)
                                                                                        }
                                                                                    </select>
                                                                            }
                                                                        </td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeLT) || x.delete} onChange={this.onChange} name="pMaxCharge" value={x.pMaxCharge} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeLT) || x.delete} onChange={this.onChange} name="pMaxDischarge" value={x.pMaxDischarge} /></td>
                                                                        <td><input type="number" className="form-control text-right" id={x.id} readOnly={this.changeDisabled(x.endTimeLT) || x.delete} onChange={this.onChange} name="fcr" value={x.fcr} /></td>
                                                                        <td><input type="number" className="form-control text-right" id={x.id} readOnly={this.changeDisabled(x.endTimeLT) || x.delete} onChange={this.onChange} name="afrrUp" value={x.afrrUp} /></td>
                                                                        <td><input type="number" className="form-control text-right" id={x.id} readOnly={this.changeDisabled(x.endTimeLT) || x.delete} onChange={this.onChange} name="afrrDown" value={x.afrrDown} /></td>
                                                                        <td><input type="number" className='form-control text-right' id={x.id} readOnly={this.changeDisabled(x.endTimeLT) || x.delete} onChange={this.onChange} name="pBase" value={x.pBase} /></td>
                                                                        <td>
                                                                            <div className="position-relative">
                                                                                <input type="number" className={`form-control text-right${this.deleteionDisabled(x) ? '' : ' pr-5'}`} id={x.id} readOnly={this.changeDisabled(x.endTimeLT) || x.delete} onChange={this.onChange} name={'pRealTimeMax'} value={x.pRealTimeMax} />
                                                                                {
                                                                                    this.deleteionDisabled(x) || x.delete ? null :
                                                                                        <div className="d-flex align-items-center position-absolute h-100 pr-2" style={{ fontSize: '1.5rem', top: 0, right: 0 }}>
                                                                                            <i className="fas fa-times fa-lg text-danger cursor pointer" title="Remove timeframe" data-name="delete" id={x.id} onClick={e => this.onChange(e)}></i>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                }
                                {
                                    !(this.state.errors && this.state.errors.length) ? null :
                                        <div>
                                            <div className="alert alert-warning">
                                                <i className="fas fa-exclamation-triangle mr-2"></i> The following {this.state.errors.length > 1 ? 'errors' : 'error'} occured:
                                                <ul>
                                                    {
                                                        this.state.errors.map(error => (<li key={Math.random()}>{error}</li>))
                                                    }
                                                </ul>
                                            </div>
                                            <br />
                                        </div>
                                }
                                {
                                    this.state.assetSchedule === undefined || this.changeDisabled() || this.state.updating === 'ok' ? null :
                                        <div>
                                            <button className="btn btn-warning mr-3" type="button" onClick={this.addTimeframe}>
                                                <i className="fas fa-plus mr-2"></i> Add new timeframe
                                            </button>
                                            <button className="btn btn-primary" type="button" onClick={this.updateAssetSchedule} disabled={!this.state.updatedSchedule || this.state.updating}>
                                                <i className={'fas mr-2 ' + (this.state.updating ? 'fa-spinner fa-spin' : 'fa fa-save')}></i> Update new asset schedule
                                            </button>
                                        </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div >
        )
    }
}

export default AssetSchedule