import React, { Component } from 'react'
import moment from 'moment-timezone'

import Loader from '../components/Loader'
import Configuration from '../components/Configuration'

moment.tz.setDefault('Europe/Brussels')


class Management extends Component {
    constructor(props) {
        super(props)

        this.state = {
            steering: false
        }

    }

    componentDidMount() {
        if (!this.props.site || (this.props.site && this.props.site.siteId !== this.props.siteId))
            this.props.selectSite(this.props.siteId)
    }

    render() {

        if (!this.props.site)
            return <Loader className="container align-items-center" noText={true} />

        return (
            <div className="row">
                <div className="col-sm">
                        {
                            this.props.user.info.role === 'admin' ?
                                <Configuration
                                    siteId={this.props.siteId}
                                    site={this.props.site}
                                    unit={this.props.unit}
                                    user={this.props.user}
                                    updateSites={this.props.updateSites}
                                />
                                : null
                        }
                    <div className="p-5"></div>
                </div>
            </div>
        )
    }
}

export default Management