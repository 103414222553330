import React, { Component } from 'react'
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'

import Loader from '../Loader'

class ImbalancePriceForecast extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">Current quarter-hour Imbalance Price Forecast {this.props.site.siteId === 'aes' ? '(NL)' : '(BE)'}<span className="float-right chart-imbalancePriceForecast" title="Updates in" style={{ fontSize: '0.85rem' }}></span></h4>
                    <div className="card-deck">
                        <div className="col-sm col-md-4 offset-md-2">
                            <div className="card mb-4">
                                <div className="card-body p-1">
                                    <h6 className="card-title text-center">Weighted average forecast</h6>
                                    <div className="card-text">
                                        {
                                            this.props.loadingImbalancePriceForecastData ? <Loader>Loading...</Loader> : this.props.imbalancePriceForecastData.length < 1 ? <div className="text-center"><h6>N/A</h6></div>
                                                : this.props.site.siteId === 'aes' ?
                                                    <div>
                                                        <h6 className="text-center font-weight-bold">Price up: {this.props.imbalancePriceForecastData[1].prediction_priceup !== undefined ? this.props.imbalancePriceForecastData[1].prediction_priceup.toFixed(2) : `N/A`} €/MWh</h6>
                                                        <h6 className="text-center font-weight-bold">Price down: {this.props.imbalancePriceForecastData[1].prediction_pricedown !== undefined ? this.props.imbalancePriceForecastData[1].prediction_pricedown.toFixed(2) : `N/A`} €/MWh</h6>
                                                    </div>
                                                    :
                                                    <h6 className="text-center font-weight-bold">Price: {this.props.imbalancePriceForecastData[1].prediction_priceup !== undefined ? this.props.imbalancePriceForecastData[1].prediction_priceup.toFixed(2) : `N/A`} €/MWh</h6>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm col-md-4">
                            <div className="card mb-4 ">
                                <div className="card-body p-1">
                                    <h6 className="card-title text-center">Highest probability bucket</h6>
                                    <div className="card-text">
                                        {
                                            this.props.loadingImbalancePriceForecastData ? <Loader>Loading...</Loader> : this.props.imbalancePriceForecastData.length < 1 ? <div className="text-center"><h6>N/A</h6></div>
                                                : this.props.site.siteId === 'aes' ?
                                                    <div>
                                                        <h6 className="text-center font-weight-bold">Price up: {`${this.props.highestProbBuckets.priceUpBucket.bucket_start} to ${this.props.highestProbBuckets.priceUpBucket.bucket_end}`}</h6>
                                                        <h6 className="text-center font-weight-bold">Price down: {`${this.props.highestProbBuckets.priceDownBucket.bucket_start} to ${this.props.highestProbBuckets.priceDownBucket.bucket_end}`}</h6>
                                                    </div>
                                                    : <h6 className="text-center font-weight-bold">{`${this.props.highestProbBuckets.priceUpBucket.bucket_start} to ${this.props.highestProbBuckets.priceUpBucket.bucket_end}`}</h6>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.loadingImbalancePriceForecastData ? <Loader className="height-400" /> : this.props.imbalancePriceForecastData.length < 1 ? <div className="d-flex justify-content-center align-items-center" style={{ height: 308 }}><h1>N/A</h1></div> :
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={this.props.imbalancePriceForecastData} layout="vertical" margin={{ top: 7, right: 20, bottom: 0, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis type="number" />
                                    <YAxis type="category" dataKey="bucket" hide={true} reversed={true} yAxisId="0" />
                                    <YAxis type="category" dataKey="bucket_end" interval={0} scale="band" reversed={true} yAxisId="1" />
                                    <Tooltip formatter={value => `${(value * 100).toFixed(2)}%`} />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    {
                                        this.props.site.siteId === 'aes' ?
                                            [
                                                data.probs_priceup === false ? null : <Bar dataKey={`probs_priceup${this.props.hidden.probs_priceup ? ' ' : ''}`} name="Probability price up" fill="#8884d8" barCategoryGap={30} key={0} />,
                                                data.probs_priceup === false ? null : <Bar dataKey={`probs_pricedown${this.props.hidden.probs_pricedown ? ' ' : ''}`} name="Probability price down" fill="#82ca9d" barCategoryGap={30} key={1} />
                                            ]
                                            :
                                            data.probs_priceup === false ? null : <Bar dataKey={`probs_priceup${this.props.hidden.probs_priceup ? ' ' : ''}`} name="Price probability" fill="#8884d8" barCategoryGap={30} />
                                    }
                                </BarChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div >
        )
    }
}

export default ImbalancePriceForecast