import React, { Component } from 'react'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, ReferenceLine, Tooltip, Legend } from 'recharts'

import Loader from '../Loader'

class Frequency extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">Frequency (Hz)
                        {/* <div className="float-md-right">,
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_freq" onChange={e => this.props.toggleDynamicYAxis(e, 'freq')} />
                                <label className="form-check-label" htmlFor="yaxis_freq">Dynamic Y Axis</label>
                            </div>
                        </div> */}
                    </h4>
                    {
                        this.props.loadingBatteryData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={this.props.batteryData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <ReferenceLine y={50} stroke="#66c766" ifOverflow="extendDomain" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} minTickGap={50} />
                                    <YAxis type="number" domain={[this.props.dynamicYAxis.freq ? 'auto' : 49.8, this.props.dynamicYAxis.freq ? 'auto' : this.props.dynamicYAxis.freq ? 'auto' : 50.2]} />
                                    <Tooltip />
                                    <Legend verticalAlign="bottom" height={36} />
                                    {
                                        data.freq === false ? null : <Line type="monotone" dataKey="freq" name="freq" unit="Hz" stroke="#3f51b5" dot={dot => this.props.setDot && this.props.setDot(dot, 'freq', 'Hz', 3, 49.8, 50.2, 'chart-battery', this.props.batteryData)} isAnimationActive={false} />
                                    }
                                </LineChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default Frequency