import moment from 'moment-timezone'
import React, { Component } from 'react'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent'

import Loader from '../Loader'

moment.tz.setDefault('UTC')

const CustomTooltip = props => {
    // payload[0] doesn't exist when tooltip isn't visible
    if (props.active && props.payload && props.payload[0]) {

        // mutating props directly is against react's conventions
        // so we create a new payload with the name and value fields set to what we want
        const label = 'UTC: ' + moment.tz(props.payload[0].payload.start_time_utc, 'YYYY-MM-DD HH:mm:ss', 'UTC').format('YYYY-MM-DD HH:mm') + ' – ' + moment.tz(props.payload[0].payload.end_time_utc, 'YYYY-MM-DD HH:mm:ss', 'UTC').format('YYYY-MM-DD HH:mm')

        // we render the default, but with our overridden payload
        return <DefaultTooltipContent {...props} label={label} />
    }

    // we just render the default
    return <DefaultTooltipContent {...props} />
}

class PriceFCR extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title"><span>FCR Price (€/MW)</span>
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_fcrPrice" onChange={e => this.props.toggleDynamicYAxis(e, 'fcrPrice')} />
                                <label className="form-check-label" htmlFor="yaxis_fcrPrice">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingFCRData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={this.props.fcrData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={tick => this.props.tickFormatterX(tick, 'YYYY-MM-DD HH:mm')} />
                                    <YAxis type="number" domain={[this.props.dynamicYAxis.fcrPrice ? 'auto' : 0, this.props.dynamicYAxis.fcrPrice ? 'auto' : 'dataMax + 5']} tickFormatter={this.props.tickFormatterY} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    {
                                        [
                                            data.bid_price === false ? null : <Line type="stepAfter" dataKey={`bid_price${this.props.hidden.bid_price ? ' ' : ''}`} name="Bid price" unit="€/MW" stroke="#3f51b5" dot={false} isAnimationActive={false} key={0} />,
                                            data.cleared_price === false ? null : <Line type="stepAfter" dataKey={`cleared_price${this.props.hidden.cleared_price ? ' ' : ''}`} name="Cleared price" unit="€/MW" stroke="#88bb56" dot={false} isAnimationActive={false} key={1} />
                                        ]
                                    }
                                </LineChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default PriceFCR