import React, { Component } from 'react'
import { ResponsiveContainer, Line, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'

import Loader from '../Loader'


class GridFrequency extends Component {
    render() {
        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">Grid Frequency
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_GridFrequency" onChange={e => this.props.toggleDynamicYAxis(e, 'GridFrequency')} />
                                <label className="form-check-label" htmlFor="yaxis_GridFrequency">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingFrequencyData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={this.props.frequencyData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="0.2 0.2" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} minTickGap={50} />
                                    <YAxis type="number" tickFormatter={e => parseFloat(e).toFixed(2)} domain={[this.props.dynamicYAxis.GridFrequency ? 'auto' : 49.7, this.props.dynamicYAxis.GridFrequency ? 'auto' : 50.3]} allowDecimals={true} minTickGap={1} />
                                    <Tooltip />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    <Line type="linear" dataKey={`frequency${this.props.hidden.frequency ? ' ' : ''}${this.props.hidden.frequency ? ' ' : ''}`} name="Frequency" stroke="#0049FF" unit='Hz' dot={dot => this.props.setDot && this.props.setDot(dot, `frequency`, "Hz", 3, -10, 10, 'chart-frequency', this.props.frequencyData)} />
                                </LineChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default GridFrequency