import React, { Component } from 'react'
import { ResponsiveContainer, Line, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'

import Loader from '../Loader'

class ActivePower extends Component {
    render() {
        const nominal_power = this.props.unit === 'kW' ? this.props.assetSpecs.nominal_power : this.props.assetSpecs.nominal_power / 1000

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">Active Power of the Battery Plant
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_power" onChange={e => this.props.toggleDynamicYAxis(e, 'ActivePower')} />
                                <label className="form-check-label" htmlFor="yaxis_power">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingBatteryData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={this.props.batteryData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={this.props.tickFormatterX} minTickGap={50} />
                                    <YAxis type="number" tickFormatter={this.props.tickFormatterY} domain={this.props.dynamicYAxis.ActivePower ? ['auto', 'auto'] : [dataMin => dataMin >= -nominal_power ? (Math.floor(-nominal_power / 10) * 10) : (Math.floor(dataMin / 1000) * 1000), dataMax => dataMax <= nominal_power ? (Math.ceil(nominal_power / 10) * 10) : (Math.ceil(dataMax / 1000) * 1000)]} />
                                    <Tooltip />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    <Line type="linear" dataKey={`activePwr${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.activePwr ? ' ' : ''}`} name="Active Power" stroke="#FF2D00" unit={this.props.unit} dot={dot => this.props.setDot && this.props.setDot(dot, `activePwr${this.props.unit === 'kW' ? '' : 'MW'}`, this.props.unit, 3, -10, 10, 'chart-battery', this.props.batteryData)} />
                                    <Line type="linear" dataKey={`activePwrReq${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.activePwrReq ? ' ' : ''}`} name="Requested Active Power by Yuso" stroke="#0049FF" unit={this.props.unit} dot={false} />
                                    <Line type="linear" dataKey={`activePwrExec${this.props.unit === 'kW' ? '' : 'MW'}${this.props.hidden.activePwrExec ? ' ' : ''}`} name="Target Active Power by Controller" stroke="#000000" unit={this.props.unit} dot={false} />
                                </LineChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default ActivePower