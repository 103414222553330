import React, { Component } from 'react'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'

import Loader from '../Loader'

class ImbalancePrices extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            < div className="card border-0 mb-4 chart-card" >
                <div className="card-body">
                    <h4 className="card-title">Energy Prices (€/MWh)
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="yaxis_balancingPrices" onChange={e => this.props.toggleDynamicYAxis(e, 'balancingPrices')} />
                                <label className="form-check-label" htmlFor="yaxis_balancingPrices">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingImbalancePricesData ? <Loader className="height-400" /> :
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={this.props.imbalancePricesData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="time" tickFormatter={tick => this.props.tickFormatterX(tick, 'HH:mm')} minTickGap={30} />
                                    <YAxis type="number" domain={this.props.dynamicYAxis.balancingPrices ? ['auto', 'auto'] : [dataMin => (Math.floor(dataMin / 100) * 100), dataMax => (Math.ceil(dataMax / 100) * 100)]} />
                                    <Tooltip />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    {
                                        this.props.site.siteId === 'aes' ?
                                            [
                                                data.priceDown === false ? null : <Line type="monotone" dataKey={`priceDown${this.props.hidden.priceDown ? ' ' : ''}`} name="Price down" unit="€/MWh" stroke="#d04239" dot={false} isAnimationActive={false} key={0} />,
                                                data.priceUp === false ? null : <Line type="monotone" dataKey={`priceUp${this.props.hidden.priceUp ? ' ' : ''}`} name="Price up" unit="€/MWh" stroke="#88bb56" dot={false} isAnimationActive={false} key={1} />,
                                                data.priceMid === false ? null : <Line type="monotone" dataKey={`priceMid${this.props.hidden.priceMid ? ' ' : ''}`} name="Price middle" unit="€/MWh" stroke="#ffbc00" dot={dot => this.props.setDot && this.props.setDot(dot, 'priceMid', '€/MW', 2, -50, 100, 'chart-imbalance-prices', this.props.imbalancePricesData)} isAnimationActive={false} key={2} />
                                            ]
                                            :
                                            [
                                                data.priceNeg === false ? null : <Line type="stepAfter" dataKey={`priceNeg${this.props.hidden.priceNeg ? ' ' : ''}`} name="Imbalance price" unit="€/MWh" stroke="#d04239" dot={false} isAnimationActive={false} key={0} />,
                                                data.belpexPrice === false ? null : <Line type="stepAfter" dataKey={`belpexPrice${this.props.hidden.belpexPrice ? ' ' : ''}`} name="Belpex price" unit="€/MWh" stroke="#3f51b5" dot={dot => this.props.setDot && this.props.setDot(dot, 'belpexPrice', '€/MW', 2, -500, 500, 'chart-imbalance-prices', this.props.imbalancePricesData)} isAnimationActive={false} key={1} />
                                            ]
                                    }
                                </LineChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div >
        )
    }
}

export default ImbalancePrices