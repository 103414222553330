import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { forgotPasswordInitiate, forgotPasswordComplete } from '../api/user'

class ForgotPassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            email: '',
            emailSend: false,
            verificationCode: '',
            newPassword: '',
            passwordChangeAttempted: false,
            passwordChangeSuccessfull: false,
            newPasswordError: null,
            newPasswordStatus: null
        }

        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handleVerificationCodeChange = this.handleVerificationCodeChange.bind(this)
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this)

        this.handleForgotPasswordInitiate = this.handleForgotPasswordInitiate.bind(this)
        this.handleForgotPasswordComplete = this.handleForgotPasswordComplete.bind(this)
    }

    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        })
    }

    handleVerificationCodeChange(e) {
        this.setState({
            verificationCode: e.target.value
        })
    }

    handleNewPasswordChange(e) {
        this.setState({
            newPassword: e.target.value
        })
    }

    handleForgotPasswordInitiate(e) {
        e.preventDefault()

        this.setState({
            newPasswordError: null,
            newPasswordStatus: null
        })

        forgotPasswordInitiate(
            this.state.email,
            (err, res) => {

                if (err) {
                    this.setState({
                        newPasswordError: err.message
                    })
                }
                else {
                    this.setState({
                        newPasswordStatus: 'Please check your email inbox for verification code',
                        newPasswordError: null,
                        emailSend: true
                    })
                }
            }
        )
    }

    handleForgotPasswordComplete(e) {
        e.preventDefault()

        forgotPasswordComplete(
            this.state.email,
            this.state.verificationCode,
            this.state.newPassword,
            (err, res) => {
                if (err) {
                    this.setState({
                        newPasswordError: err.message,
                        passwordChangeAttempted: true,
                        passwordChangeSuccessfull: false
                    })
                }
                else {
                    this.setState({
                        newPasswordError: null,
                        passwordChangeAttempted: true,
                        passwordChangeSuccessfull: true
                    })
                }
            }
        )
    }

    renderSendEmail() {
        return (
            <form onSubmit={this.handleForgotPasswordInitiate} noValidate>
                <h1 className="form-title text-center mb-5">
                    <Link to="/">
                        <img src="/yuso-flex-logo.png" alt="Yuso" className="mb-4 position-relative form-logo" />
                    </Link>
                    <br />
                    <strong>Enter your email address,</strong>
                    <br />
                    to receive a verification code
                </h1>
                <div className="form-group">
                    <input
                        className="form-control p-3"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        placeholder="E-mail"
                        autoComplete="off"
                        type="email"
                    />
                </div>
                {
                    this.state.newPasswordError && <div className="alert alert-danger">
                        {this.state.newPasswordError}
                    </div>
                }
                {
                    this.state.newPasswordStatus && <div className="alert alert-warning">
                        {this.state.newPasswordStatus}
                    </div>
                }
                <div className="form-group clearfix">
                    <button
                        type="submit"
                        className="btn btn-primary float-right px-5 py-3"
                    >
                        Send Verification Email <i className="fas fa-paper-plane"></i>
                    </button>
                </div>
            </form>
        )
    }

    renderChangePassword() {
        return (
            <form onSubmit={this.handleForgotPasswordComplete} noValidate>
                <h1 className="form-title text-center mb-5">
                    <Link to="/">
                        <img src="/yuso-flex-logo.png" alt="Yuso" className="mb-4 position-relative form-logo" />
                    </Link>
                    <br />
                    <strong>Enter the received code,</strong>
                    <br />
                    and your new password
                </h1>
                <div className="form-group">
                    <input
                        className="form-control p-3"
                        value={this.state.verificationCode}
                        onChange={this.handleVerificationCodeChange}
                        placeholder="Verification code"
                        autoComplete="new-password"
                        type="password"
                    />
                </div>
                <div className="form-group">
                    <input
                        className="form-control p-3"
                        value={this.state.newPassword}
                        onChange={this.handleNewPasswordChange}
                        placeholder="New password"
                        autoComplete="new-password"
                        type="password"
                    />
                </div>
                {
                    this.state.newPasswordError && <div className="alert alert-danger">
                        {this.state.newPasswordError}
                    </div>
                }
                {
                    this.state.newPasswordStatus && <div className="alert alert-warning">
                        <i className="fas fa-info-circle mr-2"></i> {this.state.newPasswordStatus}
                    </div>
                }
                <div className="form-group clearfix">
                    <button
                        type="submit"
                        className="btn btn-primary float-right px-5 py-3"
                    >
                        Change Password <i className="fas fa-key"></i>
                    </button>
                </div>
            </form>
        )
    }

    renderSuccess() {
        return (
            <div className="h-100 d-flex justify-content-center align-items-center text-center p-5" style={{ lineHeight: '2.5rem' }}>
                <h3 className="form-title text-center mb-5">
                    <img src="/yuso-flex-logo.png" alt="Yuso" className="mb-4 position-relative form-logo" />
                    <br />
                    <i className="fas fa-check text-success mr-2"></i> Your password has been
                    <br />
                    successfully changed,
                    <br />
                    <Link to="/">you can log-in now</Link>
                </h3>
            </div>
        )
    }

    renderFail() {
        return (
            <div className="h-100 d-flex justify-content-center align-items-center text-center p-5" style={{lineHeight:'2.5rem'}}>
                <h3 className="form-title text-center mb-5">
                    <img src="/yuso-flex-logo.png" alt="Yuso" className="mb-4 position-relative form-logo" />
                    <br />
                    <i className="fas fa-exclamation-triangle text-danger mr-2"></i> Something went wrong while
                    <br />
                    resetting your password, 
                    <br />
                    <Link to="/forgot-password">please try again</Link>
                </h3>
            </div>
        )
    }

    render() {
        return (
            <div>
                {
                    !this.state.emailSend ? this.renderSendEmail() : null
                }
                {
                    this.state.emailSend && !this.state.passwordChangeAttempted ? this.renderChangePassword() : null
                }
                {
                    this.state.passwordChangeAttempted && !this.state.passwordChangeSuccessfull ? this.renderFail() : null
                }
                {
                    this.state.passwordChangeAttempted && this.state.passwordChangeSuccessfull ? this.renderSuccess() : null
                }
            </div>
        )
    }
}

export default ForgotPassword
