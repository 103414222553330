import React, { Component } from 'react'
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, ReferenceLine, Tooltip, Legend, Bar, LabelList } from 'recharts'

import Loader from '../Loader'

class ImbalanceRevenueForecast extends Component {

    render() {

        let data = (this.props.chartConfig && this.props.chartConfig.data) || {}

        return (
            <div className="card border-0 mb-4 chart-card">
                <div className="card-body">
                    <h4 className="card-title">Imbalance Revenue Forecast (€/MWh)  {this.props.site.siteId === 'aes' ? '(NL)' : '(BE)'}
                        <div className="float-md-right">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="yaxis" id="imbalanceRevenueForecast" onChange={e => this.props.toggleDynamicYAxis(e, 'imbRevForecast')} />
                                <label className="form-check-label" htmlFor="yaxis_ImbalanceRevenueForecast">Dynamic Y Axis</label>
                            </div>
                        </div>
                    </h4>
                    {
                        this.props.loadingImbalanceRevenueForecastData ? <Loader className="height-400" /> : this.props.imbalanceRevenueForecastData.length < 1 ? <div className="height-400 d-flex justify-content-center align-items-center"><h1>N/A</h1></div> :
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={this.props.imbalanceRevenueForecastData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                                    <XAxis dataKey="period" tickFormatter={this.props.tickFormatterX} />
                                    <YAxis type="number" domain={[this.props.dynamicYAxis.imbRevForecast ? 'auto' : 'dataMin - 1', this.props.dynamicYAxis.imbRevForecast ? 'auto' : 'dataMax + 1']} tickFormatter={this.props.tickFormatterY} allowDataOverflow={true} />
                                    <Tooltip formatter={value => value.toFixed(2)} />
                                    <Legend verticalAlign="bottom" height={36} onClick={this.props.toggleChart} />
                                    <ReferenceLine y={0} stroke="#66c766" ifOverflow="extendDomain" />
                                    {
                                        data.revenue === false ? null : <Bar dataKey={`cost${this.props.hidden.cost ? ' ' : ''}`} name="Imbalance Revenue Forecast" fill="#0caded" legendType="circle" unit={'€/MWh'} isAnimationActive={false}>
                                            <LabelList dataKey={`cost`} content={dot => this.props.setDot && this.props.setDot(dot, `cost`, '€/MWh', 2, -10, 10, 'chart-imbalanceRevenueForecast', this.props.imbalanceRevenueForecastData)} />
                                        </Bar>
                                    }
                                </BarChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        )
    }
}

export default ImbalanceRevenueForecast